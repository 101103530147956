export enum OrderRejectionType {
    None = 0,
    NoLiquidity = 1,
    NotEnoughBalance = 2,
    ShortPositionsDisabled = 3,
    MaxPositionLimit = 4,
    InvalidInstrument= 5,
    MinOrderSizeLimit= 6,
    MaxOrderSizeLimit= 7,
    MaxPositionNotionalLimit = 8
}