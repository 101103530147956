import React from 'react';
import { Button, Intent, Classes, Switch } from '@blueprintjs/core';
import { inject } from 'mobx-react';
import { omit, every } from 'lodash';
import * as Backoffice from '@client/backoffice/Dto';
import * as Cronut from '@client/cronut/Dto';
import { LabelWithTextInput, LykkeForm } from '@elements/lykke-html/form/LykkeForm';
import { ILocalizedProps } from '@elements/localization';
import { FrontendError } from '@client/backoffice/CustomErrors';
import { BackofficeErrorCodes } from '@client/backoffice/ErrorCodes';
import classNames from 'classnames';

interface Props extends ILocalizedProps {
  itemToEdit?: Backoffice.Users.User | Cronut.Users.User;
  roles: Backoffice.Users.AvailableRoles | Cronut.Users.AvailableRoles;
  onCancel: () => void;
  onSubmit: (userData: Backoffice.Users.User | Cronut.Users.User) => void;
  loading: boolean;
  serverErrors: FrontendError[];
}

interface State {
  formData: Backoffice.Users.User | Cronut.Users.User;
  fieldErrors: {[P in keyof (Backoffice.Users.User | Cronut.Users.User)]?: string};
}

const getEmptyFormData = () => ({
  id: '',
  username: '',
  externalLoginProvider: '',
  roles: []
} as Backoffice.Users.User | Cronut.Users.User);

@inject('i18n')
export class AddEditUserRolesForm extends React.Component<Props, State> {
  public state: State = {
    formData: { ...(this.props.itemToEdit ? this.props.itemToEdit : getEmptyFormData()) },
    fieldErrors: {},
  };

  private handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFormData = {
      ...this.state.formData,
      username: e.target.value
    };

    this.setState({ formData: newFormData });
  }

  private handleRolesChange = (role: string, e: React.ChangeEvent<HTMLInputElement>) => {
    const roles = e.target.checked
      ? (this.state.formData.roles ?? []).concat(role)
      : (this.state.formData.roles ?? []).filter(x => x !== role);
    const newFormData = {
      ...this.state.formData,
      roles
    };

    this.setState({ formData: newFormData });
  }

  private handleSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    if (this.validate()) {
      this.props.onSubmit(this.state.formData);
    }
  }

  private validateUsername = () => {
    const value = this.state.formData.username;

    if (!value) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, username: this.props.i18n.UserRoles.Errors.UserNameFieldRequired}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'username')
      }));
      return true;
    }
  }

  private validateRoles = () => {
    const value = this.state.formData.roles ?? [];

    if (!value.length) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, roles: this.props.i18n.UserRoles.Errors.RolesFieldRequired}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'roles')
      }));
      return true;
    }
  }

  private validate = () => {
    return every([
      this.validateUsername(),
      this.validateRoles()
    ]);
  }

  private getServerErrorMessages(field: keyof (Backoffice.Users.User | Cronut.Users.User)) {
    return this.props.serverErrors
      ?.filter(e => e.errorCode as BackofficeErrorCodes === 'FieldValidationFailed' && e.fieldNames.includes(field))
      .map(e => e.errorMessage) ?? [];
  }

  private getClientErrorMessage(field: keyof (Backoffice.Users.User | Cronut.Users.User)) {
    return this.state.fieldErrors[field];
  }

  private formatErrorMessages(messages: string[]) {
    return messages.filter(m => !!m).join('; ');
  }

  public render() {
    const { itemToEdit = null, loading } = this.props;
    const t = this.props.i18n.UserRoles;

    return (
      <LykkeForm>
        <div className={Classes.DIALOG_BODY}>
          <LabelWithTextInput
            autoFocus={true}
            label={t.UserNameLabel}
            value={this.state.formData.username}
            disabled={itemToEdit}
            maxLength={100}
            onChange={this.handleUsernameChange}
            onBlur={this.validateUsername}
            error={this.formatErrorMessages([this.getClientErrorMessage('username'), ...this.getServerErrorMessages('username')])}
          />
          {this.renderRolesInput()}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={this.props.onCancel}
              disabled={loading}
              intent={Intent.NONE}
            >
              {t.CancelButtonLabel}
            </Button>
            <Button
              type='submit'
              onClick={this.handleSubmit}
              disabled={loading}
              loading={loading}
              intent={Intent.SUCCESS}
            >
              {itemToEdit ? t.UpdateButtonLabel : t.CreateButtonLabel}
            </Button>
          </div>
        </div>
      </LykkeForm>
    );
  }

  private renderRolesInput() {
    const t = this.props.i18n.UserRoles;
    const error = this.formatErrorMessages([this.getClientErrorMessage('roles'), ...this.getServerErrorMessages('roles')]);

    return (
      <div className={classNames(Classes.FORM_GROUP, {[Classes.INTENT_DANGER]: !!error})}>
        <label className={classNames(Classes.LABEL)}>
          <span className='title'>{t.RolesLabel}</span>
          <div>
            {
              this.props.roles.roles.map(x => <Switch
                key={x}
                label={x}
                checked={(this.state.formData.roles ?? []).includes(x)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  this.handleRolesChange(x, e);
                  window.setTimeout(() => this.validateRoles(), 0);
                }}
              />)
            }
          </div>
          {error && <span className={Classes.FORM_HELPER_TEXT}>{error}</span>}
        </label>
      </div>
    );
  }
}