import { NOVA_VERSION, ITabSettingsContent } from '@models/TabSettings';
import Validation from './TabSettingsValidation/';

export default class TabSettingsValidation {

  public static validate(tabSettings: ITabSettingsContent): ITabSettingsContent {
    const {
      orders = [],
      positions = [],
      news = [],
      priceAlerts = [],
      activities = [],
      cashMovements = [],
      creditRequests = [],
      adjustDrafts = [],
      costsAndCharges = [],
      version = NOVA_VERSION,
    } = tabSettings;

    const validatedOrders = Validation.Orders.validate(orders);
    const validatedPositions = Validation.Positions.validate(positions); // done
    const validatedNews = Validation.News.validate(news); // done
    const validatedPriceAlerts = Validation.PriceAlerts.validate(priceAlerts); // done
    const validatedActivities = Validation.Activities.validate(activities); // done
    const validatedCashMovements = Validation.CashMovement.validate(cashMovements); // done
    const validatedCreditRequest = Validation.CreditRequests.validate(creditRequests); // done
    const validatedAdjustDrafts = Validation.AdjustDraft.validate(adjustDrafts); // done
    const validatedCostsAndCharges = Validation.CostsAndCharges.validate(costsAndCharges); // done
    const validatedVersion = version ? version : NOVA_VERSION;

    return {
      orders: validatedOrders,
      positions: validatedPositions,
      news: validatedNews,
      priceAlerts: validatedPriceAlerts,
      activities: validatedActivities,
      cashMovements: validatedCashMovements,
      creditRequests: validatedCreditRequest,
      adjustDrafts: validatedAdjustDrafts,
      costsAndCharges: validatedCostsAndCharges,
      version: validatedVersion,
    };
  }
}