import { LocalizedStore } from '@elements/localization';
import { action, observable, flow, computed } from 'mobx';
import { CurrenciesClient } from '@client/backoffice/CurrenciesClient';
import * as Backoffice from '@client/backoffice/Dto';
import type { CancellablePromise } from 'mobx/lib/api/flow';
import { SortDescriptor, LykkeGridContext } from '@elements/lykke-html/LykkeGrid';
import { sortGridData, filterGridData, ExtendedFilterDescriptor } from '@elements/lykke-html/LykkeGridData';
import { instanceOfIAjaxResponse } from '@services/network/AjaxService';
import { FrontendBadRequest, isBackofficeFrontendBadRequest } from '@client/backoffice/CustomErrors';

export class CurrenciesStore extends LocalizedStore {
  private readonly currenciesClient: CurrenciesClient;

  private brokerId: string;

  @observable public currencies: Backoffice.Currencies.CurrencyContract[] = [];
  @observable public isLoadingFetch: boolean = false;
  @observable public errorsFetch: FrontendBadRequest;

  @observable public isLoadingCreateUpdate: boolean = false;
  @observable public errorsCreateUpdate: FrontendBadRequest;

  @observable public isDeleteLoading: boolean = false;
  @observable public errorsDelete: FrontendBadRequest;

  @observable public filters: Array<ExtendedFilterDescriptor<Backoffice.Currencies.CurrencyContract>> = [
    { type: 'text', field: 'id', operator: 'contains', value: null, ignoreCase: true },
    { type: 'text', field: 'interestRateMdsCode', operator: 'contains', value: null, ignoreCase: true },
  ];
  @observable public sort: Array<SortDescriptor<Backoffice.Currencies.CurrencyContract>> = [];

  @computed
  public get filteredAndSortedCurrencies() {
    return sortGridData(filterGridData(this.currencies, this.filters), this.sort);
  }

  constructor(sandbox: jc.Sandbox, brokerId: string) {
    super(sandbox);
    this.currenciesClient = sandbox.getService('client/backoffice/currencies');
    this.brokerId = brokerId;
  }

  public fetchCurrencies: () => CancellablePromise<any> =
  flow(function* fetchCurrencies(this: CurrenciesStore) {
    this.isLoadingFetch = true;
    try {
      const data: Backoffice.Currencies.GetCurrenciesResponse = yield this.currenciesClient.fetchCurrenciesByBrokerId(this.brokerId);
      this.currencies = data.currencies;
      this.errorsFetch = undefined;
    } catch (error) {
      if (instanceOfIAjaxResponse(error)) {
        const errorData = JSON.parse(error.data);
        if (isBackofficeFrontendBadRequest(errorData)) {
          this.errorsFetch = errorData;
        }
      }
      throw error;
    } finally {
      this.isLoadingFetch = false;
    }
  });

  public createCurrency: (request: Backoffice.Currencies.CreateCurrencyRequest) => CancellablePromise<any> =
  flow(function* createCurrency(this: CurrenciesStore, request: Backoffice.Currencies.CreateCurrencyRequest) {
    this.isLoadingCreateUpdate = true;
    try {
      yield this.currenciesClient.createCurrency(request);
      this.currencies = [{id: request.id, interestRateMdsCode: request.interestRateMdsCode}, ...this.currencies];
      this.errorsCreateUpdate = undefined;
    } catch (error) {
      if (instanceOfIAjaxResponse(error)) {
        const errorData = JSON.parse(error.data);
        if (isBackofficeFrontendBadRequest(errorData)) {
          this.errorsCreateUpdate = errorData;
        }
      }
      throw error;
    } finally {
      this.isLoadingCreateUpdate = false;
    }
  });

  public updateCurrency: (currencyId: string, request: Backoffice.Currencies.UpdateCurrencyRequest) => CancellablePromise<any> =
  flow(function* updateCurrency(this: CurrenciesStore, currencyId: string, request: Backoffice.Currencies.UpdateCurrencyRequest) {
    this.isLoadingCreateUpdate = true;
    try {
      yield this.currenciesClient.updateCurrency(currencyId, request);
      this.currencies = this.currencies.map<typeof this.currencies[0]>(c => c.id === currencyId ? { id: currencyId, interestRateMdsCode: request.interestRateMdsCode } : c);
      this.errorsCreateUpdate = undefined;
    } catch (error) {
      if (instanceOfIAjaxResponse(error)) {
        const errorData = JSON.parse(error.data);
        if (isBackofficeFrontendBadRequest(errorData)) {
          this.errorsCreateUpdate = errorData;
        }
      }
      throw error;
    } finally {
      this.isLoadingCreateUpdate = false;
    }
  });

  public deleteCurrency: (currencyId: string) => CancellablePromise<any> =
  flow(function* deleteCurrency(this: CurrenciesStore, currencyId: string) {
    this.isDeleteLoading = true;
    try {
      yield this.currenciesClient.deleteCurrency(currencyId, this.brokerId);
      this.currencies = this.currencies.filter(currency => currency.id !== currencyId);
      this.errorsDelete = undefined;
    } catch (error) {
      if (instanceOfIAjaxResponse(error)) {
        const errorData = JSON.parse(error.data);
        if (isBackofficeFrontendBadRequest(errorData)) {
          this.errorsDelete = errorData;
        }
      }
      throw error;
    } finally {
      this.isDeleteLoading = false;
    }
  });

  @action.bound
  public updateFilterAndSortState(changeContext: LykkeGridContext<Backoffice.Currencies.CurrencyContract>) {
    this.filters = changeContext.filters as any; // LykkeGridContext would proxy any extensions we pass to its filters
    this.sort = changeContext.sort;
  }
}