import { AjaxService } from '@services/network/AjaxService';
import * as Backoffice from '@client/backoffice/Dto';

export class CurrenciesClient {
  private ajaxService: AjaxService;

  constructor(private core: jc.Core) {
    this.ajaxService = this.core.getService('ajax');
  }

  // Fetch list of currencies
  public async fetchCurrenciesByBrokerId(brokerId: string): Promise<Backoffice.Currencies.GetCurrenciesResponse> {
    const response = await this.ajaxService.get({
      url: `${this.core.constants.appSettings.endpoints.backoffice}/api/currencies`,
      params: {
        BrokerId: brokerId,
      }
    });

    return JSON.parse(response.data);
  }

  // Create currency
  public async createCurrency(body: Backoffice.Currencies.CreateCurrencyRequest) {
    const response = await this.ajaxService.post({
      url: `${this.core.constants.appSettings.endpoints.backoffice}/api/currencies`,
      data: JSON.stringify(body),
    });

    return response;
  }

  // Create currency
  public async updateCurrency(currencyId: string, body: Backoffice.Currencies.UpdateCurrencyRequest) {
    const response = await this.ajaxService.put({
      url: `${this.core.constants.appSettings.endpoints.backoffice}/api/currencies/${currencyId}`,
      data: JSON.stringify(body),
    });

    return response;
  }

  // Delete currency
  public async deleteCurrency(currencyId: string, brokerId: string) {
    const response = await this.ajaxService.delete({
      url: `${this.core.constants.appSettings.endpoints.backoffice}/api/currencies/${currencyId}`,
      params: {
        BrokerId: brokerId,
      }
    });

    return response;
  }

}