import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { ILocalizedProps } from '@elements/localization';
import * as Backoffice from '@client/backoffice/Dto';
import * as Cronut from '@client/cronut/Dto';
import { LykkeGrid, LykkeGridColumn, FilterDescriptor, LykkeGridContext, LykkeGridActionsCell, VirtualField, PaginationDescriptor } from '@elements/lykke-html/LykkeGrid';
import { Intent, AnchorButton, Icon } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';

interface Props extends ILocalizedProps {
  data: Array<Backoffice.Users.User | Cronut.Users.User>;
  loading: boolean;
  filters: Array<FilterDescriptor<Backoffice.Users.User | Cronut.Users.User>>;
  onStateChange?(changeContext: LykkeGridContext<Backoffice.Users.User | Cronut.Users.User>): void;
  pagination: PaginationDescriptor;
  editClick: (username: string) => void;
  deleteClick: (username: string) => void;
}

@inject('i18n')
export class UserRolesTable extends Component<Props> {
  private get usernameColumn(): LykkeGridColumn<Backoffice.Users.User | Cronut.Users.User> {
    const t = this.props.i18n.UserRoles;
    return {
      field: 'username',
      name: t.UserNameColumn,
      filterable: true
    };
  }

  private get rolesColumn(): LykkeGridColumn<Backoffice.Users.User | Cronut.Users.User> {
    const t = this.props.i18n.UserRoles;
    return {
      field: 'roles',
      name: t.RolesColumn,
      getCellContent: item => item.roles?.join(', ')
    };
  }

  private get actionsColumn(): LykkeGridColumn<Backoffice.Users.User | Cronut.Users.User> {
    const t = this.props.i18n.UserRoles;
    return {
      field: 'actions' as VirtualField,
      name: t.ActionsColumn,
      fixed: 'right',
      initialWidth: 100,
      getCellContent: item => (
        <LykkeGridActionsCell>
          <Tooltip2 content={t.EditButtonLabel}>
            <AnchorButton intent={Intent.NONE} onClick={() => this.props.editClick(item.username)} icon={<Icon icon='edit' title={false} />}></AnchorButton>
          </Tooltip2>

          <Tooltip2 content={t.DeleteButtonLabel}>
            <AnchorButton intent={Intent.DANGER} onClick={() => this.props.deleteClick(item.username)} icon={<Icon icon='trash' title={false} />}></AnchorButton>
          </Tooltip2>
        </LykkeGridActionsCell>
      ),
    };
  }

  private get columns() {
    return [
      this.usernameColumn,
      this.rolesColumn,
      this.actionsColumn,
    ];
  }

  public render() {
    return (
      <LykkeGrid
        uniqueRowKey='id'
        columns={this.columns}
        filters={this.props.filters}
        onStateChange={this.props.onStateChange}
        loading={this.props.loading}
        rows={this.props.data}
        pageable={true}
        pagination={this.props.pagination}
        paginationSettings={{ position: ['bottomRight'], showSizeChanger: true }}
      ></LykkeGrid>
    );
  }
}