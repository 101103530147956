import { LocalizedStore } from '@elements/localization';
import { action, observable, flow, computed } from 'mobx';
import * as Backoffice from '@client/backoffice/Dto';
import type { CancellablePromise } from 'mobx/lib/api/flow';
import { SortDescriptor, LykkeGridContext } from '@elements/lykke-html/LykkeGrid';
import { sortGridData, filterGridData, ExtendedFilterDescriptor } from '@elements/lykke-html/LykkeGridData';
import { instanceOfIAjaxResponse } from '@services/network/AjaxService';
import { FrontendBadRequest, isBackofficeFrontendBadRequest } from '@client/backoffice/CustomErrors';
import { BrokerSettingsClient } from '@client/backoffice/BrokerSettingsClient';
import { RegulatoryTypesClient } from '@client/backoffice/RegulatoryTypesClient';
import { AssetTypesClient } from '@client/backoffice/AssetTypesClient';
import { UnderlyingsClient } from '@client/backoffice/UnderlyingsClient';

export class AssetTypesStore extends LocalizedStore {
  private readonly assetTypesClient: AssetTypesClient;
  private readonly brokerSettingsClient: BrokerSettingsClient;
  private readonly regulatoryTypesClient: RegulatoryTypesClient;
  private readonly underlyingsClient: UnderlyingsClient;

  private readonly brokerId: string;

  @observable public assetTypes: Backoffice.AssetTypes.AssetTypeContract[] = [];
  @observable public isLoadingFetch: boolean = false;
  @observable public errorsFetch: FrontendBadRequest;

  @observable public isLoadingCreateUpdate: boolean = false;
  @observable public errorsCreateUpdate: FrontendBadRequest;

  @observable public isDeleteLoading: boolean = false;
  @observable public errorsDelete: FrontendBadRequest;

  @observable public regulatoryTypes: Backoffice.RegulatoryType.RegulatoryTypeContract[] = [];
  @observable public isLoadingRegulatoryTypesFetch: boolean = false;
  @observable public errorsRegulatoryTypesFetch: FrontendBadRequest;

  @observable public underlyingCategoryIds: string[] = [];
  @observable public isLoadingUnderlyingCategoryIdsFetch: boolean = false;
  @observable public errorsUnderlyingCategoryIdsFetch: FrontendBadRequest;

  @observable public filters: Array<ExtendedFilterDescriptor<Backoffice.AssetTypes.AssetTypeContract>> = [
    { type: 'text', field: 'id', operator: 'contains', value: null, ignoreCase: true },
    { type: 'text', field: 'regulatoryTypeId', operator: 'contains', value: null, ignoreCase: true },
  ];
  @observable public sort: Array<SortDescriptor<Backoffice.AssetTypes.AssetTypeContract>> = [];

  @computed
  public get filteredAndSortedAssetTypes() {
    return sortGridData(filterGridData(this.assetTypes, this.filters), this.sort);
  }

  constructor(sandbox: jc.Sandbox, brokerId: string) {
    super(sandbox);
    this.assetTypesClient = sandbox.getService('client/backoffice/asset-types');
    this.brokerSettingsClient = sandbox.getService('client/backoffice/broker-settings');
    this.regulatoryTypesClient = sandbox.getService('client/backoffice/regulatory-types');
    this.underlyingsClient = sandbox.getService('client/backoffice/underlyings');
    this.brokerId = brokerId;
  }

  public fetchAssetTypes: () => CancellablePromise<void> =
  flow(function* fetchAssetTypes(this: AssetTypesStore) {
    this.isLoadingFetch = true;
    try {
      const data: Backoffice.AssetTypes.GetAssetTypesRequest = yield this.assetTypesClient.getAssetTypesAsync({
        brokerId: this.brokerId
      });
      this.assetTypes = data.assetTypes;
      this.errorsFetch = undefined;
    } catch (error) {
      if (instanceOfIAjaxResponse(error)) {
        const errorData = JSON.parse(error.data);
        if (isBackofficeFrontendBadRequest(errorData)) {
          this.errorsFetch = errorData;
        }
      }
      throw error;
    } finally {
      this.isLoadingFetch = false;
    }
  });

  public createAssetType: (request: Backoffice.AssetTypes.AddAssetTypeRequest) => CancellablePromise<void> =
  flow(function* createAssetType(this: AssetTypesStore, request: Backoffice.AssetTypes.AddAssetTypeRequest) {
    this.isLoadingCreateUpdate = true;
    try {
      yield this.assetTypesClient.addAssetTypeAsync(request);
      this.assetTypes = [{
        id: request.id,
        regulatoryTypeId: request.regulatoryTypeId,
        underlyingCategoryId: request.underlyingCategoryId,
        excludeSpreadFromProductCosts: request.excludeSpreadFromProductCosts
      }, ...this.assetTypes];
      this.errorsCreateUpdate = undefined;
    } catch (error) {
      if (instanceOfIAjaxResponse(error)) {
        const errorData = JSON.parse(error.data);
        if (isBackofficeFrontendBadRequest(errorData)) {
          this.errorsCreateUpdate = errorData;
        }
      }
      throw error;
    } finally {
      this.isLoadingCreateUpdate = false;
    }
  });

  public updateAssetType: (assetTypeId: string, request: Backoffice.AssetTypes.UpdateAssetTypeRequest) => CancellablePromise<void> =
  flow(function* updateAssetType(this: AssetTypesStore, assetTypeId: string, request: Backoffice.AssetTypes.UpdateAssetTypeRequest) {
    this.isLoadingCreateUpdate = true;
    try {
      yield this.assetTypesClient.updateAssetTypeAsync(assetTypeId, request);
      this.assetTypes = this.assetTypes.map(c => c.id === assetTypeId ? {
        id: assetTypeId,
        regulatoryTypeId: request.regulatoryTypeId,
        underlyingCategoryId: request.underlyingCategoryId,
        excludeSpreadFromProductCosts: request.excludeSpreadFromProductCosts
      } : c);
      this.errorsCreateUpdate = undefined;
    } catch (error) {
      if (instanceOfIAjaxResponse(error)) {
        const errorData = JSON.parse(error.data);
        if (isBackofficeFrontendBadRequest(errorData)) {
          this.errorsCreateUpdate = errorData;
        }
      }
      throw error;
    } finally {
      this.isLoadingCreateUpdate = false;
    }
  });

  public deleteAssetType: (assetTypeId: string) => CancellablePromise<void> =
  flow(function* deleteAssetType(this: AssetTypesStore, assetTypeId: string) {
    this.isDeleteLoading = true;
    try {
      yield this.assetTypesClient.deleteAssetTypeAsync(assetTypeId, { brokerId: this.brokerId });
      this.assetTypes = this.assetTypes.filter(assetType => assetType.id !== assetTypeId);
      this.errorsDelete = undefined;
    } catch (error) {
      if (instanceOfIAjaxResponse(error)) {
        const errorData = JSON.parse(error.data);
        if (isBackofficeFrontendBadRequest(errorData)) {
          this.errorsDelete = errorData;
        }
      }
      throw error;
    } finally {
      this.isDeleteLoading = false;
    }
  });

  @action.bound
  public updateFilterAndSortState(changeContext: LykkeGridContext<Backoffice.AssetTypes.AssetTypeContract>) {
    this.filters = changeContext.filters as any; // LykkeGridContext would proxy any extensions we pass to its filters
    this.sort = changeContext.sort;
  }

  public fetchRegulatoryTypes: () => CancellablePromise<void> =
  flow(function* fetchRegulatoryTypes(this: AssetTypesStore) {
    this.isLoadingRegulatoryTypesFetch = true;
    try {
      const brokerSettings: Backoffice.BrokerSettings.BrokerSettingsContract = yield this.brokerSettingsClient.getByIdAsync(this.brokerId);
      const regulatoryTypesResponse: Backoffice.RegulatoryType.GetRegulatoryTypesResponse = yield this.regulatoryTypesClient
        .getRegulatoryTypesAsync(brokerSettings.regulationId);
      this.regulatoryTypes = regulatoryTypesResponse.regulatoryTypes;
      this.errorsRegulatoryTypesFetch = undefined;
    } catch (error) {
      if (instanceOfIAjaxResponse(error)) {
        const errorData = JSON.parse(error.data);
        if (isBackofficeFrontendBadRequest(errorData)) {
          this.errorsRegulatoryTypesFetch = errorData;
        }
      }
      throw error;
    } finally {
      this.isLoadingRegulatoryTypesFetch = false;
    }
  });

  public fetchUnderlyingCategoryIds: () => CancellablePromise<void> =
  flow(function* fetchUnderlyingCategoryIds(this: AssetTypesStore) {
    this.isLoadingUnderlyingCategoryIdsFetch = true;
    try {
      this.underlyingCategoryIds = yield this.underlyingsClient.getCategoryIdsAsync();
      this.errorsUnderlyingCategoryIdsFetch = undefined;
    } catch (error) {
      if (instanceOfIAjaxResponse(error)) {
        const errorData = JSON.parse(error.data);
        if (isBackofficeFrontendBadRequest(errorData)) {
          this.errorsUnderlyingCategoryIdsFetch = errorData;
        }
      }
      throw error;
    } finally {
      this.isLoadingUnderlyingCategoryIdsFetch = false;
    }
  });
}