export enum HistoricalColumnNames {
  Symbol = 'symbol',
  AssetName= 'assetName',
  SignedQuantity = 'signedQuantity',
  Type = 'type',
  Status = 'status',
  LimitStop = 'limitStop',
  TakeProfit = 'takeProfit',
  StopLoss = 'stopLoss',
  Sell = 'sell',
  Buy = 'buy',
  ExecutionPrice = 'executionPrice',
  TradingVolume = 'tradingVolume',
  TradingVolumeDisplay = 'tradingVolumeDisplay',
  DisplayFxRate = 'displayFxRate',
  Direction = 'direction',
  Origin = 'origin',
  Id = 'id',
  CreatedAt = 'createdAt',
  ModifiedOn = 'modifiedOn',
  Adjust = 'adjust',
  Validity = 'validity',
  UserComments = 'userComments',
  Commission = 'commission',
  OnBehalfFee = 'onBehalfFee',
  Spread = 'spread',
  ForcedOpen = 'forcedOpen',
  DownloadPdf = 'downloadPdf'
}

export interface HistoricalColumn {
  key?: HistoricalColumnNames;
  name?: string;
  headerRenderer?: any;
  width?: number;
  draggable?: boolean;
  sortable?: boolean;
  filterable?: boolean;
  resizable?: boolean;
}
