import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { ILocalizedProps } from '@elements/localization';
import { LykkeGrid, LykkeGridColumn, FilterDescriptor, SortDescriptor, LykkeGridContext } from '@elements/lykke-html/LykkeGrid';
import { Classes } from '@blueprintjs/core';
import { KnownModule } from '@models/enums/KnownModules';
import { TabManagementSettingsContractExtended } from '../CustomerTabManagementStore';
import { TabOwnershipList } from '@client/backoffice/Dto/TabManagementSettings';
import * as Backoffice from '@client/backoffice/Dto';
import { computed } from 'mobx';
import { orderBy } from 'lodash';
import styled from 'styled-components';

const StaledWrapper = styled.div`
  width: 100%;
  height: 100%;
  td.new {
    background-color: ${(props) => props.theme.layouts.header.backgroundColor} !important;
  }
`;

interface Props extends ILocalizedProps {
  data: TabManagementSettingsContractExtended[];
  loading: boolean;
  filters: Array<FilterDescriptor<TabManagementSettingsContractExtended>>;
  sort: Array<SortDescriptor<TabManagementSettingsContractExtended>>;
  onStateChange(changeContext: LykkeGridContext<TabManagementSettingsContractExtended>): void;
  onChangeType(tabId: number, value: Backoffice.TabManagementSettings.TabOwnership): void;
  onChangeVisible(tabId: number, value: boolean): void;
  onChangeAllowedForDefaultLayout(tabId: number, value: boolean): void;
}

@inject('i18n')
export class CustomerTabManagementTable extends Component<Props> {
  private getItemLocalizedTabId = (item: TabManagementSettingsContractExtended) => {
    const t = this.props.i18n.BrokerSettingsView.CustomerTabManagementTab;
    const key = KnownModule[item.tabId];
    return (t.Tabs[key] ?? key) as string;
  };

  private get tabIdColumn(): LykkeGridColumn<TabManagementSettingsContractExtended> {
    const t = this.props.i18n.BrokerSettingsView.CustomerTabManagementTab;
    return {
      field: 'tabId',
      name: t.TabIdColumn,
      sortable: true,
      filterable: true,
      getCellContent: this.getItemLocalizedTabId,
      getCellClassName: this.getCellClassName,
    };
  }

  private get tabOwnershipColumn(): LykkeGridColumn<TabManagementSettingsContractExtended> {
    const t = this.props.i18n.BrokerSettingsView.CustomerTabManagementTab;
    return {
      field: 'tabOwnership',
      name: t.TabOwnershipColumn,
      sortable: true,
      getCellContent: (item) => {
        if (!item.isNew) {
          return t.Types[item.tabOwnership];
        }
        return (
          <div className={Classes.SELECT}>
            <select
              disabled={this.props.loading}
              value={item.tabOwnership}
              onChange={(e) => this.props.onChangeType(item.tabId, e.target.value as Backoffice.TabManagementSettings.TabOwnership)}
            >
              {TabOwnershipList.map((x) => (
                <option key={x} value={x}>
                  {t.Types[x]}
                </option>
              ))}
            </select>
          </div>
        );
      },
      getCellClassName: this.getCellClassName,
    };
  }

  private get isVisibleColumn(): LykkeGridColumn<TabManagementSettingsContractExtended> {
    const t = this.props.i18n.BrokerSettingsView.CustomerTabManagementTab;
    return {
      field: 'isVisible',
      name: t.IsVisibleColumn,
      getCellContent: (item) => (
        <input
          type='checkbox'
          className={Classes.CHECKBOX}
          checked={item.isVisible}
          onChange={(e) => this.props.onChangeVisible(item.tabId, e.target.checked)}
          disabled={!item.isNew}
        />
      ),
      getCellClassName: this.getCellClassName,
    };
  }

  private get isAllowedForDefaultLayoutColumn(): LykkeGridColumn<TabManagementSettingsContractExtended> {
    const t = this.props.i18n.BrokerSettingsView.CustomerTabManagementTab;
    return {
      field: 'isAllowedForDefaultLayout',
      name: t.IsAllowedForDefaultLayoutColumn,
      getCellContent: (item) => (
        <input
          type='checkbox'
          className={Classes.CHECKBOX}
          checked={item.isAllowedForDefaultLayout}
          onChange={(e) => this.props.onChangeAllowedForDefaultLayout(item.tabId, e.target.checked)}
        />
      ),
      getCellClassName: this.getCellClassName,
    };
  }

  private get columns() {
    return [this.tabIdColumn, this.tabOwnershipColumn, this.isVisibleColumn, this.isAllowedForDefaultLayoutColumn];
  }

  private getCellClassName = (item: TabManagementSettingsContractExtended) => (item.isNew ? ['new'] : []);

  @computed
  public get filteredAndSortedItems() {
    const t = this.props.i18n.BrokerSettingsView.CustomerTabManagementTab;
    let localizedData = this.props.data.map((x) => ({
      localizedTabId: this.getItemLocalizedTabId(x),
      localizedTabOwnership: t.Types[x.tabOwnership],
      data: x,
    }));
    // TODO: refactor
    const tabIdFilter = this.props.filters.find((x) => x.field === 'tabId');
    if (!!tabIdFilter?.value) {
      localizedData = localizedData.filter((x) => x.localizedTabId?.toLocaleLowerCase().includes(tabIdFilter.value.toLocaleLowerCase()));
    }
    const tabIdSort = this.props.sort.find((x) => x.field === 'tabId');
    if (!!tabIdSort) {
      localizedData = orderBy(localizedData, ['localizedTabId'], [tabIdSort.direction === 'ascend' ? 'asc' : 'desc']);
    }
    const tabOwnershipSort = this.props.sort.find((x) => x.field === 'tabOwnership');
    if (!!tabOwnershipSort) {
      localizedData = orderBy(localizedData, ['localizedTabOwnership'], [tabOwnershipSort.direction === 'ascend' ? 'asc' : 'desc']);
    }
    return localizedData.map((x) => x.data);
  }

  public render() {
    return (
      <StaledWrapper>
        <LykkeGrid
          uniqueRowKey='tabId'
          columns={this.columns}
          filters={this.props.filters}
          sort={this.props.sort}
          onStateChange={this.props.onStateChange}
          loading={this.props.loading}
          rows={this.filteredAndSortedItems}
        />
      </StaledWrapper>
    );
  }
}
