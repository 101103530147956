import React from 'react';
import { Button, Intent, Classes } from '@blueprintjs/core';
import * as Backoffice from '@client/backoffice/Dto';
import { LabelWithTextInput, LykkeForm } from '@elements/lykke-html/form/LykkeForm';
import { ILocalizedProps } from '@elements/localization';
import { inject } from 'mobx-react';
import { FrontendError } from '@client/backoffice/CustomErrors';
import { BackofficeErrorCodes } from '@client/backoffice/ErrorCodes';
import { omit, every } from 'lodash';

interface Props extends ILocalizedProps {
  onCancel: () => void;
  onSubmit: (regulationData: Backoffice.Regulations.RegulationContract) => void;
  loading: boolean;
  serverErrors: FrontendError[];
}

interface State {
  formData: Backoffice.Regulations.RegulationContract;
  fieldErrors: {[P in keyof Backoffice.Regulations.RegulationContract]?: string};
}

const getEmptyFormData = () => ({
  id: ''
} as Backoffice.Regulations.RegulationContract);

@inject('i18n')
export class AddRegulatonForm extends React.Component<Props, State> {
  public state: State = {
    formData: getEmptyFormData(),
    fieldErrors: {},
  };

  private handleRegulationIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFormData = {
      ...this.state.formData,
      id: e.target.value
    };

    this.setState({ formData: newFormData });
  }

  private handleSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    if (this.validate()) {
      this.props.onSubmit(this.state.formData);
    }
  }

  private validateId = () => {
    const t = this.props.i18n.RegulationRules.Regulations.ClientErrors;
    const value = this.state.formData.id;

    if (!value) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, id: t.RegulationIdFieldRequired}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'id')
      }));
      return true;
    }
  }

  private validate = () => {
    return every([
      this.validateId(),
    ]);
  }

  private getServerErrorMessages(field: keyof Backoffice.Regulations.RegulationContract) {
    return this.props.serverErrors
      ?.filter(e => e.errorCode as BackofficeErrorCodes === 'FieldValidationFailed' && e.fieldNames.includes(field))
      .map(e => e.errorMessage) ?? [];
  }

  private getClientErrorMessage(field: keyof Backoffice.Regulations.RegulationContract) {
    return this.state.fieldErrors[field];
  }

  private formatErrorMessages(messages: string[]) {
    return messages.filter(m => !!m).join('; ');
  }

  public render() {
    const { loading } = this.props;
    const t = this.props.i18n.RegulationRules.Regulations;

    return (
      <LykkeForm>
        <div className={Classes.DIALOG_BODY}>
          <LabelWithTextInput
            autoFocus={true}
            label={t.RegulationIdLabel}
            value={this.state.formData.id}
            onChange={this.handleRegulationIdChange}
            onBlur={this.validateId}
            error={this.formatErrorMessages([this.getClientErrorMessage('id'), ...this.getServerErrorMessages('id')])}
          />
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={this.props.onCancel}
              disabled={loading}
              intent={Intent.NONE}
            >
              {t.CancelButtonLabel}
            </Button>
            <Button
              type='submit'
              onClick={this.handleSubmit}
              disabled={loading}
              loading={loading}
              intent={Intent.SUCCESS}
            >
              {t.CreateButtonLabel}
            </Button>
          </div>
        </div>
      </LykkeForm>
    );
  }
}