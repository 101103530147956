import React from 'react';
import { Button, Intent, Classes } from '@blueprintjs/core';
import * as Backoffice from '@client/backoffice/Dto';
import { LabelWithTextInput, LykkeForm, LabelWithSelect, LabelWithCheckbox } from '@elements/lykke-html/form/LykkeForm';
import { ILocalizedProps } from '@elements/localization';
import { inject } from 'mobx-react';
import { FrontendError } from '@client/backoffice/CustomErrors';
import { BackofficeErrorCodes } from '@client/backoffice/ErrorCodes';
import { omit, every } from 'lodash';

interface Props extends ILocalizedProps {
  itemToEdit?: Backoffice.ClientProfiles.ClientProfileContract;
  sourceProfileId?: string;
  regulatoryProfiles: Backoffice.RegulatoryProfile.RegulatoryProfileContract[];
  onCancel: () => void;
  onSubmit: (profileData: Backoffice.ClientProfiles.ClientProfileContract, sourceProfileId?: string) => void;
  loading: boolean;
  serverErrors: FrontendError[];
}

interface State {
  formData: Backoffice.ClientProfiles.ClientProfileContract;
  fieldErrors: {[P in keyof Backoffice.ClientProfiles.ClientProfileContract]?: string};
}

const getEmptyFormData = (regulatoryTypes: Backoffice.RegulatoryProfile.RegulatoryProfileContract[]) => ({
  id: '',
  isDefault: false,
  regulatoryProfileId: regulatoryTypes[0].id
} as Backoffice.ClientProfiles.ClientProfileContract);

@inject('i18n')
export class AddEditProfileForm extends React.Component<Props, State> {
  public state: State = {
    formData: { ...(this.props.itemToEdit ?? getEmptyFormData(this.props.regulatoryProfiles)) },
    fieldErrors: {},
  };

  private get regulatoryProfileOptions() {
    return this.props.regulatoryProfiles.map(x => ({
      value: x.id,
      text: x.id
    }));
  }

  private handleProfileIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFormData = {
      ...this.state.formData,
      id: e.target.value
    };

    this.setState({ formData: newFormData });
  }

  private handleRegulatoryProfileIdChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newFormData = {
      ...this.state.formData,
      regulatoryProfileId: e.target.value
    };

    this.setState({ formData: newFormData });
  }

  private handleIsDefaultChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFormData = {
      ...this.state.formData,
      isDefault: e.target.checked
    };

    this.setState({ formData: newFormData });
  }

  private handleSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    if (this.validate()) {
      this.props.onSubmit(this.state.formData, this.props.sourceProfileId);
    }
  }

  private validateId = () => {
    const t = this.props.i18n.BrokerSettingsView.ProfilesParametersTab.Profiles.ClientErrors;
    const value = this.state.formData.id;

    if (!value) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, id: t.ProfileIdFieldRequired}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'id')
      }));
      return true;
    }
  }

  private validate = () => {
    return every([
      this.validateId(),
    ]);
  }

  private getServerErrorMessages(field: keyof Backoffice.ClientProfiles.ClientProfileContract) {
    return this.props.serverErrors
      ?.filter(e => e.errorCode as BackofficeErrorCodes === 'FieldValidationFailed' && e.fieldNames.includes(field))
      .map(e => e.errorMessage) ?? [];
  }

  private getClientErrorMessage(field: keyof Backoffice.ClientProfiles.ClientProfileContract) {
    return this.state.fieldErrors[field];
  }

  private formatErrorMessages(messages: string[]) {
    return messages.filter(m => !!m).join('; ');
  }

  public render() {
    const { itemToEdit = null, loading } = this.props;
    const t = this.props.i18n.BrokerSettingsView.ProfilesParametersTab.Profiles;

    return (
      <LykkeForm>
        <div className={Classes.DIALOG_BODY}>
          <LabelWithTextInput
            autoFocus={true}
            label={t.ProfileIdLabel}
            value={this.state.formData.id}
            onChange={this.handleProfileIdChange}
            onBlur={this.validateId}
            disabled={!!itemToEdit}
            error={this.formatErrorMessages([this.getClientErrorMessage('id'), ...this.getServerErrorMessages('id')])}
          />
          {this.props.sourceProfileId && <LabelWithTextInput
            label={t.SourceProfileIdLabel}
            disabled={true}
            value={this.props.sourceProfileId} />}
          <LabelWithSelect
            label={t.RegulatoryProfileLabel}
            value={this.state.formData.regulatoryProfileId}
            options={this.regulatoryProfileOptions}
            onChange={this.handleRegulatoryProfileIdChange} />
          <LabelWithCheckbox
            label={this.props.sourceProfileId ? t.NewDefaultLabel : t.ThisDefaultLabel}
            value={this.state.formData.isDefault}
            onChange={this.handleIsDefaultChange} />
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={this.props.onCancel}
              disabled={loading}
              intent={Intent.NONE}
            >
              {t.CancelButtonLabel}
            </Button>
            <Button
              type='submit'
              onClick={this.handleSubmit}
              disabled={loading}
              loading={loading}
              intent={Intent.SUCCESS}
            >
              {itemToEdit
                ? t.UpdateButtonLabel
                : this.props.sourceProfileId
                  ? t.DuplicateOkButtonLabel
                  : t.CreateButtonLabel}
            </Button>
          </div>
        </div>
      </LykkeForm>
    );
  }
}