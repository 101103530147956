import { LocalizedStore } from '@elements/localization';
import { action, observable, flow } from 'mobx';
import * as Backoffice from '@client/backoffice/Dto';
import type { CancellablePromise } from 'mobx/lib/api/flow';
import { SortDescriptor, LykkeGridContext } from '@elements/lykke-html/LykkeGrid';
import { ExtendedFilterDescriptor } from '@elements/lykke-html/LykkeGridData';
import { instanceOfIAjaxResponse } from '@services/network/AjaxService';
import { FrontendBadRequest, isBackofficeFrontendBadRequest } from '@client/backoffice/CustomErrors';
import { TabManagementSettingsClient } from '@client/backoffice/TabManagementSettingsClient';
import { KnownModule } from '@models/enums/KnownModules';

export interface TabManagementSettingsContractExtended extends Backoffice.TabManagementSettings.TabManagementSettingsContract {
  isNew: boolean;
}

const DeprecatedTabs: KnownModule[] = [
  KnownModule.Loading,
  KnownModule.Report,
  KnownModule.Assets,
  KnownModule.CorporateActionsToValidateDeprecated,
  KnownModule.CorporateActionsToInvalidateDeprecated,
  KnownModule.CorporateActionNoticeEditDeprecated,
  KnownModule.CorporateActionsAuditTrailDeprecated,
  KnownModule.AssetEdit,
  KnownModule.CorporateActionsTypesDeprecated,
  KnownModule.UnderlyingCreate,
  KnownModule.UnderlyingEdit,
  KnownModule.ProductCreate,
  KnownModule.ProductEdit,
  KnownModule.AuditTrails,
  KnownModule.TabWatchlistMenu,
];

export class CustomerTabManagementStore extends LocalizedStore {
  private readonly client: TabManagementSettingsClient;

  private brokerId: string;

  @observable public items: TabManagementSettingsContractExtended[] = [];
  @observable public isLoadingFetch: boolean = false;
  @observable public errorsFetch: FrontendBadRequest;

  @observable public isLoadingCreateUpdate: boolean = false;
  @observable public errorsCreateUpdate: FrontendBadRequest;

  @observable public filters: Array<ExtendedFilterDescriptor<TabManagementSettingsContractExtended>> = [
    { type: 'text', field: 'tabId', operator: 'contains', value: null, ignoreCase: true },
  ];
  @observable public sort: Array<SortDescriptor<TabManagementSettingsContractExtended>> = [];

  constructor(sandbox: jc.Sandbox, brokerId: string) {
    super(sandbox);
    this.client = sandbox.getService('client/backoffice/tab-management-settings');
    this.brokerId = brokerId;
  }

  @action
  public changeType(tabId: number, value: Backoffice.TabManagementSettings.TabOwnership) {
    const item = this.items.find(x => x.tabId === tabId);
    item.tabOwnership = value;
  }

  @action
  public changeVisible(tabId: number, value: boolean) {
    const item = this.items.find(x => x.tabId === tabId);
    item.isVisible = value;
  }

  @action
  public changeAllowedForDefaultLayout(tabId: number, value: boolean) {
    const item = this.items.find(x => x.tabId === tabId);
    item.isAllowedForDefaultLayout = value;
  }

  public fetchTabManagementSettings: () => CancellablePromise<any> = flow(function* fetchTabManagementSettings(this: CustomerTabManagementStore) {
    this.isLoadingFetch = true;
    try {
      const data: Backoffice.TabManagementSettings.GetTabManagementSettingsResponse = yield this.client.getTabManagementSettingsAsync({
        brokerId: this.brokerId,
      });
      const newTabIds = Object.keys(KnownModule)
        .map((key) => Number(KnownModule[key]))
        .filter((id) => !isNaN(id) && !DeprecatedTabs.includes(id) && !data.tabManagementSettingsContracts.map((x) => x.tabId).includes(id));
      this.items = [
        ...newTabIds.map(
          (x) =>
            ({
              isNew: true,
              tabId: x,
              tabOwnership: 'CommonInvestor',
              isVisible: false,
              isAllowedForDefaultLayout: false,
            } as TabManagementSettingsContractExtended)
        ),
        ...data.tabManagementSettingsContracts.map((x) => ({
          isNew: false,
          ...x,
        })),
      ];
      this.errorsFetch = undefined;
    } catch (error) {
      if (instanceOfIAjaxResponse(error)) {
        const errorData = JSON.parse(error.data);
        if (isBackofficeFrontendBadRequest(errorData)) {
          this.errorsFetch = errorData;
        }
      }
      throw error;
    } finally {
      this.isLoadingFetch = false;
    }
  });

  public addOrUpdateTabManagementSettings: (request: Backoffice.TabManagementSettings.AddOrUpdateTabSettingsRequest) => CancellablePromise<any> = flow(function* addOrUpdateTabManagementSettings(
    this: CustomerTabManagementStore,
    request: Backoffice.TabManagementSettings.AddOrUpdateTabSettingsRequest
  ) {
    this.isLoadingCreateUpdate = true;
    try {
      yield this.client.addOrUpdateTabManagementSettingsAsync(request);
      this.items.forEach(x => x.isNew = false);
      this.errorsCreateUpdate = undefined;
    } catch (error) {
      if (instanceOfIAjaxResponse(error)) {
        const errorData = JSON.parse(error.data);
        if (isBackofficeFrontendBadRequest(errorData)) {
          this.errorsCreateUpdate = errorData;
        }
      }
      throw error;
    } finally {
      this.isLoadingCreateUpdate = false;
    }
  });

  @action.bound
  public updateFilterAndSortState(changeContext: LykkeGridContext<TabManagementSettingsContractExtended>) {
    this.filters = changeContext.filters as any; // LykkeGridContext would proxy any extensions we pass to its filters
    this.sort = changeContext.sort;
  }
}
