import { LocalizedStore } from '@elements/localization';
import { action, observable, flow, computed } from 'mobx';
import * as Backoffice from '@client/backoffice/Dto';
import type { CancellablePromise } from 'mobx/lib/api/flow';
import { SortDescriptor, LykkeGridContext } from '@elements/lykke-html/LykkeGrid';
import { sortGridData, filterGridData, ExtendedFilterDescriptor } from '@elements/lykke-html/LykkeGridData';
import { instanceOfIAjaxResponse } from '@services/network/AjaxService';
import { FrontendBadRequest, isBackofficeFrontendBadRequest } from '@client/backoffice/CustomErrors';
import { ClientProfilesClient } from '@client/backoffice/ClientProfilesClient';
import { RegulatoryProfilesClient } from '@client/backoffice/RegulatoryProfilesClient';
import { BrokerSettingsClient } from '@client/backoffice/BrokerSettingsClient';

export class ProfilesStore extends LocalizedStore {
  private readonly profilesClient: ClientProfilesClient;
  private readonly brokerSettingsClient: BrokerSettingsClient;
  private readonly regulatoryProfilesClient: RegulatoryProfilesClient;

  private readonly brokerId: string;

  @observable public profiles: Backoffice.ClientProfiles.ClientProfileContract[] = [];
  @observable public isLoadingFetch: boolean = false;
  @observable public errorsFetch: FrontendBadRequest;

  @observable public isLoadingCreateUpdate: boolean = false;
  @observable public errorsCreateUpdate: FrontendBadRequest;

  @observable public isDeleteLoading: boolean = false;
  @observable public errorsDelete: FrontendBadRequest;

  @observable public regulatoryProfiles: Backoffice.RegulatoryProfile.RegulatoryProfileContract[] = [];
  @observable public isLoadingRegulatoryProfilesFetch: boolean = false;
  @observable public errorsRegulatoryProfilesFetch: FrontendBadRequest;

  @observable public filters: Array<ExtendedFilterDescriptor<Backoffice.ClientProfiles.ClientProfileContract>> = [
    { type: 'text', field: 'id', operator: 'contains', value: null, ignoreCase: true },
    { type: 'text', field: 'regulatoryProfileId', operator: 'contains', value: null, ignoreCase: true },
  ];
  @observable public sort: Array<SortDescriptor<Backoffice.ClientProfiles.ClientProfileContract>> = [];

  @computed
  public get filteredAndSortedProfiles() {
    return sortGridData(filterGridData(this.profiles, this.filters), this.sort);
  }

  constructor(sandbox: jc.Sandbox, brokerId: string) {
    super(sandbox);
    this.profilesClient = sandbox.getService('client/backoffice/client-profiles');
    this.brokerSettingsClient = sandbox.getService('client/backoffice/broker-settings');
    this.regulatoryProfilesClient = sandbox.getService('client/backoffice/regulatory-profiles');
    this.brokerId = brokerId;
  }

  public fetchProfiles: () => CancellablePromise<any> =
  flow(function* fetchProfiles(this: ProfilesStore) {
    this.isLoadingFetch = true;
    try {
      const data: Backoffice.ClientProfiles.GetAllClientProfilesResponse = yield this.profilesClient.getClientProfilesAsync({
        brokerId: this.brokerId
      });
      this.profiles = data.clientProfiles;
      this.errorsFetch = undefined;
    } catch (error) {
      if (instanceOfIAjaxResponse(error)) {
        const errorData = JSON.parse(error.data);
        if (isBackofficeFrontendBadRequest(errorData)) {
          this.errorsFetch = errorData;
        }
      }
      throw error;
    } finally {
      this.isLoadingFetch = false;
    }
  });

  public createProfile: (request: Backoffice.ClientProfiles.AddClientProfileRequest) => CancellablePromise<any> =
  flow(function* createProfile(this: ProfilesStore, request: Backoffice.ClientProfiles.AddClientProfileRequest) {
    this.isLoadingCreateUpdate = true;
    try {
      yield this.profilesClient.addClientProfileAsync(request);
      this.errorsCreateUpdate = undefined;
      yield this.fetchProfiles();
    } catch (error) {
      if (instanceOfIAjaxResponse(error)) {
        const errorData = JSON.parse(error.data);
        if (isBackofficeFrontendBadRequest(errorData)) {
          this.errorsCreateUpdate = errorData;
        }
      }
      throw error;
    } finally {
      this.isLoadingCreateUpdate = false;
    }
  });

  public updateProfile: (profileId: string, request: Backoffice.ClientProfiles.UpdateClientProfileRequest) => CancellablePromise<any> =
  flow(function* updateProfile(this: ProfilesStore, profileId: string, request: Backoffice.ClientProfiles.UpdateClientProfileRequest) {
    this.isLoadingCreateUpdate = true;
    try {
      yield this.profilesClient.updateClientProfileAsync(profileId, request);
      this.errorsCreateUpdate = undefined;
      yield this.fetchProfiles();
    } catch (error) {
      if (instanceOfIAjaxResponse(error)) {
        const errorData = JSON.parse(error.data);
        if (isBackofficeFrontendBadRequest(errorData)) {
          this.errorsCreateUpdate = errorData;
        }
      }
      throw error;
    } finally {
      this.isLoadingCreateUpdate = false;
    }
  });

  public deleteProfile: (profileId: string) => CancellablePromise<any> =
  flow(function* deleteProfile(this: ProfilesStore, profileId: string) {
    this.isDeleteLoading = true;
    try {
      yield this.profilesClient.deleteClientProfileAsync(profileId, { brokerId: this.brokerId });
      this.profiles = this.profiles.filter(profile => profile.id !== profileId);
      this.errorsDelete = undefined;
    } catch (error) {
      if (instanceOfIAjaxResponse(error)) {
        const errorData = JSON.parse(error.data);
        if (isBackofficeFrontendBadRequest(errorData)) {
          this.errorsDelete = errorData;
        }
      }
      throw error;
    } finally {
      this.isDeleteLoading = false;
    }
  });

  @action.bound
  public updateFilterAndSortState(changeContext: LykkeGridContext<Backoffice.ClientProfiles.ClientProfileContract>) {
    this.filters = changeContext.filters as any; // LykkeGridContext would proxy any extensions we pass to its filters
    this.sort = changeContext.sort;
  }

  public fetchRegulatoryProfiles: () => CancellablePromise<any> =
  flow(function* fetchRegulatoryProfiles(this: ProfilesStore) {
    this.isLoadingRegulatoryProfilesFetch = true;
    try {
      const brokerSettings: Backoffice.BrokerSettings.BrokerSettingsContract = yield this.brokerSettingsClient.getByIdAsync(this.brokerId);
      const regulatoryProfilesResponse: Backoffice.RegulatoryProfile.GetRegulatoryProfilesResponse = yield this.regulatoryProfilesClient
        .getRegulatoryProfilesAsync(brokerSettings.regulationId);
      this.regulatoryProfiles = regulatoryProfilesResponse.regulatoryProfiles;
      this.errorsRegulatoryProfilesFetch = undefined;
    } catch (error) {
      if (instanceOfIAjaxResponse(error)) {
        const errorData = JSON.parse(error.data);
        if (isBackofficeFrontendBadRequest(errorData)) {
          this.errorsRegulatoryProfilesFetch = errorData;
        }
      }
      throw error;
    } finally {
      this.isLoadingRegulatoryProfilesFetch = false;
    }
  });
}