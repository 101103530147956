import React from 'react';
import { Button, Intent, Classes } from '@blueprintjs/core';
import * as Backoffice from '@client/backoffice/Dto';
import { LabelWithTextInput, LykkeForm, LabelWithSelect, LabelWithDateInput, LabelWithNumberInput, LabelWithCheckbox } from '@elements/lykke-html/form/LykkeForm';
import { FrontendError } from '@client/backoffice/CustomErrors';
import { BackofficeErrorCodes } from '@client/backoffice/ErrorCodes';
import { omit, every } from 'lodash';
import { LocalizationService } from '@services/LocalizationService';
import { momentFromDto, formatAsISO8601Date, toMoment, getMinCalendarDate, getMaxCalendarDate } from '@scripts/utils/date-util';
import moment from 'moment';

interface Props {
  sb: jc.Sandbox;
  itemToEdit?: Backoffice.CorporateActions.CorporateActionContract;
  types: Backoffice.CorporateActionTypes.CorporateActionTypeContract[];
  onCancel: () => void;
  onSubmit: (caData: Backoffice.CorporateActions.CorporateActionContract) => void;
  loading: boolean;
  serverErrors: FrontendError[];
}

interface State {
  rFactorEnabled: boolean;
  formData: Backoffice.CorporateActions.CorporateActionContract;
  fieldErrors: {[P in keyof Backoffice.CorporateActions.CorporateActionContract]?: string};
}

const getEmptyFormData = (types: Backoffice.CorporateActionTypes.CorporateActionTypeContract[]) => ({
  id: '',
  messageId: '',
  isin: '',
  mdsCode: '',
  underlyingName: '',
  marketArea: '',
  typeId: types[0]?.id,
  typeName: '',
  effectiveDate: null,
  deadlineDate: null,
  rFactor: null,
  rFactorDate: null,
  source: '',
  importDate: '',
  status: '',
  validationDate: '',
  edited: null
} as Backoffice.CorporateActions.CorporateActionContract);

export class AddEditCorporateActionForm extends React.Component<Props, State> {
  private readonly minDate = moment(getMinCalendarDate());
  private readonly maxDate = moment(getMaxCalendarDate());

  private readonly loc: LocalizationService;

  constructor(props: Props) {
    super(props);
    this.loc = props.sb.getService('localization');
  }

  public state: State = {
    rFactorEnabled: !!this.props.itemToEdit ? !!this.props.itemToEdit.rFactorDate : true,
    formData: { ...(this.props.itemToEdit ?? getEmptyFormData(this.props.types)) },
    fieldErrors: {},
  };

  private get typeOptions() {
    return this.props.types.map(x => ({
      value: x.id,
      text: x.id
    }));
  }

  private handleMdsCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFormData = {
      ...this.state.formData,
      mdsCode: e.target.value
    };

    this.setState({ formData: newFormData });
  }

  private handleTypeIdChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newFormData = {
      ...this.state.formData,
      typeId: e.target.value
    };

    this.setState({ formData: newFormData });
  }

  private handleEffectiveDateChange = (selectedDate: moment.Moment) => {
    const newFormData = {
      ...this.state.formData,
      effectiveDate: selectedDate ? formatAsISO8601Date(selectedDate) : null
    };

    this.setState({ formData: newFormData }, () => this.validateDates());
  }

  private handleDeadlineDateChange = (selectedDate: moment.Moment) => {
    const newFormData = {
      ...this.state.formData,
      deadlineDate: selectedDate ? formatAsISO8601Date(selectedDate) : null,
      effectiveDate: this.state.formData.effectiveDate || formatAsISO8601Date(selectedDate),
    };

    this.setState({ formData: newFormData }, () => this.validateDates());
  }

  private handleRFactorEnabledChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const rFactorEnabled = e.target.checked;

    this.setState({ rFactorEnabled }, () => {
      this.validateRFactor();
      this.validateRFactorDate();
    });
    if (!rFactorEnabled) {
      const newFormData = {
        ...this.state.formData,
        rFactor: null,
        rFactorDate: null,
      };

      this.setState({ formData: newFormData });
    }
  }

  private handleRFactorChange = async (value: number) => {
    const newFormData = {
      ...this.state.formData,
      rFactor: value
    };

    this.setState({ formData: newFormData });
  }

  private handleRFactorDateChange = (selectedDate: moment.Moment) => {
    const newFormData = {
      ...this.state.formData,
      rFactorDate: selectedDate ? formatAsISO8601Date(selectedDate) : null,
    };

    this.setState({ formData: newFormData });
  }

  private handleSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    if (this.validate()) {
      this.props.onSubmit(this.state.formData);
    }
  }

  private validateDates = () => {
    const t = this.loc.i18n.CorporateActions.ClientErrors;
    const { deadlineDate, effectiveDate } = this.state.formData;

    if (!deadlineDate && !effectiveDate) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, effectiveDate: t.AtLeastOneDayShouldBeFilled, deadlineDate: t.AtLeastOneDayShouldBeFilled }
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'effectiveDate', 'deadlineDate'),
      }));
      return true;
    }
  }

  private validateMdsCode = () => {
    const t = this.loc.i18n.CorporateActions.ClientErrors;
    const value = this.state.formData.mdsCode;

    if (!value) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, mdsCode: t.MdsCodeFieldRequired}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'mdsCode')
      }));
      return true;
    }
  }

  private validateRFactor = () => {
    const t = this.loc.i18n.CorporateActions.ClientErrors;
    const value = this.state.formData.rFactor;

    if (this.state.rFactorEnabled && (value === null || value === undefined)) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, rFactor: t.RFactorFieldRequired}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'rFactor')
      }));
      return true;
    }
  }

  private validateRFactorDate = () => {
    const t = this.loc.i18n.CorporateActions.ClientErrors;
    const value = this.state.formData.rFactorDate;

    if (this.state.rFactorEnabled && (value === null || value === undefined)) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, rFactorDate: t.RFactorDateFieldRequired}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'rFactorDate')
      }));
      return true;
    }
  }

  private validate = () => {
    return every([
      this.validateMdsCode(),
      this.validateRFactor(),
      this.validateRFactorDate(),
      this.validateDates(),
    ]);
  }

  private getServerErrorMessages(field: keyof Backoffice.CorporateActions.CorporateActionContract) {
    return this.props.serverErrors
      ?.filter(e => e.errorCode as BackofficeErrorCodes === 'FieldValidationFailed' && e.fieldNames.includes(field))
      .map(e => e.errorMessage) ?? [];
  }

  private getClientErrorMessage(field: keyof Backoffice.CorporateActions.CorporateActionContract) {
    return this.state.fieldErrors[field];
  }

  private formatErrorMessages(messages: string[]) {
    return messages.filter(m => !!m).join('; ');
  }

  public render() {
    const { itemToEdit = null, loading } = this.props;
    const t = this.loc.i18n.CorporateActions;

    return (
      <LykkeForm>
        <div className={Classes.DIALOG_BODY}>
          {
            !!this.props.itemToEdit &&
            <>
              <LabelWithTextInput label={t.MessageIdLabel} value={this.state.formData.messageId} disabled={true} />
              <LabelWithTextInput label={t.IdLabel} value={this.state.formData.id} disabled={true} />
              <LabelWithTextInput label={t.IsinLabel} value={this.state.formData.isin} disabled={true} />
            </>
          }
          <LabelWithTextInput
            autoFocus={true}
            label={t.MdsCodeLabel}
            value={this.state.formData.mdsCode}
            disabled={!!this.props.itemToEdit}
            onChange={this.handleMdsCodeChange}
            onBlur={this.validateMdsCode}
            error={this.formatErrorMessages([this.getClientErrorMessage('mdsCode'), ...this.getServerErrorMessages('mdsCode')])}
          />
          {
            !!this.props.itemToEdit &&
            <>
              <LabelWithTextInput label={t.UnderlyingNameLabel} value={this.state.formData.underlyingName} disabled={true} />
              <LabelWithTextInput label={t.MarketAreaLabel} value={this.state.formData.marketArea} disabled={true} />
            </>
          }
          <LabelWithSelect
            label={t.TypeIdLabel}
            value={this.state.formData.typeId}
            options={this.typeOptions}
            onChange={this.handleTypeIdChange}
            error={this.formatErrorMessages([this.getClientErrorMessage('typeId'), ...this.getServerErrorMessages('typeId')])}
          />
          <LabelWithDateInput
            label={t.EffectiveDateLabel}
            value={toMoment(this.state.formData.effectiveDate)}
            minDate={this.minDate}
            maxDate={this.maxDate}
            onChange={this.handleEffectiveDateChange}
            error={this.formatErrorMessages([this.getClientErrorMessage('effectiveDate'), ...this.getServerErrorMessages('effectiveDate')])}
          />
          <LabelWithDateInput
            label={t.DeadlineDateLabel}
            value={toMoment(this.state.formData.deadlineDate)}
            minDate={this.minDate}
            maxDate={this.maxDate}
            disabled={!!this.props.itemToEdit}
            onChange={this.handleDeadlineDateChange}
            error={this.formatErrorMessages([this.getClientErrorMessage('deadlineDate'), ...this.getServerErrorMessages('deadlineDate')])}
          />
          <LabelWithCheckbox
            label={t.RFactorEnabledLabel}
            value={this.state.rFactorEnabled}
            onChange={this.handleRFactorEnabledChange}
          />
          <LabelWithNumberInput
            disabled={!this.state.rFactorEnabled}
            label={t.RFactorLabel}
            value={this.state.formData.rFactor}
            precision={4}
            minimumValue={0.0001}
            onChange={this.handleRFactorChange}
            onBlur={this.validateRFactor}
            error={this.formatErrorMessages([this.getClientErrorMessage('rFactor'), ...this.getServerErrorMessages('rFactor')])}
          />
          <LabelWithDateInput
            disabled={!this.state.rFactorEnabled}
            label={t.RFactorDateLabel}
            value={toMoment(this.state.formData.rFactorDate)}
            minDate={this.minDate}
            maxDate={this.maxDate}
            onChange={this.handleRFactorDateChange}
            onBlur={this.validateRFactorDate}
            error={this.formatErrorMessages([this.getClientErrorMessage('rFactorDate'), ...this.getServerErrorMessages('rFactorDate')])}
          />
          {
            !!this.props.itemToEdit &&
            <>
              <LabelWithTextInput label={t.SourceLabel} value={this.state.formData.source} disabled={true} />
              <LabelWithDateInput label={t.ImportDateLabel} value={momentFromDto(this.state.formData.importDate)} disabled={true} />
              <LabelWithTextInput label={t.StatusLabel} value={this.state.formData.status} disabled={true} />
            </>
          }
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={this.props.onCancel}
              disabled={loading}
              intent={Intent.NONE}
            >
              {t.CancelButtonLabel}
            </Button>
            <Button
              type='submit'
              onClick={this.handleSubmit}
              disabled={loading}
              loading={loading}
              intent={Intent.SUCCESS}
            >
              {itemToEdit ? t.UpdateButtonLabel : t.CreateButtonLabel}
            </Button>
          </div>
        </div>
      </LykkeForm>
    );
  }
}