import React from 'react';
import { Button, Intent, Classes } from '@blueprintjs/core';
import * as Backoffice from '@client/backoffice/Dto';
import { LabelWithTextInput, LykkeForm, LabelWithNumberInput, LabelWithCheckbox } from '@elements/lykke-html/form/LykkeForm';
import { ILocalizedProps } from '@elements/localization';
import { inject } from 'mobx-react';
import { FrontendError } from '@client/backoffice/CustomErrors';
import { BackofficeErrorCodes } from '@client/backoffice/ErrorCodes';
import { omit, every } from 'lodash';
import styled from 'styled-components';

const FormInputWithNote = styled.div`
  display: flex;

  .with-padding {
    padding-top: 30px;
  }

  > *:first-child {
    width: 170px;
  }

  > *:last-child {
    margin-left: 10px;
  }
`;

interface Props extends ILocalizedProps {
  itemToEdit: Backoffice.ClientProfileSettings.ClientProfileSettingsContract;
  regulatorySetting: Backoffice.RegulatorySettings.RegulatorySettingsContract;
  onCancel: () => void;
  onSubmit: (profileSettingData: Backoffice.ClientProfileSettings.ClientProfileSettingsContract) => void;
  loading: boolean;
  serverErrors: FrontendError[];
}

interface State {
  formData: Backoffice.ClientProfileSettings.ClientProfileSettingsContract;
  fieldErrors: {[P in keyof Backoffice.ClientProfileSettings.ClientProfileSettingsContract]?: string};
}

@inject('i18n', 'loc')
export class EditProfileSettingForm extends React.Component<Props, State> {
  private readonly precision: number = 2;

  public state: State = {
    formData: this.props.itemToEdit,
    fieldErrors: {},
  };

  private handleMarginPercentChange = (value: number) => {
    const newFormData = {
      ...this.state.formData,
      marginPercent: value
    };

    this.setState({ formData: newFormData });
  }

  private handleExecutionFeesFloorChange = (value: number) => {
    const newFormData = {
      ...this.state.formData,
      executionFeesFloor: value
    };

    this.setState({ formData: newFormData });
  }

  private handleExecutionFeesCapChange = (value: number) => {
    const newFormData = {
      ...this.state.formData,
      executionFeesCap: value
    };

    this.setState({ formData: newFormData });
  }

  private handleExecutionFeesRateChange = (value: number) => {
    const newFormData = {
      ...this.state.formData,
      executionFeesRate: value
    };

    this.setState({ formData: newFormData });
  }

  private handleFinancingFeesRateChange = (value: number) => {
    const newFormData = {
      ...this.state.formData,
      financingFeesRate: value
    };

    this.setState({ formData: newFormData });
  }

  private handleOnBehalfFeeChange = (value: number) => {
    const newFormData = {
      ...this.state.formData,
      onBehalfFee: value
    };

    this.setState({ formData: newFormData });
  }

  private handleIsAvailableChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFormData = {
      ...this.state.formData,
      isAvailable: e.target.checked
    };

    this.setState({ formData: newFormData });
  }

  private handleSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    if (this.validate()) {
      this.props.onSubmit(this.state.formData);
    }
  }

  private validateMarginPercent = () => {
    const t = this.props.i18n.BrokerSettingsView.ProfilesParametersTab.ProfileSettings.ClientErrors;
    const value = this.state.formData.marginPercent;

    if (value === null) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, marginPercent: t.MarginPercentFieldRequired}
      }));
      return false;
    } else if (value < this.props.regulatorySetting.marginMinPercent) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, marginPercent: this.props.loc.t(t.MarginPercentFieldIsLessThanMin, [this.props.regulatorySetting.marginMinPercent.toString()])}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'marginPercent')
      }));
      return true;
    }
  }

  private validateExecutionFeesFloor = () => {
    const t = this.props.i18n.BrokerSettingsView.ProfilesParametersTab.ProfileSettings.ClientErrors;
    const value = this.state.formData.executionFeesFloor;

    if (value === null) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, executionFeesFloor: t.ExecutionFeesFloorFieldRequired}
      }));
      return false;
    } else if (value > this.state.formData.executionFeesCap) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, executionFeesFloor: t.ExecutionFeesFloorGreaterThanCap}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'executionFeesFloor')
      }));
      return true;
    }
  }

  private validateExecutionFeesCap = () => {
    const t = this.props.i18n.BrokerSettingsView.ProfilesParametersTab.ProfileSettings.ClientErrors;
    const value = this.state.formData.executionFeesCap;

    if (value === null) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, executionFeesCap: t.ExecutionFeesCapFieldRequired}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'executionFeesCap')
      }));
      return true;
    }
  }

  private validateExecutionFeesRate = () => {
    const t = this.props.i18n.BrokerSettingsView.ProfilesParametersTab.ProfileSettings.ClientErrors;
    const value = this.state.formData.executionFeesRate;

    if (value === null) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, executionFeesRate: t.ExecutionFeesRateFieldRequired}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'executionFeesRate')
      }));
      return true;
    }
  }

  private validateFinancingFeesRate = () => {
    const t = this.props.i18n.BrokerSettingsView.ProfilesParametersTab.ProfileSettings.ClientErrors;
    const value = this.state.formData.financingFeesRate;

    if (value === null) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, financingFeesRate: t.FinancingFeesRateFieldRequired}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'financingFeesRate')
      }));
      return true;
    }
  }

  private validateOnBehalfFee = () => {
    const t = this.props.i18n.BrokerSettingsView.ProfilesParametersTab.ProfileSettings.ClientErrors;
    const value = this.state.formData.onBehalfFee;

    if (value === null) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, onBehalfFee: t.OnBehalfFeeFieldRequired}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'onBehalfFee')
      }));
      return true;
    }
  }

  private validate = () => {
    return every([
      this.validateMarginPercent(),
      this.validateExecutionFeesFloor(),
      this.validateExecutionFeesCap(),
      this.validateExecutionFeesRate(),
      this.validateFinancingFeesRate(),
      this.validateOnBehalfFee()
    ]);
  }

  private getServerErrorMessages(field: keyof Backoffice.ClientProfileSettings.ClientProfileSettingsContract) {
    return this.props.serverErrors
      ?.filter(e => e.errorCode as BackofficeErrorCodes === 'FieldValidationFailed' && e.fieldNames.includes(field))
      .map(e => e.errorMessage) ?? [];
  }

  private getClientErrorMessage(field: keyof Backoffice.ClientProfileSettings.ClientProfileSettingsContract) {
    return this.state.fieldErrors[field];
  }

  private formatErrorMessages(messages: string[]) {
    return messages.filter(m => !!m).join('; ');
  }

  public render() {
    const { loading } = this.props;
    const t = this.props.i18n.BrokerSettingsView.ProfilesParametersTab.ProfileSettings;

    return (
      <LykkeForm>
        <div className={Classes.DIALOG_BODY}>
          <LabelWithTextInput
            label={t.ClientProfileLabel}
            value={this.state.formData.clientProfileId}
            disabled={true} />
          <LabelWithTextInput
            label={t.AssetTypeLabel}
            value={this.state.formData.assetTypeId}
            disabled={true} />
          <FormInputWithNote>
            <LabelWithNumberInput
              autoFocus={true}
              label={t.MarginRateLabel}
              value={this.state.formData.marginPercent}
              minimumValue={this.props.regulatorySetting.marginMinPercent}
              maximumValue={100}
              precision={this.precision}
              onChange={this.handleMarginPercentChange}
              onBlur={this.validateMarginPercent}
              error={this.formatErrorMessages([this.getClientErrorMessage('marginPercent'), ...this.getServerErrorMessages('marginPercent')])} />
            <div className='with-padding'>{this.props.loc.t(t.RegulatoryMinimumLabel, [this.props.regulatorySetting.marginMinPercent.toString()])}</div>
          </FormInputWithNote>
          <LabelWithNumberInput
            label={t.ExecutionFeesFloorLabel}
            value={this.state.formData.executionFeesFloor}
            minimumValue={0}
            precision={this.precision}
            onChange={this.handleExecutionFeesFloorChange}
            onBlur={this.validateExecutionFeesFloor}
            error={this.formatErrorMessages([this.getClientErrorMessage('executionFeesFloor'), ...this.getServerErrorMessages('executionFeesFloor')])} />
          <LabelWithNumberInput
            label={t.ExecutionFeesCapLabel}
            value={this.state.formData.executionFeesCap}
            minimumValue={0}
            precision={this.precision}
            onChange={this.handleExecutionFeesCapChange}
            onBlur={this.validateExecutionFeesCap}
            error={this.formatErrorMessages([this.getClientErrorMessage('executionFeesCap'), ...this.getServerErrorMessages('executionFeesCap')])} />
          <LabelWithNumberInput
            label={t.ExecutionFeesRateLabel}
            value={this.state.formData.executionFeesRate}
            minimumValue={0}
            maximumValue={100}
            precision={this.precision}
            onChange={this.handleExecutionFeesRateChange}
            onBlur={this.validateExecutionFeesRate}
            error={this.formatErrorMessages([this.getClientErrorMessage('executionFeesRate'), ...this.getServerErrorMessages('executionFeesRate')])} />
          <LabelWithNumberInput
            label={t.FinancingFeesRateLabel}
            value={this.state.formData.financingFeesRate}
            minimumValue={0}
            maximumValue={100}
            precision={this.precision}
            onBlur={this.validateFinancingFeesRate}
            onChange={this.handleFinancingFeesRateChange}
            error={this.formatErrorMessages([this.getClientErrorMessage('financingFeesRate'), ...this.getServerErrorMessages('financingFeesRate')])} />
          <LabelWithNumberInput
            label={t.PhoneFeesLabel}
            value={this.state.formData.onBehalfFee}
            minimumValue={0}
            precision={this.precision}
            onBlur={this.validateOnBehalfFee}
            onChange={this.handleOnBehalfFeeChange}
            error={this.formatErrorMessages([this.getClientErrorMessage('onBehalfFee'), ...this.getServerErrorMessages('onBehalfFee')])} />
          <FormInputWithNote>
            <LabelWithCheckbox
              label={t.AvailableLabel}
              disabled={!this.props.regulatorySetting.isAvailable}
              value={this.state.formData.isAvailable}
              onChange={this.handleIsAvailableChange} />
            {this.props.regulatorySetting.isAvailable && <div>{t.RegulatoryRestrictionNone}</div>}
          </FormInputWithNote>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={this.props.onCancel}
              disabled={loading}
              intent={Intent.NONE}
            >
              {t.CancelButtonLabel}
            </Button>
            <Button
              type='submit'
              onClick={this.handleSubmit}
              disabled={loading}
              loading={loading}
              intent={Intent.SUCCESS}
            >
              {t.UpdateButtonLabel}
            </Button>
          </div>
        </div>
      </LykkeForm>
    );
  }
}