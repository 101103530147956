import React from 'react';
import { Button, Intent, Classes } from '@blueprintjs/core';
import * as Backoffice from '@client/backoffice/Dto';
import { LabelWithTextInput, LykkeForm, LabelWithSelect, LabelWithCheckbox } from '@elements/lykke-html/form/LykkeForm';
import { ILocalizedProps } from '@elements/localization';
import { inject } from 'mobx-react';
import { FrontendError } from '@client/backoffice/CustomErrors';
import { BackofficeErrorCodes } from '@client/backoffice/ErrorCodes';
import { omit, every } from 'lodash';

interface Props extends ILocalizedProps {
  itemToEdit?: Backoffice.AssetTypes.AssetTypeContract;
  sourceAssetTypeId?: string;
  regulatoryTypes: Backoffice.RegulatoryType.RegulatoryTypeContract[];
  underlyingCategoryIds: string[];
  onCancel: () => void;
  onSubmit: (assetTypeData: Backoffice.AssetTypes.AssetTypeContract, sourceAssetTypeId?: string) => void;
  loading: boolean;
  serverErrors: FrontendError[];
}

interface State {
  formData: Backoffice.AssetTypes.AssetTypeContract;
  fieldErrors: {[P in keyof Backoffice.AssetTypes.AssetTypeContract]?: string};
}

const getEmptyFormData = (regulatoryTypes: Backoffice.RegulatoryType.RegulatoryTypeContract[], underlyingCategoryIds: string[]) => ({
  id: '',
  regulatoryTypeId: regulatoryTypes[0].id,
  underlyingCategoryId: underlyingCategoryIds[0],
  excludeSpreadFromProductCosts: false,
} as Backoffice.AssetTypes.AssetTypeContract);

@inject('i18n')
export class AddEditAssetTypeForm extends React.Component<Props, State> {
  public state: State = {
    formData: { ...(this.props.itemToEdit ?? getEmptyFormData(this.props.regulatoryTypes, this.props.underlyingCategoryIds)) },
    fieldErrors: {},
  };

  private get regulatoryTypeOptions() {
    return this.props.regulatoryTypes.map(x => ({
      value: x.id,
      text: x.id
    }));
  }

  private get underlyingCategoryIdOptions() {
    return this.props.underlyingCategoryIds.map(x => ({ value: x, text: x }));
  }

  private handleAssetTypeIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFormData = {
      ...this.state.formData,
      id: e.target.value
    };

    this.setState({ formData: newFormData });
  }

  private handleRegulatoryTypeIdChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newFormData = {
      ...this.state.formData,
      regulatoryTypeId: e.target.value
    };

    this.setState({ formData: newFormData });
  }

  private handleUnderlyingCategoryIddChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newFormData = {
      ...this.state.formData,
      underlyingCategoryId: e.target.value
    };

    this.setState({ formData: newFormData });
  }

  private handleExcludeSpreadFromProductCostsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFormData = {
      ...this.state.formData,
      excludeSpreadFromProductCosts: e.target.checked
    };

    this.setState({ formData: newFormData });
  }

  private handleSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    if (this.validate()) {
      this.props.onSubmit(this.state.formData, this.props.sourceAssetTypeId);
    }
  }

  private validateId = () => {
    const t = this.props.i18n.BrokerSettingsView.ProfilesParametersTab.AssetTypes.ClientErrors;
    const value = this.state.formData.id;

    if (!value) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, id: t.AssetTypeIdFieldRequired}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'id')
      }));
      return true;
    }
  }

  private validate = () => {
    return every([
      this.validateId(),
    ]);
  }

  private getServerErrorMessages(field: keyof Backoffice.AssetTypes.AssetTypeContract) {
    return this.props.serverErrors
      ?.filter(e => e.errorCode as BackofficeErrorCodes === 'FieldValidationFailed' && e.fieldNames.includes(field))
      .map(e => e.errorMessage) ?? [];
  }

  private getClientErrorMessage(field: keyof Backoffice.AssetTypes.AssetTypeContract) {
    return this.state.fieldErrors[field];
  }

  private formatErrorMessages(messages: string[]) {
    return messages.filter(m => !!m).join('; ');
  }

  public render() {
    const { itemToEdit = null, loading } = this.props;
    const t = this.props.i18n.BrokerSettingsView.ProfilesParametersTab.AssetTypes;

    return (
      <LykkeForm>
        <div className={Classes.DIALOG_BODY}>
          <LabelWithTextInput
            autoFocus={true}
            label={t.AssetTypeIdLabel}
            value={this.state.formData.id}
            onChange={this.handleAssetTypeIdChange}
            onBlur={this.validateId}
            disabled={!!itemToEdit}
            error={this.formatErrorMessages([this.getClientErrorMessage('id'), ...this.getServerErrorMessages('id')])}
          />
          {this.props.sourceAssetTypeId && <LabelWithTextInput
            label={t.SourceAssetTypeIdLabel}
            disabled={true}
            value={this.props.sourceAssetTypeId} />}
          <LabelWithSelect
            label={t.RegulatoryTypeLabel}
            value={this.state.formData.regulatoryTypeId}
            options={this.regulatoryTypeOptions}
            onChange={this.handleRegulatoryTypeIdChange} />
          <LabelWithSelect
            label={t.UnderlyingCategoryLabel}
            value={this.state.formData.underlyingCategoryId}
            options={this.underlyingCategoryIdOptions}
            onChange={this.handleUnderlyingCategoryIddChange} />
          <LabelWithCheckbox
            label={t.ExcludeSpreadFromProductCostsLabel}
            value={this.state.formData.excludeSpreadFromProductCosts}
            onChange={this.handleExcludeSpreadFromProductCostsChange} />
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={this.props.onCancel}
              disabled={loading}
              intent={Intent.NONE}
            >
              {t.CancelButtonLabel}
            </Button>
            <Button
              type='submit'
              onClick={this.handleSubmit}
              disabled={loading}
              loading={loading}
              intent={Intent.SUCCESS}
            >
              {itemToEdit
                ? t.UpdateButtonLabel
                : this.props.sourceAssetTypeId
                  ? t.DuplicateOkButtonLabel
                  : t.CreateButtonLabel}
            </Button>
          </div>
        </div>
      </LykkeForm>
    );
  }
}