import { LocalizedStore } from '@elements/localization';
import { action, observable, flow, computed } from 'mobx';
import * as Backoffice from '@client/backoffice/Dto';
import type { CancellablePromise } from 'mobx/lib/api/flow';
import { SortDescriptor, LykkeGridContext } from '@elements/lykke-html/LykkeGrid';
import { sortGridData, filterGridData, ExtendedFilterDescriptor } from '@elements/lykke-html/LykkeGridData';
import { instanceOfIAjaxResponse } from '@services/network/AjaxService';
import { FrontendBadRequest, isBackofficeFrontendBadRequest } from '@client/backoffice/CustomErrors';
import { RegulatoryProfilesClient } from '@client/backoffice/RegulatoryProfilesClient';

export class RegulatoryProfilesStore extends LocalizedStore {
  private readonly regulatoryProfilesClient: RegulatoryProfilesClient;

  @observable public regulatoryProfiles: Backoffice.RegulatoryProfile.RegulatoryProfileContract[] = [];
  @observable public isLoadingFetch: boolean = false;
  @observable public errorsFetch: FrontendBadRequest;

  @observable public isLoadingCreate: boolean = false;
  @observable public errorsCreate: FrontendBadRequest;

  @observable public isDeleteLoading: boolean = false;
  @observable public errorsDelete: FrontendBadRequest;

  @observable public filters: Array<ExtendedFilterDescriptor<Backoffice.RegulatoryProfile.RegulatoryProfileContract>> = [
    { type: 'text', field: 'id', operator: 'contains', value: null, ignoreCase: true },
  ];
  @observable public sort: Array<SortDescriptor<Backoffice.RegulatoryProfile.RegulatoryProfileContract>> = [];

  @computed
  public get filteredAndSortedRegulatoryProfiles() {
    return sortGridData(filterGridData(this.regulatoryProfiles, this.filters), this.sort);
  }

  constructor(sandbox: jc.Sandbox) {
    super(sandbox);
    this.regulatoryProfilesClient = sandbox.getService('client/backoffice/regulatory-profiles');
  }

  public fetchRegulatoryProfiles: (regulationId: string) => CancellablePromise<any> =
  flow(function* fetchRegulatoryProfiles(this: RegulatoryProfilesStore, regulationId: string) {
    this.isLoadingFetch = true;
    try {
      const data: Backoffice.RegulatoryProfile.GetRegulatoryProfilesResponse = yield this.regulatoryProfilesClient.getRegulatoryProfilesAsync(regulationId);
      this.regulatoryProfiles = data.regulatoryProfiles;
      this.errorsFetch = undefined;
    } catch (error) {
      if (instanceOfIAjaxResponse(error)) {
        const errorData = JSON.parse(error.data);
        if (isBackofficeFrontendBadRequest(errorData)) {
          this.errorsFetch = errorData;
        }
      }
      throw error;
    } finally {
      this.isLoadingFetch = false;
    }
  });

  public createRegulatoryProfile: (request: Backoffice.RegulatoryProfile.AddRegulatoryProfileRequest) => CancellablePromise<any> =
  flow(function* createRegulatoryProfile(this: RegulatoryProfilesStore, request: Backoffice.RegulatoryProfile.AddRegulatoryProfileRequest) {
    this.isLoadingCreate = true;
    try {
      yield this.regulatoryProfilesClient.insertRegulatoryProfileAsync(request);
      this.regulatoryProfiles = [{id: request.id, isDefault: request.isDefault}, ...this.regulatoryProfiles];
      this.errorsCreate = undefined;
    } catch (error) {
      if (instanceOfIAjaxResponse(error)) {
        const errorData = JSON.parse(error.data);
        if (isBackofficeFrontendBadRequest(errorData)) {
          this.errorsCreate = errorData;
        }
      }
      throw error;
    } finally {
      this.isLoadingCreate = false;
    }
  });

  public deleteRegulatoryProfile: (regulatoryProfileId: string) => CancellablePromise<any> =
  flow(function* deleteRegulatoryProfile(this: RegulatoryProfilesStore, regulatoryProfileId: string) {
    this.isDeleteLoading = true;
    try {
      yield this.regulatoryProfilesClient.deleteRegulatoryProfileAsync(regulatoryProfileId);
      this.regulatoryProfiles = this.regulatoryProfiles.filter(regulatoryProfile => regulatoryProfile.id !== regulatoryProfileId);
      this.errorsDelete = undefined;
    } catch (error) {
      if (instanceOfIAjaxResponse(error)) {
        const errorData = JSON.parse(error.data);
        if (isBackofficeFrontendBadRequest(errorData)) {
          this.errorsDelete = errorData;
        }
      }
      throw error;
    } finally {
      this.isDeleteLoading = false;
    }
  });

  @action.bound
  public updateFilterAndSortState(changeContext: LykkeGridContext<Backoffice.RegulatoryProfile.RegulatoryProfileContract>) {
    this.filters = changeContext.filters as any; // LykkeGridContext would proxy any extensions we pass to its filters
    this.sort = changeContext.sort;
  }
}