import React, { Component } from 'react';
import moment from 'moment';
import { AnchorButton, Intent, RadioGroup, Radio, Icon } from '@blueprintjs/core';
import * as Backoffice from '@client/backoffice/Dto';
import { LykkeGrid, LykkeGridColumn, FilterDescriptor, SortDescriptor, LykkeGridContext, VirtualField, LykkeGridActionsCell, PaginationDescriptor, StyledLykkeGridFilterWrapper } from '@elements/lykke-html/LykkeGrid';
import { LocalizationService } from '@services/LocalizationService';
import { DateTimeFormatter } from '@elements/date-formatter/DateTimeFormatter';
import { getMaxCalendarDate, momentFromDto, toMoment } from '@scripts/utils/date-util';
import { LykkeDateInput } from '@elements/lykke-html/LykkeDateInput';
import { makeDateRangeFilterValue } from '@elements/lykke-html/LykkeGridData';
import styled from 'styled-components';
import { DateFormatter } from '@services/ui-formatters/DateFormatter';
import { Tooltip2 } from '@blueprintjs/popover2';
import { NumericFormatter } from '@elements/number-formatter/NumericFormatter';

const StyledDiv = styled.div`
  height: 100%;
  width: 100%;

  .ca-event-manual:not(.ca-event-source-cell) {
    color: ${props => props.theme.corporateActionTaskManualColor};
  }

  .ca-event-manual.ca-event-source-cell {
    background-color: ${props => props.theme.corporateActionTaskSourceBackgroundColor} !important;
    color: white;
  }

  .ca-event-edited {
    color: ${props => props.theme.corporateActionTaskImportedAndEditedColor};
  }
`;

interface Props {
  sb: jc.Sandbox;
  data: Backoffice.CorporateActions.CorporateActionContract[];
  loading: boolean;
  filters: Array<FilterDescriptor<Backoffice.CorporateActions.CorporateActionContract>>;
  sort: Array<SortDescriptor<Backoffice.CorporateActions.CorporateActionContract>>;
  onStateChange?(changeContext: LykkeGridContext<Backoffice.CorporateActions.CorporateActionContract>): void;
  pagination: PaginationDescriptor;
  editClick?: (caId: string) => void;
  selectedRowKeys: string[];
  onSelectedRowKeysChange: (selectedRowKeys: string[]) => void;
  onExpand: (expanded: boolean, record: Backoffice.CorporateActions.CorporateActionContract) => void;
  expandedRowRenderer: (record: Backoffice.CorporateActions.CorporateActionContract, index: number, indent: number, expanded: boolean) => string | React.ReactNode;
  availableStatuses: Backoffice.CorporateActions.CorporateActionStatusContract[];
}

export class CorporateActionsTable extends Component<Props, {}> {
  private readonly loc: LocalizationService;
  private readonly dateFormatter: DateFormatter;

  private get dateFormat() {
    return this.dateFormatter.getMomentFormat('Date');
  }

  constructor(props: Props) {
    super(props);
    this.loc = props.sb.getService('localization');
    this.dateFormatter = props.sb.getService('date-formatter');
  }

  private get messageIdColumn(): LykkeGridColumn<Backoffice.CorporateActions.CorporateActionContract> {
    const t = this.loc.i18n.CorporateActions;
    return {
      field: 'messageId',
      name: t.MessageIdColumn,
      getCellClassName: item => this.getCellClassName(item, 'messageId'),
    };
  }

  private get idColumn(): LykkeGridColumn<Backoffice.CorporateActions.CorporateActionContract> {
    const t = this.loc.i18n.CorporateActions;
    return {
      field: 'id',
      name: t.IdColumn,
      getCellClassName: item => this.getCellClassName(item, 'id'),
    };
  }

  private get isinColumn(): LykkeGridColumn<Backoffice.CorporateActions.CorporateActionContract> {
    const t = this.loc.i18n.CorporateActions;
    return {
      field: 'isin',
      name: t.IsinColumn,
      getCellClassName: item => this.getCellClassName(item, 'isin'),
    };
  }

  private get mdsCodeColumn(): LykkeGridColumn<Backoffice.CorporateActions.CorporateActionContract> {
    const t = this.loc.i18n.CorporateActions;
    return {
      field: 'mdsCode',
      name: t.MdsCodeColumn,
      getCellClassName: item => this.getCellClassName(item, 'mdsCode'),
      sortable: true,
      filterable: true
    };
  }

  private get underlyingNameColumn(): LykkeGridColumn<Backoffice.CorporateActions.CorporateActionContract> {
    const t = this.loc.i18n.CorporateActions;
    return {
      field: 'underlyingName',
      name: t.UnderlyingNameColumn,
      getCellClassName: item => this.getCellClassName(item, 'underlyingName'),
    };
  }

  private get marketAreaColumn(): LykkeGridColumn<Backoffice.CorporateActions.CorporateActionContract> {
    const t = this.loc.i18n.CorporateActions;
    return {
      field: 'marketArea',
      name: t.MarketAreaColumn,
      getCellClassName: item => this.getCellClassName(item, 'marketArea'),
      sortable: true,
      filterable: true,
      getFilterContent: this.renderMarketAreaFilter
    };
  }

  private renderMarketAreaFilter = (filter: FilterDescriptor<Backoffice.CorporateActions.CorporateActionContract>, updateFilter: (updatedFilter: FilterDescriptor<Backoffice.CorporateActions.CorporateActionContract>) => void) => {
    const t = this.loc.i18n.CorporateActions;
    return <StyledLykkeGridFilterWrapper>
      <RadioGroup
        onChange={e => updateFilter({...filter, value: e.currentTarget.value})}
        selectedValue={filter.value || ''} // Blueprint doesn't recognize null as a valid value
      >
        <Radio label={t.AllMarketsLabel} value={''} />
        {this.props.sb.constants.appSettings.market_areas.map(x => <Radio label={x} value={x} key={x} />)}
      </RadioGroup>
    </StyledLykkeGridFilterWrapper>;
  }

  private get typeIdColumn(): LykkeGridColumn<Backoffice.CorporateActions.CorporateActionContract> {
    const t = this.loc.i18n.CorporateActions;
    return {
      field: 'typeId',
      name: t.TypeIdColumn,
      getCellClassName: item => this.getCellClassName(item, 'typeId'),
    };
  }

  private get effectiveDateColumn(): LykkeGridColumn<Backoffice.CorporateActions.CorporateActionContract> {
    const t = this.loc.i18n.CorporateActions;
    return {
      field: 'effectiveDate',
      name: t.EffectiveDateColumn,
      getCellContent: item => <DateTimeFormatter dateTime={toMoment(item.effectiveDate)} format='Date' />,
      getCellClassName: item => this.getCellClassName(item, 'effectiveDate'),
      sortable: true,
      filterable: true,
      getFilterContent: this.renderEffectiveDateFilter
    };
  }

  private renderEffectiveDateFilter = (filter: FilterDescriptor<Backoffice.CorporateActions.CorporateActionContract>, updateFilter: (updatedFilter: FilterDescriptor<Backoffice.CorporateActions.CorporateActionContract>) => void) => {
    const t = this.loc.i18n.CorporateActions;
    const filterArray = filter.value as moment.Moment[];
    return <StyledLykkeGridFilterWrapper>
      <LykkeDateInput
        inputProps={{ leftIcon: 'calendar', placeholder: t.FilterDateFromPlaceholder }}
        formatDate={(date, locale) => moment(date).format(this.dateFormat)}
        parseDate={(str, locale) => moment(str, this.dateFormat).toDate()}
        value={filterArray.length ? filterArray[0]?.toDate() : undefined}
        onChange={selectedDate => {
          updateFilter({
            ...filter,
            value: makeDateRangeFilterValue(selectedDate ? toMoment(selectedDate) : null, filterArray[1])
          });
        }}
        maxDate={getMaxCalendarDate()}
      />
      <br/>
      <LykkeDateInput
        inputProps={{ leftIcon: 'calendar', placeholder: t.FilterDateToPlaceholder }}
        formatDate={(date, locale) => moment(date).format(this.dateFormat)}
        parseDate={(str, locale) => moment(str, this.dateFormat).toDate()}
        value={filterArray.length ? filterArray[1]?.toDate() : undefined}
        onChange={selectedDate => {
          updateFilter({
            ...filter,
            value: makeDateRangeFilterValue(filterArray[0], selectedDate ? toMoment(selectedDate) : null)
          });
        }}
        maxDate={getMaxCalendarDate()}
      />
    </StyledLykkeGridFilterWrapper>;
  }

  private get deadlineDateColumn(): LykkeGridColumn<Backoffice.CorporateActions.CorporateActionContract> {
    const t = this.loc.i18n.CorporateActions;
    return {
      field: 'deadlineDate',
      name: t.DeadlineDateColumn,
      getCellClassName: item => this.getCellClassName(item, 'deadlineDate'),
      getCellContent: item => <DateTimeFormatter dateTime={toMoment(item.deadlineDate)} format='Date' />,
    };
  }

  private get rFactorColumn(): LykkeGridColumn<Backoffice.CorporateActions.CorporateActionContract> {
    const t = this.loc.i18n.CorporateActions;
    return {
      field: 'rFactor',
      name: t.RFactorColumn,
      getCellClassName: item => this.getCellClassName(item, 'rFactor'),
      getCellContent: item => <NumericFormatter value={item.rFactor} precision={4} />
    };
  }

  private get rFactorDateColumn(): LykkeGridColumn<Backoffice.CorporateActions.CorporateActionContract> {
    const t = this.loc.i18n.CorporateActions;
    return {
      field: 'rFactorDate',
      name: t.RFactorDateColumn,
      getCellClassName: item => this.getCellClassName(item, 'rFactorDate'),
      getCellContent: item => <DateTimeFormatter dateTime={toMoment(item.rFactorDate)} format='Date' />,
      sortable: true,
      filterable: true,
      getFilterContent: this.renderRFactorDateFilter
    };
  }

  private renderRFactorDateFilter = (filter: FilterDescriptor<Backoffice.CorporateActions.CorporateActionContract>, updateFilter: (updatedFilter: FilterDescriptor<Backoffice.CorporateActions.CorporateActionContract>) => void) => {
    const t = this.loc.i18n.CorporateActions;
    const filterArray = filter.value as moment.Moment[];
    return <StyledLykkeGridFilterWrapper>
      <LykkeDateInput
        inputProps={{ leftIcon: 'calendar', placeholder: t.FilterDateFromPlaceholder }}
        formatDate={(date, locale) => moment(date).format(this.dateFormat)}
        parseDate={(str, locale) => moment(str, this.dateFormat).toDate()}
        value={filterArray.length ? filterArray[0]?.toDate() : undefined}
        onChange={selectedDate => {
          updateFilter({
            ...filter,
            value: makeDateRangeFilterValue(selectedDate ? toMoment(selectedDate) : null, filterArray[1])
          });
        }}
        maxDate={getMaxCalendarDate()}
      />
      <br/>
      <LykkeDateInput
        inputProps={{ leftIcon: 'calendar', placeholder: t.FilterDateToPlaceholder }}
        formatDate={(date, locale) => moment(date).format(this.dateFormat)}
        parseDate={(str, locale) => moment(str, this.dateFormat).toDate()}
        value={filterArray.length ? filterArray[1]?.toDate() : undefined}
        onChange={selectedDate => {
          updateFilter({
            ...filter,
            value: makeDateRangeFilterValue(filterArray[0], selectedDate ? toMoment(selectedDate) : null)
          });
        }}
        maxDate={getMaxCalendarDate()}
      />
    </StyledLykkeGridFilterWrapper>;
  }

  private get sourceColumn(): LykkeGridColumn<Backoffice.CorporateActions.CorporateActionContract> {
    const t = this.loc.i18n.CorporateActions;
    return {
      field: 'source',
      name: t.SourceColumn,
      getCellClassName: item => this.getCellClassName(item, 'source'),
    };
  }

  private get importDateColumn(): LykkeGridColumn<Backoffice.CorporateActions.CorporateActionContract> {
    const t = this.loc.i18n.CorporateActions;
    return {
      field: 'importDate',
      name: t.ImportDateColumn,
      getCellContent: item => <DateTimeFormatter dateTime={momentFromDto(item.importDate)} format='Date' />,
      getCellClassName: item => this.getCellClassName(item, 'importDate'),
    };
  }

  private get statusColumn(): LykkeGridColumn<Backoffice.CorporateActions.CorporateActionContract> {
    const t = this.loc.i18n.CorporateActions;
    return {
      field: 'status',
      name: t.StatusColumn,
      getCellClassName: item => this.getCellClassName(item, 'status'),
      fixed: 'right',
      sortable: true,
      filterable: true,
      getFilterContent: this.renderStatusFilter
    };
  }

  private renderStatusFilter = (filter: FilterDescriptor<Backoffice.CorporateActions.CorporateActionContract>, updateFilter: (updatedFilter: FilterDescriptor<Backoffice.CorporateActions.CorporateActionContract>) => void) => {
    const t = this.loc.i18n.CorporateActions;
    return <StyledLykkeGridFilterWrapper>
      <RadioGroup
        onChange={e => updateFilter({...filter, value: e.currentTarget.value})}
        selectedValue={filter.value || ''} // Blueprint doesn't recognize null as a valid value
      >
        <Radio label={t.AllStatusesFilterLabel} value={''} />
        {this.props.availableStatuses.map(x => <Radio label={x} value={x} key={x} />)}
      </RadioGroup>
    </StyledLykkeGridFilterWrapper>;
  }

  private get editColumn(): LykkeGridColumn<Backoffice.CorporateActions.CorporateActionContract> {
    const t = this.loc.i18n.CorporateActions;
    return {
      field: 'actions' as VirtualField,
      name: t.EditColumn,
      fixed: 'right',
      getCellContent: item => (
        <LykkeGridActionsCell>
          <Tooltip2 content={t.EditButtonLabel}>
            <AnchorButton intent={Intent.NONE} onClick={() => this.props.editClick(item.id)} icon={<Icon icon='edit' title={false} />}></AnchorButton>
          </Tooltip2>
        </LykkeGridActionsCell>
      ),
      initialWidth: 50
    };
  }

  private get columns() {
    const result = [
      this.messageIdColumn,
      this.idColumn,
      this.isinColumn,
      this.mdsCodeColumn,
      this.underlyingNameColumn,
      this.marketAreaColumn,
      this.typeIdColumn,
      this.effectiveDateColumn,
      this.deadlineDateColumn,
      this.rFactorColumn,
      this.rFactorDateColumn,
      this.sourceColumn,
      this.importDateColumn,
      this.statusColumn
    ];
    if (this.props.editClick) {
      result.push(this.editColumn);
    }
    return result;
  }

  public render() {
    return (
      <StyledDiv>
        <LykkeGrid
          uniqueRowKey='id'
          columns={this.columns}
          filters={this.props.filters}
          sort={this.props.sort}
          onStateChange={this.props.onStateChange}
          loading={this.props.loading}
          rows={this.props.data}
          selectable={true}
          rowSelection={{
            onChange: this.props.onSelectedRowKeysChange,
            selectedRowKeys: this.props.selectedRowKeys,
          }}
          expandable={{
            onExpand: this.props.onExpand,
            expandedRowRender: this.props.expandedRowRenderer
          }}
          pageable={true}
          pagination={this.props.pagination}
          paginationSettings={{ position: ['bottomRight'], showSizeChanger: true }}
        />
      </StyledDiv>
    );
  }

  private getCellClassName = (item: Backoffice.CorporateActions.CorporateActionContract, field: keyof Backoffice.CorporateActions.CorporateActionContract) => {
    const result = [];

    if (item.source === 'Manual') { // TODO: from consts
      result.push('ca-event-manual');
      if (field === 'source') { result.push('ca-event-source-cell'); }
    } else if (item.edited) {
      const editFieldsToExclude: Array<keyof Backoffice.CorporateActions.CorporateActionContract> = ['messageId', 'source', 'status', 'effectiveDate', 'deadlineDate', 'importDate'];
      if (!editFieldsToExclude.includes(field)) { result.push('ca-event-edited'); }
    }

    return result;
  }
}