import React from 'react';
import { Button, Intent, Classes } from '@blueprintjs/core';
import moment from 'moment';
import { every, omit } from 'lodash';
import * as Backoffice from '@client/backoffice/Dto';
import { LykkeForm, LabelWithDateInput, LabelWithTextInput, LabelWithCheckbox, LabelWithNumberInput } from '@elements/lykke-html/form/LykkeForm';
import { FrontendError } from '@client/backoffice/CustomErrors';
import { BackofficeErrorCodes } from '@client/backoffice/ErrorCodes';
import { LocalizationService } from '@services/LocalizationService';
import { formatAsISO8601Date, getMaxCalendarDate, toMoment } from '@scripts/utils/date-util';

interface Props {
  sb: jc.Sandbox;
  itemToEdit?: Backoffice.CorporateActions.CorporateActionTaskContract;
  itemToAmend?: Backoffice.CorporateActions.CorporateActionTaskContract;
  onCancel: () => void;
  onSubmit: (caData: Backoffice.CorporateActions.CorporateActionTaskContract) => void;
  loading: boolean;
  serverErrors: FrontendError[];
}

interface State {
  formData: Backoffice.CorporateActions.CorporateActionTaskContract;
  fieldErrors: {[P in keyof Backoffice.CorporateActions.CorporateActionTaskContract]?: string};
}

export class EditAmendCorporateActionTaskForm extends React.Component<Props, State> {
  private readonly minDate = moment().startOf('day');
  private readonly maxDate = moment(getMaxCalendarDate());

  private readonly loc: LocalizationService;

  constructor(props: Props) {
    super(props);
    this.loc = props.sb.getService('localization');
  }

  public state: State = {
    formData: this.props.itemToEdit ? this.props.itemToEdit : this.props.itemToAmend,
    fieldErrors: {},
  };

  private handleEligibleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFormData = {
      ...this.state.formData,
      eligible: e.target.checked
    };

    this.setState({ formData: newFormData });
  }

  private handleFreezeStartChange = (selectedDate: moment.Moment) => {
    const newFormData = {
      ...this.state.formData,
      freezeStart: selectedDate ? formatAsISO8601Date(selectedDate) : null
    };

    this.setState({ formData: newFormData }, () => this.validateFreezeStart());
  }

  private handleFreezeEndChange = (selectedDate: moment.Moment) => {
    const newFormData = {
      ...this.state.formData,
      freezeEnd: selectedDate ? formatAsISO8601Date(selectedDate) : null
    };

    this.setState({ formData: newFormData }, () => this.validateFreezeEnd());
  }

  private handleNotificationDateChange = (selectedDate: moment.Moment) => {
    const newFormData = {
      ...this.state.formData,
      notificationDate: selectedDate ? formatAsISO8601Date(selectedDate) : null
    };

    this.setState({ formData: newFormData }, () => this.validateNotificationDate());
  }

  private handlePositionCloseDateChange = (selectedDate: moment.Moment) => {
    const newFormData = {
      ...this.state.formData,
      positionCloseDate: selectedDate ? formatAsISO8601Date(selectedDate) : null
    };

    this.setState({ formData: newFormData }, () => this.validatePositionCloseDate());
  }

  private handleSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    if (this.validate()) {
      this.props.onSubmit(this.state.formData);
    }
  }

  private validateFreezeStart = () => this.validateDatesSequence();

  private validateFreezeEnd = () => {
    const t = this.loc.i18n;
    const value = this.state.formData.freezeEnd;

    if (value && moment().isAfter(value)) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, freezeEnd: this.loc.t(t.CorporateActions.ClientErrors.DateShouldBeNotPast, [t.CorporateActions.Tasks.FreezeEndLabel])}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'freezeEnd')
      }));

      return this.validateDatesSequence();
    }
  }

  private validateNotificationDate = () => this.validateDatesSequence();

  private validatePositionCloseDate = () => true;

  private validateDatesSequence = () => {
    const t = this.loc.i18n.CorporateActions.ClientErrors;
    const { freezeEnd, freezeStart, notificationDate } = this.state.formData;
    let failed = false;

    if (notificationDate && freezeStart && moment(notificationDate).isAfter(freezeStart)) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, notificationDate: t.NotificationDateShouldNotBeLater, freezeStart: t.FreezeStartDateShouldNotBeEarlier }
      }));
      failed = true;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'notificationDate', 'freezeStart')
      }));
    }

    if (freezeEnd && freezeStart && (moment(freezeEnd).isBefore(freezeStart) || freezeStart === freezeEnd)) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, freezeStart: t.FreezeStartDateShouldBeEarlier, freezeEnd: t.FreezeEndShouldBeLater }
      }));
      failed = true;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'freezeEnd', 'freezeStart')
      }));
    }

    return !failed;
  }

  private validate = () => {
    return every([
      this.validateFreezeStart(),
      this.validateFreezeEnd(),
      this.validateNotificationDate(),
      this.validatePositionCloseDate(),
      this.validateDatesSequence(),
    ]);
  }

  private getServerErrorMessages(field: keyof Backoffice.CorporateActions.CorporateActionTaskContract) {
    return this.props.serverErrors
      ?.filter(e => e.errorCode as BackofficeErrorCodes === 'FieldValidationFailed' && e.fieldNames.includes(field))
      .map(e => e.errorMessage) ?? [];
  }

  private getClientErrorMessage(field: keyof Backoffice.CorporateActions.CorporateActionTaskContract) {
    return this.state.fieldErrors[field];
  }

  private formatErrorMessages(messages: string[]) {
    return messages.filter(m => !!m).join('; ');
  }

  public render() {
    const t = this.loc.i18n.CorporateActions.Tasks;

    return (
      <LykkeForm>
        <div className={Classes.DIALOG_BODY}>
          <LabelWithTextInput label={t.BrokerIdLabel} value={this.state.formData.brokerId} disabled={true} />
          <LabelWithTextInput label={t.TypeIdLabel} value={this.state.formData.typeId} disabled={true} />
          <LabelWithTextInput label={t.ProductIdLabel} value={this.state.formData.productId} disabled={true} />
          <LabelWithCheckbox
            label={t.EligibleLabel}
            value={this.state.formData.eligible}
            onChange={this.handleEligibleChange}
            error={this.formatErrorMessages([...this.getServerErrorMessages('eligible')])}
          />
          <LabelWithDateInput
            label={t.FreezeStartLabel}
            value={toMoment(this.state.formData.freezeStart)}
            maxDate={this.maxDate}
            onChange={this.handleFreezeStartChange}
            error={this.formatErrorMessages([this.getClientErrorMessage('freezeStart'), ...this.getServerErrorMessages('freezeStart')])}
          />
          <LabelWithDateInput
            label={t.FreezeEndLabel}
            value={toMoment(this.state.formData.freezeEnd)}
            minDate={this.minDate}
            maxDate={this.maxDate}
            onChange={this.handleFreezeEndChange}
            error={this.formatErrorMessages([this.getClientErrorMessage('freezeEnd'), ...this.getServerErrorMessages('freezeEnd')])}
          />
          <LabelWithDateInput
            label={t.NotificationDateLabel}
            value={toMoment(this.state.formData.notificationDate)}
            maxDate={this.maxDate}
            disabled={!!this.props.itemToAmend}
            onChange={this.handleNotificationDateChange}
            error={this.formatErrorMessages([this.getClientErrorMessage('notificationDate'), ...this.getServerErrorMessages('notificationDate')])}
          />
          <LabelWithDateInput
            label={t.PositionCloseDateLabel}
            value={toMoment(this.state.formData.positionCloseDate)}
            maxDate={this.maxDate}
            disabled={!!this.props.itemToAmend}
            onChange={this.handlePositionCloseDateChange}
            error={this.formatErrorMessages([this.getClientErrorMessage('positionCloseDate'), ...this.getServerErrorMessages('positionCloseDate')])}
          />
          <LabelWithNumberInput label={t.TotalValueLongLabel} value={this.state.formData.totalValueLong} precision={2} disabled={true} />
          <LabelWithNumberInput label={t.TotalValueShortColumn} value={this.state.formData.totalValueShort} precision={2} disabled={true} />
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={this.props.onCancel}
              disabled={this.props.loading}
              intent={Intent.NONE}
            >
              {t.CancelButtonLabel}
            </Button>
            <Button
              type='submit'
              onClick={this.handleSubmit}
              disabled={this.props.loading}
              loading={this.props.loading}
              intent={Intent.SUCCESS}
            >
              {t.UpdateButtonLabel}
            </Button>
          </div>
        </div>
      </LykkeForm>
    );
  }
}