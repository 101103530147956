import React from 'react';
import { inject } from 'mobx-react';
import { Button, Intent, Dialog, IconName } from '@blueprintjs/core';
import { omit, every } from 'lodash';
import { ILocalizedProps } from '@elements/localization';
import {
  LykkeForm,
  LabelWithTextInput,
  LabelWithSelect,
  LabelWithCheckbox,
  LabelWithNumberInput
} from '@elements/lykke-html/form/LykkeForm';
import { FrontendError } from '@client/backoffice/CustomErrors';
import { BackofficeErrorCodes } from '@client/backoffice/ErrorCodes';
import type { CorporateActionTypes } from '@client/backoffice/Dto';

interface Props extends ILocalizedProps {
  open?: boolean;
  loading?: boolean;
  onCancel();
  onSubmit(typeId: string, item: CorporateActionTypes.CorporateActionTypeConfigContract);
  brokers: string[];
  itemToEdit: CorporateActionTypes.CorporateActionTypeConfigContract;
  icon: IconName;
  title: string;
  typeId: string;
  serverErrors?: FrontendError[];
}

interface State {
  formData: CorporateActionTypes.CorporateActionTypeConfigContract;
  fieldErrors: {[P in keyof CorporateActionTypes.CorporateActionTypeConfigContract]?: string};
}

const getEmptyFormData = (brokers: string[]) => ({
  brokerId: brokers[0],
  typeId: undefined,
  isEligibleFieldEditable: false,
  daysPriorEffectiveDateToClosePosition: 1,
  daysPriorEffectiveDateToNotify: 1,
  defaultBusinessDaysForFreezeEnd: 0,
  defaultBusinessDaysForFreezeStart: 1,
  deleted: false,
  displayInUI: false,
  isEligibleFieldBasedOn871m: false,
  isEligibleFieldEnabledByDefault: false,
  paysDividend: false,
  shouldDiscontinue: false,
} as CorporateActionTypes.CorporateActionTypeConfigContract);

@inject('i18n')
export class AddCorporateActionsTypeConfigDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      formData: { ...(this.props.itemToEdit ?? getEmptyFormData(props.brokers)) },
      fieldErrors: {},
    };
  }

  private handleBrokerIdChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const { value } = e.target;

    this.updateState('brokerId', value);
  }

  private handleCheckboxChange = (name, e: React.ChangeEvent<HTMLInputElement>): void => {
    const { checked } = e.target;

    this.updateState(name, checked);
  }

  private updateState = (name: string, value: string | number | boolean): void => {
    const newFormData = {
      ...this.state.formData,
      [name]: value,
    };

    this.setState({ formData: newFormData });
  }

  private handleSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();

    if (this.validate()) {
      const { onSubmit, typeId } = this.props;

      onSubmit(typeId, this.state.formData);
    }
  }

  private validateField = (field: keyof CorporateActionTypes.CorporateActionTypeConfigContract) => {
    const value = this.state.formData[field];

    if (value === 0 && !value) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, [field]: this.props.i18n.FormValidationErrors.RequiredField}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, field)
      }));
      return true;
    }
  }

  private validate = () => {
    return every([
      this.validateField('brokerId'),
      this.validateField('daysPriorEffectiveDateToClosePosition'),
      this.validateField('daysPriorEffectiveDateToNotify'),
    ]);
  }

  private getServerErrorMessages(field: keyof CorporateActionTypes.CorporateActionTypeConfigContract) {
    return this.props.serverErrors
      ?.filter(e => e.errorCode as BackofficeErrorCodes === 'FieldValidationFailed' && e.fieldNames.includes(field))
      .map(e => e.errorMessage) ?? [];
  }

  private getClientErrorMessage(field: keyof CorporateActionTypes.CorporateActionTypeConfigContract) {
    return this.state.fieldErrors[field];
  }

  private formatErrorMessages(messages: string[]) {
    return messages.filter(m => !!m).join('; ');
  }

  public componentWillReceiveProps(nextProps: Props) {
    if (this.props.open !== nextProps.open) {
      this.setState({
        formData: { ...(this.props.itemToEdit ?? getEmptyFormData(nextProps.brokers)) },
        fieldErrors: {},
      });
    }
  }

  private get loc() {
    return this.props.i18n.CorporateActionTypes;
  }

  private handleClose = () => {
    const { onCancel } = this.props;

    onCancel();
  }

  public render() {
    const { open, loading, brokers, itemToEdit, icon, title, typeId } = this.props;
    const { formData } = this.state;

    return (
      <Dialog
        icon={icon}
        isOpen={open}
        onClose={this.handleClose}
        title={title}
        canOutsideClickClose={false}
      >
        <LykkeForm loading={loading}>
          <div className='bp3-dialog-body'>
            <LabelWithTextInput
              label={this.loc.TypeId}
              value={typeId}
              disabled={true}
            />
            <LabelWithSelect
              label={this.loc.BrokerId}
              value={formData.brokerId}
              options={brokers.map(broker => ({text: broker, value: broker}))}
              error={this.formatErrorMessages([this.getClientErrorMessage('brokerId'), ...this.getServerErrorMessages('brokerId')])}
              disabled={!!itemToEdit}
              onChange={this.handleBrokerIdChange}
            />
            <LabelWithCheckbox
              label={this.loc.IsEligible}
              value={formData.isEligibleFieldEditable}
              onChange={(e) => this.handleCheckboxChange('isEligibleFieldEditable', e)}
            />
            <LabelWithCheckbox
              label={this.loc.IsEligibleByDefault}
              value={formData.isEligibleFieldEnabledByDefault}
              onChange={(e) => this.handleCheckboxChange('isEligibleFieldEnabledByDefault', e)}
            />
            <LabelWithCheckbox
              label={this.loc.IsEligibleFor871n}
              value={formData.isEligibleFieldBasedOn871m}
              onChange={(e) => this.handleCheckboxChange('isEligibleFieldBasedOn871m', e)}
            />
            <LabelWithNumberInput
              label={this.loc.BusinessDaysForFreezeStart}
              value={formData.defaultBusinessDaysForFreezeStart}
              minimumValue={1}
              maximumValue={365}
              precision={0}
              error={this.formatErrorMessages([this.getClientErrorMessage('defaultBusinessDaysForFreezeStart'), ...this.getServerErrorMessages('defaultBusinessDaysForFreezeStart')])}
              onChange={(value) => this.updateState('defaultBusinessDaysForFreezeStart', value)}
            />
            <LabelWithNumberInput
              label={this.loc.BusinessDaysForFreezeEnd}
              value={formData.defaultBusinessDaysForFreezeEnd}
              minimumValue={0}
              maximumValue={365}
              precision={0}
              error={this.formatErrorMessages([this.getClientErrorMessage('defaultBusinessDaysForFreezeEnd'), ...this.getServerErrorMessages('defaultBusinessDaysForFreezeEnd')])}
              onChange={(value) => this.updateState('defaultBusinessDaysForFreezeEnd', value)}
            />
            <LabelWithNumberInput
              label={this.loc.DaysPriorEffectiveDateToNotify}
              value={formData.daysPriorEffectiveDateToNotify}
              minimumValue={1}
              maximumValue={365}
              precision={0}
              error={this.formatErrorMessages([this.getClientErrorMessage('daysPriorEffectiveDateToNotify'), ...this.getServerErrorMessages('daysPriorEffectiveDateToNotify')])}
              onChange={(value) => this.updateState('daysPriorEffectiveDateToNotify', value)}
              onBlur={() => this.validateField('daysPriorEffectiveDateToNotify')}
            />
            <LabelWithNumberInput
              label={this.loc.DaysPriorEffectiveDateToClosePosition}
              value={formData.daysPriorEffectiveDateToClosePosition}
              minimumValue={1}
              maximumValue={365}
              precision={0}
              error={this.formatErrorMessages([this.getClientErrorMessage('daysPriorEffectiveDateToClosePosition'), ...this.getServerErrorMessages('daysPriorEffectiveDateToClosePosition')])}
              onChange={(value) => this.updateState('daysPriorEffectiveDateToClosePosition', value)}
              onBlur={() => this.validateField('daysPriorEffectiveDateToClosePosition')}
            />
            <LabelWithCheckbox
              label={this.loc.DividendsToBePaid}
              value={formData.paysDividend}
              onChange={(e) => this.handleCheckboxChange('paysDividend', e)}
            />
            <LabelWithCheckbox
              label={this.loc.Discontinue}
              value={formData.shouldDiscontinue}
              onChange={(e) => this.handleCheckboxChange('shouldDiscontinue', e)}
            />
          </div>
          <div className='bp3-dialog-footer'>
            <div className='bp3-dialog-footer-actions'>
              <Button
                className='bp3-intent-none'
                disabled={loading}
                onClick={this.handleClose}
              >
                {this.loc.Cancel}
              </Button>
              <Button
                type='submit'
                intent={Intent.PRIMARY}
                disabled={loading}
                onClick={this.handleSubmit}
              >
                {this.loc.Save}
              </Button>
            </div>
          </div>
        </LykkeForm>
      </Dialog>
    );
  }
}