import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { ILocalizedProps } from '@elements/localization';
import * as Backoffice from '@client/backoffice/Dto';
import { LykkeGrid, LykkeGridColumn, FilterDescriptor, SortDescriptor, LykkeGridContext, LykkeGridActionsCell, VirtualField } from '@elements/lykke-html/LykkeGrid';
import { Intent, Classes, AnchorButton, Icon } from '@blueprintjs/core';
import { NumericFormatter } from '@elements/number-formatter/NumericFormatter';
import { Tooltip2 } from '@blueprintjs/popover2';

interface Props extends ILocalizedProps {
  data: Backoffice.RegulatorySettings.RegulatorySettingsContract[];
  loading: boolean;
  filters: Array<FilterDescriptor<Backoffice.RegulatorySettings.RegulatorySettingsContract>>;
  sort: Array<SortDescriptor<Backoffice.RegulatorySettings.RegulatorySettingsContract>>;
  onStateChange?(changeContext: LykkeGridContext<Backoffice.RegulatorySettings.RegulatorySettingsContract>): void;
  editClick: (profileId: string, typeId: string) => void;
}

@inject('i18n')
export class RegulatorySettingsTable extends Component<Props, {}> {
  private readonly precision: number = 2;

  private get regulatoryProfileIdColumn(): LykkeGridColumn<Backoffice.RegulatorySettings.RegulatorySettingsContract> {
    const t = this.props.i18n.RegulationRules.RegulatorySettings;
    return {
      field: 'profileId',
      name: t.RegulatoryProfileColumn,
      sortable: true,
      filterable: true,
      initialWidth: 300,
    };
  }

  private get regulatoryTypeIdColumn(): LykkeGridColumn<Backoffice.RegulatorySettings.RegulatorySettingsContract> {
    const t = this.props.i18n.RegulationRules.RegulatorySettings;
    return {
      field: 'typeId',
      name: t.RegulatoryTypeColumn,
      sortable: true,
      filterable: true,
      initialWidth: 300,
    };
  }

  private get marginMinPercentColumn(): LykkeGridColumn<Backoffice.RegulatorySettings.RegulatorySettingsContract> {
    const t = this.props.i18n.RegulationRules.RegulatorySettings;
    return {
      field: 'marginMinPercent',
      name: t.MarginMinRateColumn,
      getCellContent: item => <NumericFormatter value={item.marginMinPercent} precision={this.precision} />,
      sortable: true
    };
  }

  private get isAvailableColumn(): LykkeGridColumn<Backoffice.RegulatorySettings.RegulatorySettingsContract> {
    const t = this.props.i18n.RegulationRules.RegulatorySettings;
    return {
      field: 'isAvailable',
      name: t.AvailableColumn,
      getCellContent: item => <input type='checkbox' className={Classes.CHECKBOX} checked={item.isAvailable} disabled={true} />,
      sortable: true
    };
  }

  private get actionsColumn(): LykkeGridColumn<Backoffice.RegulatorySettings.RegulatorySettingsContract> {
    const t = this.props.i18n.RegulationRules.RegulatorySettings;
    return {
      field: 'actions' as VirtualField,
      name: t.ActionsColumn,
      fixed: 'right',
      initialWidth: 100,
      getCellContent: item =>
        <LykkeGridActionsCell>
          <Tooltip2 content={t.EditButtonLabel}>
            <AnchorButton intent={Intent.NONE} onClick={() => this.props.editClick(item.profileId, item.typeId)} icon={<Icon icon='edit' title={false} />}></AnchorButton>
          </Tooltip2>
        </LykkeGridActionsCell>
    };
  }

  private get columns() {
    return [
      this.regulatoryProfileIdColumn,
      this.regulatoryTypeIdColumn,
      this.marginMinPercentColumn,
      this.isAvailableColumn,
      this.actionsColumn,
    ];
  }

  public render() {
    return (
      <LykkeGrid
        uniqueRowKey={record => `${record.profileId}_${record.typeId}`}
        columns={this.columns}
        filters={this.props.filters}
        sort={this.props.sort}
        onStateChange={this.props.onStateChange}
        loading={this.props.loading}
        rows={this.props.data}
      ></LykkeGrid>
    );
  }
}