import { CancelAndAdjustTabSettings, TabType } from '@models/TabSettings';
import TabSettingsGeneration from '@models/utils/TabSettingsGeneration';
import SharedValidation from './SharedValidation';

export class AdjustDraftsValidation {

  public static validate(adjustDraftTabSettings: CancelAndAdjustTabSettings[] = []): CancelAndAdjustTabSettings[] {

    let [
      adjustDraftsSettings = TabSettingsGeneration.generateCancelAndAdjustSettings(),
      ...customAdjustDraftSettings
    ] = adjustDraftTabSettings;

    adjustDraftsSettings = AdjustDraftsValidation.sanitizeCancelAndAdjustTabSettings(adjustDraftsSettings);
    customAdjustDraftSettings = AdjustDraftsValidation.sanitizeCustomCancelAndAdjustTabSettings(customAdjustDraftSettings);

    return [
      adjustDraftsSettings,
      ...customAdjustDraftSettings
    ];
  }

  private static sanitizeCancelAndAdjustTabSettings(adjustDraftsSettings: CancelAndAdjustTabSettings): CancelAndAdjustTabSettings {
    const errors = AdjustDraftsValidation.isValidAdjustDraft(adjustDraftsSettings);
    return errors.length === 0 ? adjustDraftsSettings : TabSettingsGeneration.generateCancelAndAdjustSettings();
  }

  private static sanitizeCustomCancelAndAdjustTabSettings(customAdjustDraftSettings: CancelAndAdjustTabSettings[] = []): CancelAndAdjustTabSettings[] {
    const validatedCustomAdjustDraftSettings = customAdjustDraftSettings.map((customAdjustDraft) => {
      const errors = AdjustDraftsValidation.isValidAdjustDraft(customAdjustDraft);
      return errors.length === 0 ? customAdjustDraft : null;
    }).filter(customAdjustDraft => !!customAdjustDraft);

    return validatedCustomAdjustDraftSettings;
  }

  private static isValidAdjustDraft(adjustDraft: CancelAndAdjustTabSettings): string[] {
    const errors = [];

    const { id, name, tabType, hidden, created, instrumentOrder, instrumentAdjustment, status, deselectedColumns } = adjustDraft;

    if (!SharedValidation.isString(id)) {
      errors.push('ID must be a string.');
    }

    if (!SharedValidation.isString(name)) {
      errors.push('Name must be a string.');
    }

    if (tabType !== TabType.CancelAndAdjust) {
      errors.push(`Invalid TabType ${tabType}, must be ${TabType.CancelAndAdjust}.`);
    }

    if (!SharedValidation.isBoolean(hidden)) {
      errors.push('Property hidden must be a boolean.');
    }

    if (!SharedValidation.isTimeRange(created))  {
      errors.push('Property created must be of type TimeRange.');
    }

    if (!SharedValidation.isString(instrumentOrder)) {
      errors.push('Property instrumentOrder must be a string.');
    }

    if (!SharedValidation.isString(instrumentAdjustment)) {
      errors.push('Property instrumentAdjustment must be a string.');
    }

    if (!SharedValidation.isArrayOfStrings(deselectedColumns)) { // possible to improve this check with columnNames
      errors.push('Property deselectedColumns must be an array of strings.');
    }

    if (!AdjustDraftsValidation.isValidStatusObject(status)) {
      errors.push('Property status is invalid. Must be on object with 3 boolean properties - pending, rejected and approved.');
    }

    return errors;
  }

  private static isValidStatusObject(statusObj: object) {
    const statuses = ['pending', 'rejected', 'approved'];
    const _keys = Object.keys(statusObj);

    return _keys.every(x => statuses.includes(x) && SharedValidation.isBoolean(statusObj[x]));
  }

}