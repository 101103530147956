import { Menu, MenuItem } from '@blueprintjs/core';
import * as React from 'react';

interface Props {
  handleClickTrades: () => void;
  handleClickPositions: () => void;
}

export class SearchPopoverContent extends React.Component<Props, {}> {
  public render() {
    return (
      <Menu>
        <MenuItem onClick={this.props.handleClickTrades} text='Trades' />
        <MenuItem onClick={this.props.handleClickPositions} text='Positions' />
      </Menu>
    );
  }
}
