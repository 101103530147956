import { useState, useMemo } from 'react';
import { FrontendBadRequest } from '@client/backoffice/CustomErrors';
import { GeneralErrorCodes } from '@client/backoffice/ErrorCodes/GeneralErrorCodes';

export function useBackofficeError<T>() {
  const [error, setError] = useState<FrontendBadRequest>(null);

  return useMemo(() => ({
    clear() {
      setError(null);
    },
    set(e: FrontendBadRequest) {
      setError(e);
    },
    getFieldErrorMessage(field: keyof T) {
      if (error == undefined) { return ''; }

      return error.errors.filter(e => e.errorCode as GeneralErrorCodes === 'FieldValidationFailed' && e.fieldNames.includes(field as string))
        .map(e => e.errorMessage)
        .join('; ');
    }
  }), [error]);
}