
import React from 'react';
import { inject } from 'mobx-react';
import { cloneDeep, isEqual, every } from 'lodash';
import { Button, Intent } from '@blueprintjs/core';
import * as Backoffice from '@client/backoffice/Dto';
import { ILocalizedProps } from '@elements/localization';
import { LykkeForm, LykkeFormFooter, LykkeButtonGroup, LabelWithNumberInput, LabelWithTextInput, LykkeFormRow, LykkeFormColumn } from '@elements/lykke-html/form/LykkeForm';
import styled from 'styled-components';

const TickFormulaForm = styled(LykkeForm)`
  margin-bottom: 20px;

  label.bp3-label .bp3-input, label.bp3-label .bp3-numeric-input {
    min-width: 200px;
  }

  label.lykke-input {
    padding: 0;
    margin: 0;
  }

  .bp3-form-group {
    margin: 0;
  }

  .bp3-button-group {
    display: none;
  }
`;

const TickFormulaFormRow = styled(LykkeFormRow)`
  margin-bottom: 20px;

  .lykke-form-column {
    width: 250px;
  }

  .lykke-form-column button.bp3-button {
    width: 30px;
    height: 30px;
    margin: 0;
  }

  .lykke-form-column > div > button.bp3-button {
    margin: 20px 0;
  }
`;

const PdlTicksColumn = styled(LykkeFormColumn)`
  justify-content: space-around;
`;

const PdlLadderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 250px;
`;

const ResultRow = styled.div`
  display: flex;

  div {
    margin-right: 10px;
  }
`;

interface Props extends ILocalizedProps {
  tickFormula: Backoffice.TickFormulas.TickFormulaContract;
  loading?: boolean;
  onDirtyChange(isDirty: boolean);
  onSubmit(tickFormula: Backoffice.TickFormulas.TickFormulaContract);
}

interface State {
  tickFormula: Backoffice.TickFormulas.TickFormulaContract;
  pdlLadderErrors: string[];
  pdlTickErrors: string[];
}

@inject('i18n')
export class EditTickFormulaForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tickFormula: cloneDeep(props.tickFormula),
      pdlLadderErrors: new Array(props.tickFormula.pdlLadders.length),
      pdlTickErrors: new Array(props.tickFormula.pdlTicks.length)
    };
  }

  private get loc() {
    return this.props.i18n.BrokerSettingsView.TickFormulasTab;
  }

  public componentWillReceiveProps(nextProps: Props) {
    if (this.props.tickFormula !== nextProps.tickFormula) {
      this.setState({
        tickFormula: cloneDeep(nextProps.tickFormula),
        pdlLadderErrors: new Array(nextProps.tickFormula.pdlLadders.length),
        pdlTickErrors: new Array(nextProps.tickFormula.pdlTicks.length)
      });
    }
  }

  private isLadderValid = (index: number) => {
    const { tickFormula: { pdlLadders }, pdlLadderErrors } = this.state;

    if (index === 0) {
      return true;
    }
    if (pdlLadders[index] === null) {
      pdlLadderErrors[index] = this.props.i18n.FormValidationErrors.RequiredField;
      this.setState({ pdlLadderErrors });
      return false;
    }
    if (pdlLadders[index] <= pdlLadders[index - 1]) {
      pdlLadderErrors[index] = this.props.i18n.BackofficeErrors.PdlLaddersMustBeInAscendingOrderWithoutDuplicates;
      this.setState({ pdlLadderErrors });
      return false;
    }
    return true;
  }

  private isTickValid = (index: number) => {
    const { tickFormula: { pdlLadders, pdlTicks }, pdlTickErrors } = this.state;

    if (pdlTicks[index] === null) {
      pdlTickErrors[index] = this.props.i18n.FormValidationErrors.RequiredField;
      this.setState({ pdlTickErrors });
      return false;
    }
    if (pdlTicks[index] <= 0) {
      pdlTickErrors[index] = this.props.i18n.BackofficeErrors.PdlTicksValuesMustBeGreaterThanZero;
      this.setState({ pdlTickErrors });
      return false;
    }
    if (pdlTicks[index] < pdlTicks[index - 1]) {
      pdlTickErrors[index] = this.props.i18n.BackofficeErrors.PdlTicksMustBeInAscendingOrder;
      this.setState({ pdlTickErrors });
      return false;
    }
    if (pdlTicks.length > index + 1
      && pdlLadders[index + 1] !== null
      && pdlTicks[index] > pdlLadders[index + 1]
    ) {
      pdlTickErrors[index] = this.props.i18n.BackofficeErrors.PdlTicksMustBeInAscendingOrder;
      this.setState({ pdlTickErrors });
      return false;
    }
    return true;
  }

  private isValid = () => {
    const { tickFormula: { pdlLadders, pdlTicks } } = this.state;

    const pdlLaddersValidation = pdlLadders.map((_, index) => this.isLadderValid(index));
    const pdlTicksValidation = pdlTicks.map((_, index) => this.isTickValid(index));

    return every(pdlLaddersValidation.concat(pdlTicksValidation));
  }

  public isDirty = () => {
    const { onDirtyChange } = this.props;
    const isDirty = !isEqual(this.state.tickFormula, this.props.tickFormula);

    onDirtyChange(isDirty);
    return isDirty;
  }

  private handlePdlLadderChange = (value: number, index: number) => {
    const { tickFormula, pdlLadderErrors } = this.state;
    const { pdlLadders } = tickFormula;
    pdlLadders[index] = value;
    pdlLadderErrors[index] = '';

    this.setState({
      tickFormula: {
        pdlLadders,
        ...this.state.tickFormula
      },
      pdlLadderErrors
    });
  }

  private handlePdlTickChange = (value: number, index: number) => {
    console.log('handlePdlTickChange', index);
    const { tickFormula, pdlTickErrors } = this.state;
    const { pdlTicks } = tickFormula;
    pdlTicks[index] = value;
    pdlTickErrors[index] = '';

    this.setState({
      tickFormula: {
        pdlTicks,
        ...this.state.tickFormula
      },
      pdlTickErrors
    });
  }

  private handleAddLadderClick = (index: number) => {
    const { tickFormula, pdlLadderErrors, pdlTickErrors } = this.state;
    const { pdlLadders, pdlTicks } = tickFormula;
    pdlLadders.splice(index + 1, 0, null);
    pdlTicks.splice(index + 1, 0, null);
    pdlLadderErrors.splice(index + 1, 0, null);
    pdlTickErrors.splice(index + 1, 0, null);

    this.setState({
      tickFormula: {
        pdlLadders,
        pdlTicks,
        ...tickFormula
      },
      pdlLadderErrors,
      pdlTickErrors
    });
  }

  private handleRemoveLadderClick = (index: number) => {
    const { tickFormula, pdlLadderErrors, pdlTickErrors } = this.state;
    const { pdlLadders, pdlTicks } = tickFormula;
    pdlLadders.splice(index, 1);
    pdlTicks.splice(index, 1);
    pdlLadderErrors.splice(index, 1);
    pdlTickErrors.splice(index, 1);

    this.setState({
      tickFormula: {
        pdlLadders,
        pdlTicks,
        ...tickFormula
      },
      pdlLadderErrors,
      pdlTickErrors
    });
  }

  private handleDiscardClick = () => {
    this.setState({
      tickFormula: cloneDeep(this.props.tickFormula),
      pdlLadderErrors: new Array(this.props.tickFormula.pdlLadders.length),
      pdlTickErrors: new Array(this.props.tickFormula.pdlTicks.length)
    });
  }

  private handleSaveClick = () => {
    const { onSubmit } = this.props;

    if (this.isValid()) {
      onSubmit(this.state.tickFormula);
    }
  }

  public render() {
    const { loading } = this.props;
    const { tickFormula, pdlLadderErrors, pdlTickErrors } = this.state;
    const precision = 6;

    return tickFormula ? (
      <TickFormulaForm loading={loading}>
        <TickFormulaFormRow>
          <LykkeFormColumn>{this.loc.Bid}</LykkeFormColumn>
          <LykkeFormColumn>{this.loc.Tick}</LykkeFormColumn>
        </TickFormulaFormRow>
        <TickFormulaFormRow>
          <LykkeFormColumn>
            {tickFormula.pdlLadders.map((pdlLadder, index) => (
              <div key={index}>
                <PdlLadderRow key={`${index}d`}>
                  {index === 0 && (
                    <LabelWithTextInput
                      disabled
                      label=''
                      value='0'
                    />
                  )}
                  {index !== 0 && (
                    <LabelWithNumberInput
                      label=''
                      value={pdlLadder}
                      onChange={value => this.handlePdlLadderChange(value, index)}
                      onBlur={() => this.isLadderValid(index)}
                      minimumValue={0}
                      precision={precision}
                      error={pdlLadderErrors[index]}
                    />
                  )}
                  {index > 0 && (
                    <Button className='bp3-intent-none' onClick={() => this.handleRemoveLadderClick(index)}>x</Button>
                  )}
                </PdlLadderRow>
                <Button key={`${index}b`} className='bp3-intent-none' onClick={() => this.handleAddLadderClick(index)}>+</Button>
              </div>
            ))}
            <LabelWithTextInput
              disabled
              label=''
              value={this.loc.Infinity}
            />
          </LykkeFormColumn>
          <PdlTicksColumn>
            {tickFormula.pdlTicks.map((pdlTick, index) => (
              <LabelWithNumberInput
                key={index}
                label=''
                value={pdlTick}
                onChange={value => this.handlePdlTickChange(value, index)}
                onBlur={() => this.isTickValid(index)}
                minimumValue={0}
                precision={precision}
                error={pdlTickErrors[index]}
              />
            ))}
          </PdlTicksColumn>
        </TickFormulaFormRow>
        <ResultRow>
          <div>{this.loc.PdlLadders}</div>
          {this.props.tickFormula.pdlLadders.map((pdlLadder, index) => <div key={index}>{` ${pdlLadder}`}</div>)}
        </ResultRow>
        <ResultRow>
          <div>{this.loc.PdlTicks}</div>
          {this.props.tickFormula.pdlTicks.map((pdlTick, index) => <div key={index}>{` ${pdlTick}`}</div>)}
        </ResultRow>
        <LykkeFormFooter>
          <LykkeButtonGroup>
            <Button
              intent={Intent.NONE}
              disabled={!this.isDirty()}
              onClick={this.handleDiscardClick}
            >
              {this.loc.Discard}
            </Button>
            <Button
              intent={Intent.PRIMARY}
              onClick={this.handleSaveClick}
            >
              {this.loc.Save}
            </Button>
          </LykkeButtonGroup>
        </LykkeFormFooter>
      </TickFormulaForm>
    ) : null;
  }
}