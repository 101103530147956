import { CreditSettings, TabType } from '@models/TabSettings';
import TabSettingsGeneration from '@models/utils/TabSettingsGeneration';
import SharedValidation from './SharedValidation';

export class CreditRequestsValidation {

  public static validate(creditRequestsSettings: CreditSettings[] = []): CreditSettings[] {

    let [
      creditSettings = TabSettingsGeneration.generateCreditRequestSettings(),
      ...customCreditSettings
    ] = creditRequestsSettings;

    creditSettings = CreditRequestsValidation.sanitizeCreditSettings(creditSettings);
    customCreditSettings = CreditRequestsValidation.sanitizeCustomCreditSettings(customCreditSettings);

    return [
      creditSettings,
      ...customCreditSettings
    ];
  }

  private static sanitizeCreditSettings(creditSettings: CreditSettings): CreditSettings {
    const errors = CreditRequestsValidation.isValidCreditRequest(creditSettings);
    return errors.length === 0 ? creditSettings : TabSettingsGeneration.generateCreditRequestSettings();
  }

  private static sanitizeCustomCreditSettings(customCreditSettings: CreditSettings[] = []): CreditSettings[] {
    const validatedCustomCreditSettings = customCreditSettings.map((customCreditRequest) => {
      const errors = CreditRequestsValidation.isValidCreditRequest(customCreditRequest);
      return errors.length === 0 ? customCreditRequest : null;
    }).filter(customCreditRequest => !!customCreditRequest);

    return validatedCustomCreditSettings;
  }

  private static isValidCreditRequest(creditSettings: CreditSettings): string[] {
    const errors = [];

    const { id, name, tabType, hidden, created, modified, deselectedColumns, status } = creditSettings;

    if (!SharedValidation.isString(id)) {
      errors.push('ID must be a string.');
    }

    if (!SharedValidation.isString(name)) {
      errors.push('Name must be a string.');
    }

    if (tabType !== TabType.Credit) {
      errors.push(`Invalid TabType ${tabType}, must be ${TabType.Credit}.`);
    }

    if (!SharedValidation.isTimeRange(created))  {
      errors.push('Property created must be of type TimeRange.');
    }

    if (!SharedValidation.isTimeRange(modified))  {
      errors.push('Property modified must be of type TimeRange.');
    }

    if (!SharedValidation.isArrayOfStrings(deselectedColumns)) { // possible to improve this check with columnNames
      errors.push('Property deselectedColumns must be an array of strings.');
    }

    if (!SharedValidation.isBoolean(hidden)) {
      errors.push('Property hidden must be a boolean.');
    }

    if (!CreditRequestsValidation.isValidStatusObject(status)) {
      errors.push('Property status is invalid. Must be on object with 4 boolean properties - Inactive, Active, Rejected and Revoked.');
    }

    return errors;
  }

  private static isValidStatusObject(statusObject: object) {
    const statuses = ['Inactive', 'Active', 'Rejected', 'Revoked'];
    const _keys = Object.keys(statusObject);
    return _keys.every(x => statuses.includes(x) && SharedValidation.isBoolean(statusObject[x]));
  }

}