import React from 'react';
import { Button, Intent, Classes } from '@blueprintjs/core';
import * as Backoffice from '@client/backoffice/Dto';
import { LabelWithTextInput, LykkeForm, LabelWithNumberInput, LabelWithCheckbox } from '@elements/lykke-html/form/LykkeForm';
import { ILocalizedProps } from '@elements/localization';
import { inject } from 'mobx-react';
import { FrontendError } from '@client/backoffice/CustomErrors';
import { BackofficeErrorCodes } from '@client/backoffice/ErrorCodes';
import { omit, every } from 'lodash';

interface Props extends ILocalizedProps {
  itemToEdit: Backoffice.RegulatorySettings.RegulatorySettingsContract;
  onCancel: () => void;
  onSubmit: (regulatorySettingData: Backoffice.RegulatorySettings.RegulatorySettingsContract) => void;
  loading: boolean;
  serverErrors: FrontendError[];
}

interface State {
  formData: Backoffice.RegulatorySettings.RegulatorySettingsContract;
  fieldErrors: {[P in keyof Backoffice.RegulatorySettings.RegulatorySettingsContract]?: string};
}

@inject('i18n')
export class EditRegulatorySettingForm extends React.Component<Props, State> {
  private readonly precision: number = 2;

  public state: State = {
    formData: this.props.itemToEdit,
    fieldErrors: {},
  };

  private handleMarginMinPercentChange = (value: number) => {
    const newFormData = {
      ...this.state.formData,
      marginMinPercent: value
    };

    this.setState({ formData: newFormData });
  }

  private handleIsAvailableChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFormData = {
      ...this.state.formData,
      isAvailable: e.target.checked
    };

    this.setState({ formData: newFormData });
  }

  private handleSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    if (this.validate()) {
      this.props.onSubmit(this.state.formData);
    }
  }

  private validateMarginMinPercent = () => {
    const t = this.props.i18n.RegulationRules.RegulatorySettings.ClientErrors;
    const value = this.state.formData.marginMinPercent;

    if (value === null) {
      this.setState(state => ({
        fieldErrors: {...state.fieldErrors, marginMinPercent: t.MarginMinPercentFieldRequired}
      }));
      return false;
    } else {
      this.setState(state => ({
        fieldErrors: omit(state.fieldErrors, 'marginMinPercent')
      }));
      return true;
    }
  }

  private validate = () => {
    return every([
      this.validateMarginMinPercent(),
    ]);
  }

  private getServerErrorMessages(field: keyof Backoffice.RegulatorySettings.RegulatorySettingsContract) {
    return this.props.serverErrors
      ?.filter(e => e.errorCode as BackofficeErrorCodes === 'FieldValidationFailed' && e.fieldNames.includes(field))
      .map(e => e.errorMessage) ?? [];
  }

  private getClientErrorMessage(field: keyof Backoffice.RegulatorySettings.RegulatorySettingsContract) {
    return this.state.fieldErrors[field];
  }

  private formatErrorMessages(messages: string[]) {
    return messages.filter(m => !!m).join('; ');
  }

  public render() {
    const { loading } = this.props;
    const t = this.props.i18n.RegulationRules.RegulatorySettings;

    return (
      <LykkeForm>
        <div className={Classes.DIALOG_BODY}>
          <LabelWithTextInput
            label={t.RegulatoryProfileLabel}
            value={this.state.formData.profileId}
            disabled={true} />
          <LabelWithTextInput
            label={t.RegulatoryTypeLabel}
            value={this.state.formData.typeId}
            disabled={true} />
          <LabelWithNumberInput
            autoFocus={true}
            label={t.MarginMinRateLabel}
            value={this.state.formData.marginMinPercent}
            minimumValue={0}
            maximumValue={100}
            precision={this.precision}
            onChange={this.handleMarginMinPercentChange}
            onBlur={this.validateMarginMinPercent}
            error={this.formatErrorMessages([this.getClientErrorMessage('marginMinPercent'), ...this.getServerErrorMessages('marginMinPercent')])} />
          <LabelWithCheckbox
            label={t.AvailableLabel}
            value={this.state.formData.isAvailable}
            onChange={this.handleIsAvailableChange} />
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={this.props.onCancel}
              disabled={loading}
              intent={Intent.NONE}
            >
              {t.CancelButtonLabel}
            </Button>
            <Button
              type='submit'
              onClick={this.handleSubmit}
              disabled={loading}
              loading={loading}
              intent={Intent.SUCCESS}
            >
              {t.UpdateButtonLabel}
            </Button>
          </div>
        </div>
      </LykkeForm>
    );
  }
}