import {
  TabType,
  OrderTabSettings,
  PositionTabSettings,
  NewsTabSettings,
  ActivityTabSettings,
  CashMovementTabSettings,
  CostsAndChargesSettings,
  PriceAlertTabSettings,
} from '@models/TabSettings';
import { KnownModule } from '@models/enums/KnownModules';
import { v4 } from 'uuid';
import TabSettingsGeneration from '@models/utils/TabSettingsGeneration';

export class TabSettingsFactory {
  public static generateCustomTabSettings(
    tabType: TabType,
    props?: { isOpen?: boolean, module?: KnownModule }
  ) {

    switch (tabType) {
      case TabType.PriceAlerts: return this.generatePriceAlertsSettings();
      case TabType.Activity: return this.generateActivitiesSettings();
      case TabType.Dealing: return this.generateCashMovementSettings();
      case TabType.CostsAndCharges: return this.generateCostsAndChargesSettings();
      case TabType.News: return this.generateNewsSettings();
      case TabType.Order: {
        if (typeof props.isOpen === 'undefined') {
          throw new Error('isOpen is not defined!');
        } else {
          switch (props.module) {
            case KnownModule.OpenOrder: return this.generateOpenOrderSettings();
            case KnownModule.ExecutedOrder: return this.generateExecutedOrderSettings();
            case KnownModule.OtherOrder: return this.generateOtherOrderSettings();
            default: return this.generateOpenOrderSettings();
          }
        }
      }
      case TabType.Position: {
        if (typeof props.isOpen === 'undefined') {
          throw new Error('isOpen is not defined!');
        } else {
          return props.isOpen ? this.generateOpenPositionsSettings() : this.generateClosedPositionsSettings();
        }
      }
      default:
        throw new Error('No default settings found for this tab type. ' +
          'Please, create default tab settings generator function for this tab type.');
    }
  }

  private static generateOpenOrderSettings(): OrderTabSettings {
    const defaultOpenOrdersSettings = TabSettingsGeneration.generateOpenOrderSettings();
    const customOpenOrdersSettings: Partial<OrderTabSettings> = {
      id: v4(),
      name: '',
      modified: {
        start: null,
        end: null,
        period: null
      },
    };

    return {
      ...defaultOpenOrdersSettings,
      ...customOpenOrdersSettings
    };
  }

  private static generateExecutedOrderSettings(): OrderTabSettings {
    const defaultExecutedOrdersSettings = TabSettingsGeneration.generateExecutedOrderSettings();
    const customExecutedOrdersSettings: Partial<OrderTabSettings> = {
      id: v4(),
      name: '',
      modified: {
        start: null,
        end: null,
        period: null
      },
    };

    return {
      ...defaultExecutedOrdersSettings,
      ...customExecutedOrdersSettings,
    };
  }

  private static generateOtherOrderSettings(): OrderTabSettings {
    const defaultOtherOrdersSettings = TabSettingsGeneration.generateOtherOrdersSettings();
    const customOtherOrdersSettings: Partial<OrderTabSettings> = {
      id: v4(),
      name: '',
      modified: {
        start: null,
        end: null,
        period: null
      },
    };

    return { ...defaultOtherOrdersSettings, ...customOtherOrdersSettings };
  }

  private static generateOpenPositionsSettings(): PositionTabSettings {
    const defaultOpenPositionsSettings = TabSettingsGeneration.generateOpenPositionsSettings();
    const customOpenPositionsSettings: Partial<PositionTabSettings> = {
      id: v4(),
      name: '',
    };

    return { ...defaultOpenPositionsSettings, ...customOpenPositionsSettings };
  }

  private static generateClosedPositionsSettings(): PositionTabSettings {
    const defaultClosedPositionsSettings = TabSettingsGeneration.generateClosedPositionsSettings();
    const customOpenPositionsSettings: Partial<PositionTabSettings> = {
      id: v4(),
      name: '',
      created: {
        start: null,
        end: null,
        period: null
      },
    };

    return { ...defaultClosedPositionsSettings, ...customOpenPositionsSettings };
  }

  private static generateNewsSettings(): NewsTabSettings {
    const defaultNewsSettings = TabSettingsGeneration.generateNewsSettings();
    const customNewsSettings: Partial<NewsTabSettings> = {
      id: v4(),
      name: '',
    };

    return { ...defaultNewsSettings, ...customNewsSettings };
  }

  private static generatePriceAlertsSettings(): PriceAlertTabSettings {
    const defaultPriceAlertSettings = TabSettingsGeneration.generatePriceAlertSettings();
    const customPriceAlertSettings: Partial<PriceAlertTabSettings> = {
      id: v4(),
      name: '',
    };

    return { ...defaultPriceAlertSettings, ...customPriceAlertSettings };
  }

  private static generateActivitiesSettings(): ActivityTabSettings {
    const defaultActivitySettings = TabSettingsGeneration.generateActivitiesSettings();
    const customActivitySettings: Partial<ActivityTabSettings> = {
      id: v4(),
      name: '',
      created: {
        start: null,
        end: null,
        period: null
      },
    };

    return { ...defaultActivitySettings, ...customActivitySettings };
  }

  private static generateCashMovementSettings(): CashMovementTabSettings {
    const defaultCashMovementSettings: CashMovementTabSettings = TabSettingsGeneration.generateCashMovementSettings();
    const customCashMovementSettings: Partial<CashMovementTabSettings> = {
      id: v4(),
      name: '',
      dealingPoint: {
        start: null,
        end: null,
        period: null
      },
    };

    return { ...defaultCashMovementSettings, ...customCashMovementSettings };
  }

  private static generateCostsAndChargesSettings(): CostsAndChargesSettings {
    const costsAndChargesSettings = TabSettingsGeneration.generateCostsAndChargesSettings();

    return costsAndChargesSettings;
  }
}
