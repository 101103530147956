import { LocalizedStore } from '@elements/localization';
import { action, observable, flow, computed } from 'mobx';
import * as Backoffice from '@client/backoffice/Dto';
import type { CancellablePromise } from 'mobx/lib/api/flow';
import { SortDescriptor, LykkeGridContext } from '@elements/lykke-html/LykkeGrid';
import { sortGridData, filterGridData, ExtendedFilterDescriptor } from '@elements/lykke-html/LykkeGridData';
import { instanceOfIAjaxResponse } from '@services/network/AjaxService';
import { FrontendBadRequest, isBackofficeFrontendBadRequest } from '@client/backoffice/CustomErrors';
import { RegulatorySettingsClient } from '@client/backoffice/RegulatorySettingsClient';

export class RegulatorySettingsStore extends LocalizedStore {
  private readonly regulatorySettingsClient: RegulatorySettingsClient;

  @observable public regulatorySettings: Backoffice.RegulatorySettings.RegulatorySettingsContract[] = [];
  @observable public isLoadingFetch: boolean = false;
  @observable public errorsFetch: FrontendBadRequest;

  @observable public isLoadingUpdate: boolean = false;
  @observable public errorsUpdate: FrontendBadRequest;

  @observable public filters: Array<ExtendedFilterDescriptor<Backoffice.RegulatorySettings.RegulatorySettingsContract>> = [
    { type: 'text', field: 'profileId', operator: 'contains', value: null, ignoreCase: true },
    { type: 'text', field: 'typeId', operator: 'contains', value: null, ignoreCase: true },
  ];
  @observable public sort: Array<SortDescriptor<Backoffice.RegulatorySettings.RegulatorySettingsContract>> = [];

  @computed
  public get filteredAndSortedRegulatorySettings() {
    return sortGridData(filterGridData(this.regulatorySettings, this.filters), this.sort);
  }

  constructor(sandbox: jc.Sandbox) {
    super(sandbox);
    this.regulatorySettingsClient = sandbox.getService('client/backoffice/regulatory-settings');
  }

  public fetchRegulatorySettings: (regulationId: string) => CancellablePromise<any> =
  flow(function* fetchRegulatorySettings(this: RegulatorySettingsStore, regulationId: string) {
    this.isLoadingFetch = true;
    try {
      const data: Backoffice.RegulatorySettings.GetRegulatorySettingsResponse = yield this.regulatorySettingsClient.getRegulatorySettingsAsync(regulationId);
      this.regulatorySettings = data.regulatorySettings;
      this.errorsFetch = undefined;
    } catch (error) {
      if (instanceOfIAjaxResponse(error)) {
        const errorData = JSON.parse(error.data);
        if (isBackofficeFrontendBadRequest(errorData)) {
          this.errorsFetch = errorData;
        }
      }
      throw error;
    } finally {
      this.isLoadingFetch = false;
    }
  });

  public updateRegulatorySetting: (profileId: string, typeId: string, request: Backoffice.RegulatorySettings.UpdateRegulatorySettingsRequest) => CancellablePromise<any> =
  flow(function* updateRegulatorySetting(this: RegulatorySettingsStore, profileId: string, typeId: string, request: Backoffice.RegulatorySettings.UpdateRegulatorySettingsRequest) {
    this.isLoadingUpdate = true;
    try {
      yield this.regulatorySettingsClient.updateRegulatorySettingsAsync(request, profileId, typeId);
      this.regulatorySettings = this.regulatorySettings
        .map(c => c.profileId === profileId && c.typeId === typeId
          ? {
            isAvailable: request.isAvailable,
            marginMinPercent: request.marginMinPercent,
            profileId: profileId,
            typeId: typeId
          }
          : c);
      this.errorsUpdate = undefined;
    } catch (error) {
      if (instanceOfIAjaxResponse(error)) {
        const errorData = JSON.parse(error.data);
        if (isBackofficeFrontendBadRequest(errorData)) {
          this.errorsUpdate = errorData;
        }
      }
      throw error;
    } finally {
      this.isLoadingUpdate = false;
    }
  });

  @action.bound
  public updateFilterAndSortState(changeContext: LykkeGridContext<Backoffice.RegulatorySettings.RegulatorySettingsContract>) {
    this.filters = changeContext.filters as any; // LykkeGridContext would proxy any extensions we pass to its filters
    this.sort = changeContext.sort;
  }
}