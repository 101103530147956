import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { ILocalizedProps } from '@elements/localization';
import * as Backoffice from '@client/backoffice/Dto';
import { LykkeGrid, LykkeGridColumn, FilterDescriptor, SortDescriptor, LykkeGridContext, LykkeGridActionsCell, VirtualField } from '@elements/lykke-html/LykkeGrid';
import { Intent, Classes, AnchorButton, Icon } from '@blueprintjs/core';
import { NumericFormatter } from '@elements/number-formatter/NumericFormatter';
import { Tooltip2 } from '@blueprintjs/popover2';

interface Props extends ILocalizedProps {
  data: Backoffice.ClientProfileSettings.ClientProfileSettingsContract[];
  loading: boolean;
  filters: Array<FilterDescriptor<Backoffice.ClientProfileSettings.ClientProfileSettingsContract>>;
  sort: Array<SortDescriptor<Backoffice.ClientProfileSettings.ClientProfileSettingsContract>>;
  onStateChange?(changeContext: LykkeGridContext<Backoffice.ClientProfileSettings.ClientProfileSettingsContract>): void;
  editClick: (profileId: string, typeId: string) => void;
}

@inject('i18n')
export class ProfileSettingsTable extends Component<Props, {}> {
  private readonly precision: number = 2;

  private get clientProfileIdColumn(): LykkeGridColumn<Backoffice.ClientProfileSettings.ClientProfileSettingsContract> {
    const t = this.props.i18n.BrokerSettingsView.ProfilesParametersTab.ProfileSettings;
    return {
      field: 'clientProfileId',
      name: t.ClientProfileColumn,
      sortable: true,
      filterable: true
    };
  }

  private get assetTypeIdColumn(): LykkeGridColumn<Backoffice.ClientProfileSettings.ClientProfileSettingsContract> {
    const t = this.props.i18n.BrokerSettingsView.ProfilesParametersTab.ProfileSettings;
    return {
      field: 'assetTypeId',
      name: t.AssetTypeColumn,
      sortable: true,
      filterable: true
    };
  }

  private get marginPercentColumn(): LykkeGridColumn<Backoffice.ClientProfileSettings.ClientProfileSettingsContract> {
    const t = this.props.i18n.BrokerSettingsView.ProfilesParametersTab.ProfileSettings;
    return {
      field: 'marginPercent',
      name: t.MarginRateColumn,
      getCellContent: item => <NumericFormatter value={item.marginPercent} precision={this.precision} />,
      sortable: true
    };
  }

  private get executionFeesFloorColumn(): LykkeGridColumn<Backoffice.ClientProfileSettings.ClientProfileSettingsContract> {
    const t = this.props.i18n.BrokerSettingsView.ProfilesParametersTab.ProfileSettings;
    return {
      field: 'executionFeesFloor',
      name: t.ExecutionFeesFloorColumn,
      getCellContent: item => <NumericFormatter value={item.executionFeesFloor} precision={this.precision} />,
      sortable: true
    };
  }

  private get executionFeesCapColumn(): LykkeGridColumn<Backoffice.ClientProfileSettings.ClientProfileSettingsContract> {
    const t = this.props.i18n.BrokerSettingsView.ProfilesParametersTab.ProfileSettings;
    return {
      field: 'executionFeesCap',
      name: t.ExecutionFeesCapColumn,
      getCellContent: item => <NumericFormatter value={item.executionFeesCap} precision={this.precision} />,
      sortable: true
    };
  }

  private get executionFeesRateColumn(): LykkeGridColumn<Backoffice.ClientProfileSettings.ClientProfileSettingsContract> {
    const t = this.props.i18n.BrokerSettingsView.ProfilesParametersTab.ProfileSettings;
    return {
      field: 'executionFeesRate',
      name: t.ExecutionFeesRateColumn,
      getCellContent: item => <NumericFormatter value={item.executionFeesRate} precision={this.precision} />,
      sortable: true
    };
  }

  private get financingFeesRateColumn(): LykkeGridColumn<Backoffice.ClientProfileSettings.ClientProfileSettingsContract> {
    const t = this.props.i18n.BrokerSettingsView.ProfilesParametersTab.ProfileSettings;
    return {
      field: 'financingFeesRate',
      name: t.FinancingFeesRateColumn,
      getCellContent: item => <NumericFormatter value={item.financingFeesRate} precision={this.precision} />,
      sortable: true
    };
  }

  private get onBehalfFeeColumn(): LykkeGridColumn<Backoffice.ClientProfileSettings.ClientProfileSettingsContract> {
    const t = this.props.i18n.BrokerSettingsView.ProfilesParametersTab.ProfileSettings;
    return {
      field: 'onBehalfFee',
      name: t.PhoneFeesColumn,
      getCellContent: item => <NumericFormatter value={item.onBehalfFee} precision={this.precision} />,
      sortable: true
    };
  }

  private get isAvailableColumn(): LykkeGridColumn<Backoffice.ClientProfileSettings.ClientProfileSettingsContract> {
    const t = this.props.i18n.BrokerSettingsView.ProfilesParametersTab.ProfileSettings;
    return {
      field: 'isAvailable',
      name: t.AvailableColumn,
      getCellContent: item => <input type='checkbox' className={Classes.CHECKBOX} checked={item.isAvailable} disabled={true} />,
      sortable: true
    };
  }

  private get actionsColumn(): LykkeGridColumn<Backoffice.ClientProfileSettings.ClientProfileSettingsContract> {
    const t = this.props.i18n.BrokerSettingsView.ProfilesParametersTab.ProfileSettings;
    return {
      field: 'actions' as VirtualField,
      name: t.ActionsColumn,
      fixed: 'right',
      initialWidth: 100,
      getCellContent: item =>
        <LykkeGridActionsCell>
          <Tooltip2 content={t.EditButtonLabel}>
            <AnchorButton intent={Intent.NONE} onClick={() => this.props.editClick(item.clientProfileId, item.assetTypeId)} icon={<Icon icon='edit' title={false} />}></AnchorButton>
          </Tooltip2>
        </LykkeGridActionsCell>
    };
  }

  private get columns() {
    return [
      this.clientProfileIdColumn,
      this.assetTypeIdColumn,
      this.marginPercentColumn,
      this.executionFeesFloorColumn,
      this.executionFeesCapColumn,
      this.executionFeesRateColumn,
      this.financingFeesRateColumn,
      this.onBehalfFeeColumn,
      this.isAvailableColumn,
      this.actionsColumn,
    ];
  }

  public render() {
    return (
      <LykkeGrid
        uniqueRowKey={record => `${record.clientProfileId}_${record.assetTypeId}`}
        columns={this.columns}
        filters={this.props.filters}
        sort={this.props.sort}
        onStateChange={this.props.onStateChange}
        loading={this.props.loading}
        rows={this.props.data}
      ></LykkeGrid>
    );
  }
}