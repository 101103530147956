import { OrderTabSettings, TabType } from '@models/TabSettings';
import TabSettingsGeneration from '@models/utils/TabSettingsGeneration';
import SharedValidation from './SharedValidation';
import { KnownModule } from '@models/enums/KnownModules';

export class OrdersValidation {

  public static validate(orderSettings: OrderTabSettings[] = []): OrderTabSettings[] {
    let [
      openOrderSettings = TabSettingsGeneration.generateOpenOrderSettings(),
      executedOrderSettings = TabSettingsGeneration.generateExecutedOrderSettings(),
      otherOrdersSettings = TabSettingsGeneration.generateOtherOrdersSettings(),
      ...customOrderSettings
    ] = orderSettings;

    openOrderSettings = OrdersValidation.sanitizeOpenOrder(openOrderSettings);
    executedOrderSettings = OrdersValidation.sanitizeExecutedOrder(executedOrderSettings);
    otherOrdersSettings = OrdersValidation.sanitizeOtherOrder(otherOrdersSettings);
    customOrderSettings = OrdersValidation.sanitizeCustomOrders(customOrderSettings);

    return [
      openOrderSettings,
      executedOrderSettings,
      otherOrdersSettings,
      ...customOrderSettings
    ];
  }

  private static sanitizeOpenOrder(openOrder: OrderTabSettings): OrderTabSettings {
    const errors = OrdersValidation.isValidOrder(openOrder);
    return errors.length === 0 ? openOrder : TabSettingsGeneration.generateOpenOrderSettings();
  }

  private static sanitizeExecutedOrder(executedOrder: OrderTabSettings): OrderTabSettings {
    const errors = OrdersValidation.isValidOrder(executedOrder);
    return errors.length === 0 ? executedOrder : TabSettingsGeneration.generateExecutedOrderSettings();
  }

  private static sanitizeOtherOrder(executedOrder: OrderTabSettings): OrderTabSettings {
    const errors = OrdersValidation.isValidOrder(executedOrder);
    return errors.length === 0 ? executedOrder : TabSettingsGeneration.generateOtherOrdersSettings();
  }

  private static sanitizeCustomOrders(customOrders: OrderTabSettings[] = []): OrderTabSettings[] {
    const validatedCustomOrders = customOrders.map((customOrder) => {
      const errors = OrdersValidation.isValidOrder(customOrder);
      return errors.length === 0 ? customOrder : null;
    }).filter(customOrder => !!customOrder); // remove null elements

    return validatedCustomOrders;
  }

  private static isValidOrder(orderSettings: OrderTabSettings): string[] {
    const errors = [];
    const moduleTypes = [KnownModule.OpenOrder, KnownModule.ExecutedOrder, KnownModule.OtherOrder];

    const {
      isOpen,
      id,
      name,
      tabType,
      hidden,
      created,
      modified,
      orderTypes,
      postingCategory,
      origin,
      deselectedColumns,
      columnOrder,
      columnSettings,
      instrument
    } = orderSettings;

    if (!SharedValidation.isString(id)) {
      errors.push('ID must be a string.');
    }

    if (!SharedValidation.isString(name)) {
      errors.push('Name must be a string.');
    }

    if (tabType !== TabType.Order) {
      errors.push(`Invalid TabType ${tabType}, must be ${TabType.Order}.`);
    }

    if (!SharedValidation.isBoolean(isOpen)) { // isOpen must be true
      errors.push('Property isOpen must be a boolean with a true value.');
    }

    if (!SharedValidation.isBoolean(hidden)) {
      errors.push('Property hidden must be a boolean.');
    }

    if (!SharedValidation.isTimeRange(modified)) {
      errors.push('Property modified must be of type TimeRange.');
    }

    if (!SharedValidation.isTimeRange(created)) {
      errors.push('Property created must be of type TimeRange.');
    }

    if (!SharedValidation.isObjectWithOnlyBooleanProperties(orderTypes)) {
      errors.push('Property orderTypes is invalid.');
    }

    if (!SharedValidation.isObjectWithOnlyBooleanProperties(postingCategory)) {
      errors.push('Property postingCategory is invalid.');
    }

    if (!SharedValidation.isObjectWithOnlyBooleanProperties(origin)) {
      errors.push('Property origin is invalid.');
    }

    if (!moduleTypes.includes(orderSettings.module)) {
      errors.push('Property module is invalid.');
    }

    if (!SharedValidation.isArrayOfStrings(deselectedColumns)) { // possible to improve this check with columnNames
      errors.push('Property deselectedColumns must be an array of strings.');
    }

    if (!SharedValidation.isArrayOfStrings(columnOrder)) {
      errors.push('ColumnOrder must be an array of strings.');
    }

    if (!SharedValidation.isString(instrument)) {
      errors.push('Property instrument must be a string.');
    }

    if (!SharedValidation.isObjectWithOnlyIntegerProperties(columnSettings)) {
      errors.push('Property columnSettings is invalid.');
    }

    return errors;
  }
}