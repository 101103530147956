/**
 * Clamps the given number between min and max values. Returns value if within
 * range, or closest bound.
 */
import { Keys } from '@blueprintjs/core/lib/cjs/common';

import { CLAMP_MIN_MAX as CLAMP_MIN_MAX_ERROR } from '../errors';

export function clamp(val: number, min: number, max: number): number {
    if (val == null) {
        return val;
    }
    if (max < min) {
        throw new Error(CLAMP_MIN_MAX_ERROR);
    }
    return Math.min(Math.max(val, min), max);
}

/** Returns whether the key code is `enter` or `space`, the two keys that can click a button. */
export function isKeyboardClick(keyCode: number): boolean {
    return keyCode === Keys.ENTER || keyCode === Keys.SPACE;
}
