import React, { Component } from 'react';
import * as Backoffice from '@client/backoffice/Dto';
import { LykkeGrid, LykkeGridColumn, VirtualField, LykkeGridActionsCell } from '@elements/lykke-html/LykkeGrid';
import { LocalizationService } from '@services/LocalizationService';
import { AnchorButton, Intent, Classes, Icon } from '@blueprintjs/core';
import { DateTimeFormatter } from '@elements/date-formatter/DateTimeFormatter';
import { toMoment } from '@scripts/utils/date-util';
import { NumericFormatter } from '@elements/number-formatter/NumericFormatter';
import styled from 'styled-components';
import { v4 } from 'uuid';
import { Tooltip2 } from '@blueprintjs/popover2';

const StyledBrokerDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledStatusDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDiv = styled.div`
  height: 100%;
  width: 100%;

  .ca-task-eligible {
    background-color: ${props => props.theme.corporateActionTaskEligibleBackgroundColor} !important;
    color: white;
  }

  .ca-task-non-eligible {
    background-color: ${props => props.theme.corporateActionTaskMaybeEligibleBackgroundColor} !important;
    color: white;
  }

  .ca-task-empty {
    background-color: ${props => props.theme.corporateActionTaskEmptyBackgroundColor} !important;
    color: white;
  }

  .ca-task-never-eligible {
    background-color: ${props => props.theme.corporateActionTaskNotEligibleBackgroundColor} !important;
    color: white;
  }

  .ca-task-edited {
    color: ${props => props.theme.corporateActionTaskImportedAndEditedColor};
  }

  .ca-task-amended {
    color: ${props => props.theme.corporateActionTaskAmendColor};
  }
`;

interface Props {
  sb: jc.Sandbox;
  data: Backoffice.CorporateActions.CorporateActionTaskContract[];
  errors: Backoffice.CorporateActions.CorporateActionBrokerErrorContract[];
  configs: Backoffice.CorporateActionTypes.CorporateActionTypeConfigContract[];
  loading: boolean;
  editClick?: (caTaskId: string) => void;
  amendClick?: (caTaskId: string) => void;
  closePositions?: (caTaskId: string) => void;
  onShowStatusReason: (item: Backoffice.CorporateActions.CorporateActionTaskContract) => void;
}

interface TaskExtended extends Backoffice.CorporateActions.CorporateActionTaskContract, Backoffice.CorporateActions.CorporateActionBrokerErrorContract {
}

export class CorporateActionTasksTable extends Component<Props, {}> {
  private readonly loc: LocalizationService;

  private get dataExtended(): TaskExtended[] {
    const defaultError: Backoffice.CorporateActions.CorporateActionBrokerError = 'None';
    const data = this.props.data.map(x => ({
      ...x,
      error: defaultError
    }));

    const defaultStatus: Backoffice.CorporateActions.CorporateActionTaskStatusContract = 'NonValidated';
    const errors = this.props.errors
      .filter(x => x.error !== 'None')
      .map(x => ({
        ...x,
        id: v4(),
        corporateActionId: '',
        typeId: '',
        name: '',
        status: defaultStatus,
        statusReason: '',
        eligible: null,
        paysDividend: null,
        overdueDividend: null,
        shouldDiscontinue: null,
        productId: '',
        marketPlace: '',
        mdsCode: '',
        edited: null,
        amended: null,
        isDeleted: null,
        isEmpty: null
      }));

    return [...data, ...errors];
  }

  constructor(props: Props) {
    super(props);
    this.loc = props.sb.getService('localization');
  }

  private get brokerIdColumn(): LykkeGridColumn<TaskExtended> {
    const t = this.loc.i18n.CorporateActions.Tasks;
    return {
      field: 'brokerId',
      name: t.BrokerIdColumn,
      getCellContent: item =>
        <StyledBrokerDiv>
          {item.brokerId}
          {
            item.error !== 'None' &&
            <>
              &nbsp;
              <Tooltip2 content={t.Errors[item.error]}>
                <AnchorButton
                  intent={Intent.DANGER}
                  icon={<Icon icon='error' title={false} />}
                />
              </Tooltip2>
            </>
          }
        </StyledBrokerDiv>,
      getCellClassName: item => this.getCellClassName(item, 'brokerId')
    };
  }

  private get productIdColumn(): LykkeGridColumn<TaskExtended> {
    const t = this.loc.i18n.CorporateActions.Tasks;
    return {
      field: 'productId',
      name: t.ProductIdColumn,
      getCellClassName: item => this.getCellClassName(item, 'productId')
    };
  }

  private get eligibleColumn(): LykkeGridColumn<TaskExtended> {
    const t = this.loc.i18n.CorporateActions.Tasks;
    return {
      field: 'eligible',
      name: t.EligibleColumn,
      getCellContent: item => item.error === 'None' && <input type='checkbox' className={Classes.CHECKBOX} checked={item.eligible} disabled={true} />,
      getCellClassName: item => this.getCellClassName(item, 'eligible')
    };
  }

  private get paysDividendColumn(): LykkeGridColumn<TaskExtended> {
    const t = this.loc.i18n.CorporateActions.Tasks;
    return {
      field: 'paysDividend',
      name: t.PaysDividendColumn,
      getCellContent: item => item.error === 'None' && <input type='checkbox' className={Classes.CHECKBOX} checked={item.paysDividend} disabled={true} />,
      getCellClassName: item => this.getCellClassName(item, 'paysDividend')
    };
  }

  private get freezeColumn(): LykkeGridColumn<TaskExtended> {
    const t = this.loc.i18n.CorporateActions.Tasks;
    return {
      field: 'freeze' as VirtualField,
      name: t.FreezeColumn,
      getCellContent: item => item.error === 'None' && (
        <>
          <DateTimeFormatter dateTime={toMoment(item.freezeStart)} format='Date' />-<DateTimeFormatter dateTime={toMoment(item.freezeEnd)} format='Date' />
        </>
      ),
      getCellClassName: item => this.getCellClassName(item, 'freezeStart')
    };
  }

  private get notificationDateColumn(): LykkeGridColumn<TaskExtended> {
    const t = this.loc.i18n.CorporateActions.Tasks;
    return {
      field: 'notificationDate',
      name: t.NotificationDateColumn,
      getCellContent: item => item.error === 'None' && <DateTimeFormatter dateTime={toMoment(item.notificationDate)} format='Date' />,
      getCellClassName: item => this.getCellClassName(item, 'notificationDate')
    };
  }

  private get positionCloseDateColumn(): LykkeGridColumn<TaskExtended> {
    const t = this.loc.i18n.CorporateActions.Tasks;
    return {
      field: 'positionCloseDate',
      name: t.PositionCloseDateColumn,
      getCellContent: item => item.error === 'None' && <DateTimeFormatter dateTime={toMoment(item.positionCloseDate)} format='Date' />,
      getCellClassName: item => this.getCellClassName(item, 'positionCloseDate')
    };
  }

  private get totalValueLongColumn(): LykkeGridColumn<TaskExtended> {
    const t = this.loc.i18n.CorporateActions.Tasks;
    return {
      field: 'totalValueLong',
      name: t.TotalValueLongColumn,
      getCellContent: item => item.error === 'None' && <NumericFormatter value={item.totalValueLong} precision={2} />,
      getCellClassName: item => this.getCellClassName(item, 'totalValueLong')
    };
  }

  private get totalValueShortColumn(): LykkeGridColumn<TaskExtended> {
    const t = this.loc.i18n.CorporateActions.Tasks;
    return {
      field: 'totalValueShort',
      name: t.TotalValueShortColumn,
      getCellContent: item => item.error === 'None' && <NumericFormatter value={item.totalValueShort} precision={2} />,
      getCellClassName: item => this.getCellClassName(item, 'totalValueShort')
    };
  }

  private get statusColumn(): LykkeGridColumn<TaskExtended> {
    const t = this.loc.i18n.CorporateActions.Tasks;
    return {
      field: 'status',
      name: t.StatusColumn,
      fixed: 'right',
      getCellContent: item => item.error === 'None' &&
        <StyledStatusDiv>
          {item.status}
          {
            item.statusReason &&
            <>
              &nbsp;
              <Tooltip2 content={t.StatusReasonButtonLabel}>
                <AnchorButton
                  intent={Intent.NONE}
                  icon={<Icon icon='eye-open' title={false} />}
                  onClick={() => this.props.onShowStatusReason(item)}
                />
              </Tooltip2>
            </>
          }
        </StyledStatusDiv>,
      getCellClassName: item => this.getCellClassName(item, 'status')
    };
  }

  private get closePositionsColumn(): LykkeGridColumn<TaskExtended> {
    const t = this.loc.i18n.CorporateActions.Tasks;
    return {
      field: 'actions' as VirtualField,
      name: t.ClosePositionsColumn,
      fixed: 'right',
      getCellContent: item => item.error === 'None' && (
        <LykkeGridActionsCell>
          {item.eligible && <Tooltip2 content={t.ClosePositionsButtonLabel}>
            <AnchorButton intent={Intent.NONE} onClick={() => this.props.closePositions(item.id)} icon={<Icon icon='cross' title={false} />}></AnchorButton>
          </Tooltip2>}
        </LykkeGridActionsCell>
      ),
      initialWidth: 120
    };
  }

  private get editColumn(): LykkeGridColumn<TaskExtended> {
    const t = this.loc.i18n.CorporateActions.Tasks;
    return {
      field: 'actions' as VirtualField,
      name: t.EditColumn,
      fixed: 'right',
      getCellContent: item => item.error === 'None' && (
        <LykkeGridActionsCell>
          <Tooltip2 content={t.EditButtonLabel}>
            <AnchorButton
              intent={Intent.NONE}
              onClick={() => this.props.editClick(item.id)}
              icon={<Icon icon='edit' title={false} />}
              disabled={!item.productId}
            />
          </Tooltip2>
        </LykkeGridActionsCell>
      ),
      initialWidth: 50
    };
  }

  private get amendColumn(): LykkeGridColumn<TaskExtended> {
    const t = this.loc.i18n.CorporateActions.Tasks;
    return {
      field: 'actions' as VirtualField,
      name: t.AmendColumn,
      fixed: 'right',
      getCellContent: item => item.error === 'None' && (
        <LykkeGridActionsCell>
          <Tooltip2 content={t.AmendButtonLabel}>
            <AnchorButton
              intent={Intent.NONE}
              onClick={() => this.props.amendClick(item.id)}
              icon={<Icon icon='edit' title={false} />}
              disabled={!item.productId}
            />
          </Tooltip2>
        </LykkeGridActionsCell>
      ),
      initialWidth: 70
    };
  }

  private get columns() {
    const result = [
      this.brokerIdColumn,
      this.eligibleColumn,
      this.paysDividendColumn,
      this.productIdColumn,
      this.freezeColumn,
      this.notificationDateColumn,
      this.positionCloseDateColumn,
      this.totalValueLongColumn,
      this.totalValueShortColumn,
      this.statusColumn
    ];
    if (this.props.editClick) {
      result.push(this.editColumn);
    }
    if (this.props.closePositions) {
      result.push(this.closePositionsColumn);
    }
    if (this.props.amendClick) {
      result.push(this.amendColumn);
    }
    return result;
  }

  public render() {
    return (
      <StyledDiv>
        <LykkeGrid
          uniqueRowKey='id'
          columns={this.columns}
          loading={this.props.loading}
          rows={this.dataExtended}
          deriveHeightFromContents={true}
        />
      </StyledDiv>
    );
  }

  private getCellClassName = (item: TaskExtended, field: keyof TaskExtended) => {
    const eligibleFields: Array<keyof TaskExtended> = ['eligible', 'freezeStart', 'freezeEnd'];
    const result = [];

    if (item.error !== 'None') {
      return result;
    }

    if (item.eligible) {
      if (eligibleFields.includes(field)) {
        result.push('ca-task-eligible');
      }
    } else {
      const config = this.props.configs.find(x => x.brokerId === item.brokerId);
      if (!config?.isEligibleFieldEditable) {
        result.push('ca-task-never-eligible');
      } else {
        const { isEmpty } = item;

        if (isEmpty) {
          result.push('ca-task-empty');
        } else if (eligibleFields.includes(field)) {
          result.push('ca-task-non-eligible');
        }
      }
    }

    const editAmendFieldsToExclude: Array<keyof TaskExtended> = ['status', 'freezeStart', 'freezeEnd', 'notificationDate', 'positionCloseDate', 'productId'];
    if (!editAmendFieldsToExclude.includes(field)) {
      if (this.props.editClick && item.edited) { result.push('ca-task-edited'); }
      if (this.props.amendClick && item.amended) { result.push('ca-task-amended'); }
    }
    return result;
  }
}