import { CostsAndChargesSettings, TabType } from '@models/TabSettings';
import TabSettingsGeneration from '@models/utils/TabSettingsGeneration';
import SharedValidation from './SharedValidation';
import { CostsAndChargesColumnNames } from '@scripts/features/messages/elements/cost-and-charges/CostsAndChargesColumn';

export class CostsAndChargesValidation {

  public static validate(costsAndChargesSettings: CostsAndChargesSettings[] = []): CostsAndChargesSettings[] {

    let [
      costAndCharges = TabSettingsGeneration.generateCostsAndChargesSettings(),
    ] = costsAndChargesSettings;

    costAndCharges = CostsAndChargesValidation.sanitizeCostsAndChargesSettings(costAndCharges);

    return [
      costAndCharges
    ];
  }

  private static sanitizeCostsAndChargesSettings(costAndCharges: CostsAndChargesSettings): CostsAndChargesSettings {
    const errors = CostsAndChargesValidation.isValidCostsAndCharges(costAndCharges);
    return errors.length === 0 ? costAndCharges : TabSettingsGeneration.generateCostsAndChargesSettings();
  }

  private static isValidCostsAndCharges(costsAndCharges: CostsAndChargesSettings): string[] {
    const errors = [];
    const columnNames = Object.keys(CostsAndChargesColumnNames).map(k => CostsAndChargesColumnNames[k]);

    const { id, name, tabType, hidden, created, assetName, deselectedColumns, columnOrder } = costsAndCharges;

    if (!SharedValidation.isString(id)) {
      errors.push('ID must be a string.');
    }

    if (!SharedValidation.isString(name)) {
      errors.push('Name must be a string.');
    }

    if (tabType !== TabType.CostsAndCharges) {
      errors.push(`Invalid TabType ${tabType}, must be ${TabType.CostsAndCharges}.`);
    }

    if (!SharedValidation.isBoolean(hidden)) {
      errors.push('Property hidden must be a boolean.');
    }

    if (!SharedValidation.isTimeRange(created))  {
      errors.push('Property created must be of type TimeRange.');
    }

    if (!costsAndCharges.hasOwnProperty('direction'))  {
      errors.push('Property direction must exist.');
    }

    if (!SharedValidation.isString(assetName)) {
      errors.push('Property assetName must be a string.');
    }

    if (!SharedValidation.isArrayOfStrings(deselectedColumns)) { // possible to improve this check with columnNames
      errors.push('Property deselectedColumns must be an array of strings.');
    }

    if (!SharedValidation.isArrayOfStrings(columnOrder) || columnNames.length !== columnOrder.length) {
      errors.push(`ColumnOrder must be an array of strings with ${columnNames.length} elements.`);
    }

    if (!costsAndCharges.hasOwnProperty('columnSettings'))  {
      errors.push('Property columnSettings must exist.');
    }

    return errors;
  }

}