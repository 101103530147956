import { ActivityTabSettings, TabType } from '@models/TabSettings';
import TabSettingsGeneration from '@models/utils/TabSettingsGeneration';
import SharedValidation from './SharedValidation';
import { ActivityColumnNames } from '@scripts/features/activities/interfaces/ActivityColumn';

export class ActivitiesValidation {

  public static validate(activities: ActivityTabSettings[] = []): ActivityTabSettings[] {
    let [
      activitiesSettings = TabSettingsGeneration.generateActivitiesSettings(),
      ...customActivities
    ] = activities;

    activitiesSettings = ActivitiesValidation.sanitizeActivitiesSettings(activitiesSettings);
    customActivities = ActivitiesValidation.sanitizeCustomActivitiesSettings(customActivities);

    return [
      activitiesSettings,
      ...customActivities
    ];
  }

  private static sanitizeActivitiesSettings(activitiesSettings: ActivityTabSettings): ActivityTabSettings {
    const errors = ActivitiesValidation.isValidActivity(activitiesSettings);
    return errors.length === 0 ? activitiesSettings : TabSettingsGeneration.generateActivitiesSettings();
  }

  private static sanitizeCustomActivitiesSettings(customActivities: ActivityTabSettings[] = []): ActivityTabSettings[] {
    const validatedCustomActivities = customActivities.map((customActivity) => {
      const errors = ActivitiesValidation.isValidActivity(customActivity);
      return errors.length === 0 ? customActivity : null;
    }).filter(customActivity => !!customActivity);

    return validatedCustomActivities;
  }

  private static isValidActivity(activity: ActivityTabSettings): string[] {
    const errors = [];
    const columnNames = Object.keys(ActivityColumnNames).map(k => ActivityColumnNames[k]);

    const { id, name, tabType, created, category, columnSettings, columnOrder, hidden, deselectedColumns, instrument, instrumentId } = activity;

    if (!SharedValidation.isString(id)) {
      errors.push('ID must be a string.');
    }

    if (!SharedValidation.isString(name)) {
      errors.push('Name must be a string.');
    }

    if (tabType !== TabType.Activity) {
      errors.push(`Invalid TabType ${tabType}, must be ${TabType.Activity}.`);
    }

    if (!SharedValidation.isTimeRange(created)) {
      errors.push('Property created must be of type TimeRange.');
    }

    if (!ActivitiesValidation.isValidCategory(category)) {
      errors.push('Category property is invalid.');
    }

    if (!ActivitiesValidation.isValidColumnSettings(columnSettings)) {
      errors.push('ColumnSettings property is invalid.');
    }

    if (!SharedValidation.isArrayOfStrings(columnOrder) || columnNames.length !== columnOrder.length) {
      errors.push(`ColumnOrder must be an array of strings with ${columnNames.length} elements.`);
    }

    if (!SharedValidation.isArrayOfStrings(deselectedColumns)) {
      errors.push('DeselectedColumns must be an array of strings.');
    }

    if (!SharedValidation.isString(instrument)) {
      errors.push('Property instrument must be a string.');
    }

    if (!!instrument && !instrumentId) {
      errors.push('Instrument id must be present.');
    }

    if (!SharedValidation.isBoolean(hidden)) {
      errors.push('Property hidden must be a boolean.');
    }

    return errors;
  }

  private static isValidCategory(category): boolean {
    if (!category || typeof category !== 'object') return false;

    const _keys = ['order', 'closeAll', 'position', 'marginControl', 'cancellationAdjustment', 'session', 'account', 'settings', 'priceAlert', 'cashMovement'];
    return _keys.every(x => SharedValidation.isBoolean(category[x]));
  }

  private static isValidColumnSettings(columnSettings): boolean {
    const _keys = Object.keys(columnSettings);
    const columnNames = Object.keys(ActivityColumnNames).map(k => ActivityColumnNames[k]);
    return _keys.length === columnNames.length && columnNames.every(x =>
      _keys.includes(x) && SharedValidation.isNumber(columnSettings[x])
    );
  }

}