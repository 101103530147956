const searchIcon = require<string>('@branding/img/instrument_search.svg');
const newsIcon = require<string>('@branding/img/news.svg');
const marketSentimentIcon = require<string>('@branding/img/market_sentiment.png');
const openOrdersIcon = require<string>('@branding/img/open_orders.svg');
const openPositionsIcon = require<string>('@branding/img/open_positions.svg');
const priceAlertsIcon = require<string>('@branding/img/bell_empty.png');
const priceAlertsDarkIcon = require<string>('@branding/img/bell_empty_dark.png');
const activitiesIcon = require<string>('@branding/img/trading_journal.svg');
const closedPositionsIcon = require<string>('@branding/img/closed_positions.svg');
const transactionsIcon = require<string>('@branding/img/cash_transactions.svg');
const profileIcon = require<string>('@branding/img/profile_icon.png');
const profileIconDark = require<string>('@branding/img/profile_icon_dark.png');
const manageUsersIcon = require<string>('@branding/img/manage_users.svg');

export const Branding = {
  title: 'Consors CFD Platform',
  titleOffline: 'Consors CFD Platform - Offline Mode',

  iconSize: 43,
  searchIcon,
  newsIcon,
  marketSentimentIcon,
  openOrdersIcon,
  openPositionsIcon,
  priceAlertsIcon,
  priceAlertsDarkIcon,
  activitiesIcon,
  closedPositionsIcon,
  transactionsIcon,
  profileIcon,
  profileIconDark,
  manageUsersIcon,

  primaryColor: '#0080A6',
  primaryColorDarkMode: '#2B3742',
  headerTextColor: '#000000',
  headerBackgroundColorDarkMode: '#333333',
  backgroundColorDarkMode: '#000000',
  borderColorDarkMode: '#333333',
  tabsBackgroundColorDarkMode: '#222222',
  chartBackgroundColor: '#222222',
  iconColor: '#0080A6',

  closeAllButtonColor: '#000000',
  closeAllButtonColorDarkMode: '#ffffff',
  closeAllButtonBackgroundColor: '#e8974c',
  closeAllButtonBackgroundColorDarkMode: '#06c2d4',

  /* Corporate actions */
  corporateActionTaskEligibleBackgroundColor: '#5da25d',
  corporateActionTaskMaybeEligibleBackgroundColor: '#d45f5f',
  corporateActionTaskEmptyBackgroundColor: '#333333',
  corporateActionTaskNotEligibleBackgroundColor: '#222222',
  corporateActionTaskImportedAndEditedColor: '#5da25d',
  corporateActionTaskSourceBackgroundColor: '#9f6ebf',
  corporateActionTaskManualColor: '#9f6ebf',
  corporateActionTaskAmendColor: '#5da25d',

  tradingIdeasDateColor: '#b2b8bf',
  tradingIdeasDateColorDarkMode: '#909090',
  tradingIdeasDisclaimerDividerColor: '#b2b8bf',
  tradingIdeasDisclaimerDividerColorDarkMode: '#909090',

  tradingJournalGridLinesColor: '#dfdfdf',
  tradingJournalGridLinesColorDarkMode: '#272727',

  spreadBackgroundColorDarkMode: '#909090',

  chartBackgroundColorDarkMode: '#111',

  sentiments: {
    long: '#1F487C',
    longDarkMode: '#0080A7',
    short: '#4E84A2',
    shortDarkMode: '#D44851',
  },

  layouts: {
    header: {
      backgroundColor: '#ededed',
      backgroundColorDarkMode: '#2a2a2a',

      itemColor: '#8c94a0',
      itemColorDarkMode: '#8c94a0',

      itemColorHover: '#3387a4',

      itemColorActive: '#0080a6',
      itemColorActiveDarkMode: '#0080a6',
    },
    menu: {
      listSeparatorColor: '#f5f6f7',
      listSeparatorColorDarkMode: '#333'
    }
  },

  submitWarningIconColor: '#0080a6',
  submitWarningIconColorDarkMode: '#0080a6'
};