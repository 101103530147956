import React from 'react';
import { Wrapper } from './styled';
import { BigFiguresQuoteProps } from './types';
import { inject, observer } from 'mobx-react';

const BigFiguresQuotePrice = inject('commonSettingsService', 'loc')(observer((props: BigFiguresQuoteProps) => {
  const { price, commonSettingsService, loc } = props;
  const { showBigFigures = false } = commonSettingsService.settings?.appearanceSettings || {};

  if (!showBigFigures || price === '--') {
    return <>{price}</>;
  }

  const [head, mid, tail] = breakQuotePrice(price, loc.decimalSeparator);

  return (
    <Wrapper>
      {head}
      <span className={'big-figures'}>{mid}</span>
      {tail}
    </Wrapper>
  );
}));

export default BigFiguresQuotePrice;

// Returns an array with 3 elements [head, mid, tail];
// head: the non-formatted part of the decimals before the big figures (includes integer part and decimal separator)
// mid: the big figures
// tail:  the non-formatted part of the decimals after the big figures
const breakQuotePrice = (quotePrice: string, decimalSeparator: string): string[] => {
  const [integer, decimals] = quotePrice.split(decimalSeparator);

  let head = '';
  let mid = '';
  let tail = '';

  if (decimals && decimals.length >= 4) { // 4 or more decimal places
    head = decimals.substring(0, 2);
    mid = decimals.substring(2, 4);
    tail = decimals.substring(4); // rest of the decimal string
  }

  if (decimals && decimals.length === 3) { // 3 decimal places
    const wholeNumber = parseInt(integer, 10); //
    if (wholeNumber < 50) { // 49.99 or less
      head = decimals.substring(0, 1);
      mid = decimals.substring(1);
    } else { // 50.00 or bigger
      mid = decimals.substring(0, 2);
      tail = decimals.substring(2);
    }
  }

  if (decimals && decimals.length < 3) { // for less than 3 decimal places do not highlight big figures
    head = decimals;
  }
  const showDecimalSeparator = head.length || mid.length || tail.length;
  return [integer + (showDecimalSeparator ? decimalSeparator + head : ''), mid, tail]; // e.g. ['123,45', '67', '89'] where 67 is the highlighted part on the UI
};