import { observable, action, computed } from 'mobx';
import { Side } from './Side';
import { Quote } from './Quote';
import CustomNumber from './customNumber';
import { HistoricalOrder } from './HistoricalOrder';
import moment from 'moment';
import { I18n } from '@elements/localization/I18n';
import { InstrumentDetails } from './InstrumentDetails';

export interface AdjustementTradeOptions {
  assetId: string;
  assetName: string;
  quantity: CustomNumber;
  direction: Side;
  forcedOpen:	boolean;
}
export interface CompensationOptions {
  amount: CustomNumber;
  reason: string;
}

export interface CancellationTradeTarget {
  tradeId:	string;
  assetId:	string;
  assetName: string;
  quantity:	number;
  price:	number;
  direction?:	Side;
  adjustmentTradeOptions?: AdjustementTradeOptions;
}

export enum DraftStatus {
  Pending = 0,
  Approved = 1,
  Rejected = 2
}

export enum TradeDraftExecutionStatus {
  Pending,
  Executing,
  Executed,
  Failed,
  Rejected
}

export class TradeDraft {
  private readonly i18n: I18n;

  public get compensationAmount() {
    if (this.compensationOptions !== undefined) {
      return this.compensationOptions.amount.Value;
    }
    return null;
  }

  public get IsQuantityValid(): boolean {
    return true;
  }

  public get isCancellation(): boolean {
    return this.cancellationTradeTarget !== undefined;
  }

  public get isAdjustment(): boolean {
    return this.cancellationTradeTarget !== undefined && this.cancellationTradeTarget.adjustmentTradeOptions !== undefined;
  }

  public get isCompensation(): boolean {
    return this.compensationOptions !== undefined;
  }

  public get isInstrumentChanged(): boolean {
    return this.cancellationTradeTarget !== undefined
    && this.cancellationTradeTarget.adjustmentTradeOptions !== undefined
    && this.cancellationTradeTarget.assetId !== this.cancellationTradeTarget.adjustmentTradeOptions.assetId;
  }

  @computed
  public get isQuantityValid(): boolean {
    return this.cancellationTradeTarget.adjustmentTradeOptions.quantity.Value >= this.instrumentDetails.minOrderSize
    && this.cancellationTradeTarget.adjustmentTradeOptions.quantity.Value <= this.instrumentDetails.maxOrderSize;
  }

  @computed
  public get isValidatorComentValid() {
    return this.tempUpdatedByComment !== '' && this.tempUpdatedByComment !== null;
  }

  @computed
  public get isCompensationValid(): boolean {
    return this.isCompensationAmountValid && this.isCompensationReasonValid;
  }

  @computed
  public get isCompensationReasonValid() {
    return this.compensationOptions.reason !== '' && this.compensationOptions.reason !== null;
  }

  @computed
  public get isCompensationAmountValid() {
    return this.compensationOptions.amount.Value !== 0 && this.compensationOptions.amount.Value !== null;
  }

  @computed
  public get price() {
    return this.cancellationTradeTarget.adjustmentTradeOptions.direction === Side.Buy ? this.quote.ask : this.quote.bid;
  }

  public static getDraftColour(status: DraftStatus): string {
    switch (status) {
      case (DraftStatus.Approved): return 'green';
      case (DraftStatus.Pending): return 'blue';
      case (DraftStatus.Rejected): return 'orange';
    }
  }

  public static getTradeColour(status: TradeDraftExecutionStatus): string {
    switch (status) {
      case (TradeDraftExecutionStatus.Executed): return 'green';
      case (TradeDraftExecutionStatus.Pending): return 'blue';
      case (TradeDraftExecutionStatus.Failed): return 'red';
      case (TradeDraftExecutionStatus.Rejected): return 'orange';
    }
  }

  public oid: number;
  public id: string;
  public accountId: string;
  public createdBy: string;
  @observable public createdByComments: string;
  public creationTimestamp: moment.Moment;
  @observable public updatedBy: string;
  @observable public updatedByComments: string;
  @observable public tempUpdatedByComment: string;
  @observable public updatedTimestamp: moment.Moment;
  @observable public cancellationTradeTarget: CancellationTradeTarget;
  @observable public compensationOptions: CompensationOptions;
  @observable public quote?: Quote;
  @observable public instrumentDetails?: InstrumentDetails;
  @observable public compensationStatus: TradeDraftExecutionStatus;
  @observable public draftStatus: DraftStatus;
  @observable public cancellationTradeStatus: TradeDraftExecutionStatus;
  @observable public adjustmentTradeStatus: TradeDraftExecutionStatus;
  @observable public isStatus: boolean;
  @observable public brokerId: string;
  public isApprove: boolean;

  @computed
  public get instrumentWrongTrade() {
    return this.cancellationTradeTarget !== undefined
      ? this.cancellationTradeTarget.assetName
      : null;
  }

  @computed
  public get instrumentAdjustmentTrade() {
    return this.cancellationTradeTarget !== undefined && this.cancellationTradeTarget.adjustmentTradeOptions !== undefined
      ? this.cancellationTradeTarget.adjustmentTradeOptions.assetName
      : null;
  }

  @computed
  public get tradeToBeCanceled() {
    return this.cancellationTradeTarget !== undefined
      ? this.cancellationTradeTarget.tradeId
      : null;
  }

  @computed
  public get currentStatus() {
    return this.i18n.AdjustTradesGrid.DraftStatus[DraftStatus[this.draftStatus]];
  }

  constructor(i18n: I18n) {
    this.i18n = i18n;
    this.tempUpdatedByComment = '';
    this.isStatus = false;
  }

  public getCustomContent(column: string) {
    if (column === 'tradeToBeCanceled') {
      return this.cancellationTradeTarget !== undefined ? this.cancellationTradeTarget.tradeId : undefined;
    } else {
      return this[column];
    }
  }

  @action
  public initialize(tradeDraft: TradeDraft, quote?: Quote ) {
    this.oid = tradeDraft.oid;
    this.id = tradeDraft.id;
    this.accountId = tradeDraft.accountId;
    if (tradeDraft.cancellationTradeTarget) {
      this.cancellationTradeTarget = {
        tradeId:	null,
        assetId:	null,
        assetName: null,
        quantity:	null,
        price:	null,
        direction:	null,
      };
      this.initializeCancellationTradeTarget(tradeDraft.cancellationTradeTarget);
    }
    this.createdBy = tradeDraft.createdBy;
    this.createdByComments = tradeDraft.createdByComments;
    this.updatedBy = tradeDraft.updatedBy;
    this.updatedByComments = tradeDraft.updatedByComments;
    this.updatedTimestamp = moment(tradeDraft.updatedTimestamp);
    this.creationTimestamp = moment(tradeDraft.creationTimestamp);
    if (tradeDraft.compensationOptions) {
      this.compensationOptions = {
        amount: null,
        reason: null
      };
      this.initializeCompensationOptions(tradeDraft.compensationOptions);
    }
    this.compensationStatus = tradeDraft.compensationStatus;
    this.draftStatus = tradeDraft.draftStatus;
    this.cancellationTradeStatus = tradeDraft.cancellationTradeStatus;
    this.adjustmentTradeStatus = tradeDraft.adjustmentTradeStatus;
    this.quote = quote;
    this.isApprove = true;
    this.brokerId = tradeDraft.brokerId;
  }

  @action
  public initializeCompensationOptions(value: CompensationOptions) {
    this.compensationOptions.amount = value.amount;
    this.compensationOptions.reason = value.reason;
  }
  @action
  public initializeCancellationTradeTarget(value: CancellationTradeTarget) {
    this.cancellationTradeTarget.tradeId =	value.tradeId;
    this.cancellationTradeTarget.assetId =	value.assetId;
    this.cancellationTradeTarget.assetName = value.assetName;
    this.cancellationTradeTarget.quantity =	value.quantity;
    this.cancellationTradeTarget.price =	value.price;
    this.cancellationTradeTarget.direction = value.direction;
    if (value.adjustmentTradeOptions) {
      this.cancellationTradeTarget.adjustmentTradeOptions = {
        assetId: null,
        assetName: null,
        quantity: null,
        direction: null,
        forcedOpen:	null,
      };
      this.initializeAdjustmentOptions(value.adjustmentTradeOptions);
    }
  }

  @action
  public initializeAdjustmentOptions(value: AdjustementTradeOptions) {
    this.cancellationTradeTarget.adjustmentTradeOptions.assetId = value.assetId;
    this.cancellationTradeTarget.adjustmentTradeOptions.assetName = value.assetName;
    this.cancellationTradeTarget.adjustmentTradeOptions.quantity = value.quantity;
    this.cancellationTradeTarget.adjustmentTradeOptions.direction = value.direction;
    this.cancellationTradeTarget.adjustmentTradeOptions.forcedOpen =	value.forcedOpen;
  }

  @action
  public initializeFromOrder(
    order: HistoricalOrder,
    instrumentDetails: InstrumentDetails,
    precision: number) {
      this.accountId = order.accountId;
      this.instrumentDetails = instrumentDetails;
      this.quote = order.quote;
      this.cancellationTradeTarget = {
        tradeId:	order.id,
        assetId:	order.symbol,
        assetName: order.quote.name,
        quantity:	order.quantity.Value,
        price:	order.executionPrice.Value,
        direction: Side[order.direction.name],
        adjustmentTradeOptions: null,
      };
      this.cancellationTradeTarget.adjustmentTradeOptions = {
        assetId: order.quote.symbol,
        assetName: order.quote.name,
        quantity: new CustomNumber(Math.abs(order.quantity.Value), 0),
        direction: Side[order.direction.name],
        forcedOpen: order.forcedOpen,
      };
      this.compensationOptions = {
        amount: new CustomNumber(null, precision),
        reason: ''
      };
      this.createdByComments = '';
      this.isApprove = false;
  }
}
