import { PositionTabSettings, TabType } from '@models/TabSettings';
import TabSettingsGeneration from '@models/utils/TabSettingsGeneration';
import SharedValidation from './SharedValidation';
import { OpenPositionsColumnNames } from '@scripts/features/open-positions/interfaces/OpenPositionsColumn';
import { ClosedPositionsColumnNames } from '@scripts/features/closed-positions/interfaces/ClosedPositionsColumn';

export class PositionsValidation {

  public static validate(positions: PositionTabSettings[] = []): PositionTabSettings[] {
    let [
      openPositionsSettings = TabSettingsGeneration.generateOpenPositionsSettings(),
      closedPositionsSettings = TabSettingsGeneration.generateClosedPositionsSettings(),
      ...customPositionSettings
    ] = positions;

    openPositionsSettings = PositionsValidation.sanitizeOpenPosition(openPositionsSettings);
    closedPositionsSettings = PositionsValidation.sanitizeClosedPosition(closedPositionsSettings);
    customPositionSettings = PositionsValidation.sanitizeCustomPositions(customPositionSettings);

    return [
      openPositionsSettings,
      closedPositionsSettings,
      ...customPositionSettings
    ];
  }

  private static sanitizeOpenPosition(openPosition: PositionTabSettings): PositionTabSettings {
    const errors = PositionsValidation.isValidOpenPosition(openPosition);
    return errors.length === 0 ? openPosition : TabSettingsGeneration.generateOpenPositionsSettings();
  }

  private static sanitizeClosedPosition(closedPosition: PositionTabSettings): PositionTabSettings {
    const errors = PositionsValidation.isValidClosedPosition(closedPosition);
    return errors.length === 0 ? closedPosition : TabSettingsGeneration.generateClosedPositionsSettings();
  }

  private static sanitizeCustomPositions(customPositions: PositionTabSettings[] = []): PositionTabSettings[] {
    const validatedCustomPositions = customPositions.map((customPosition) => {
      const errors = customPosition.isOpen ? PositionsValidation.isValidOpenPosition(customPosition) : PositionsValidation.isValidClosedPosition(customPosition);
      return errors.length === 0 ? customPosition : null;
    }).filter(customPosition => !!customPosition);

    return validatedCustomPositions;
  }

  private static isValidOpenPosition(openPosition: PositionTabSettings): string[] {
    const errors = [];
    const columnNames = Object.keys(OpenPositionsColumnNames).map(k => OpenPositionsColumnNames[k]);

    const { id, isOpen, tabType, hidden, name, instrument, aggregate, deselectedColumns, modified, columnOrder, columnSettings } = openPosition;

    if (!SharedValidation.isString(id)) {
      errors.push('ID must be a string.');
    }

    if (!SharedValidation.isString(name)) {
      errors.push('Name must be a string.');
    }

    if (tabType !== TabType.Position) {
      errors.push(`Invalid TabType ${tabType}, must be ${TabType.Position}.`);
    }

    if (!SharedValidation.isBoolean(isOpen) && isOpen) { // isOpen must be true
      errors.push('Property isOpen must be a boolean with a true value.');
    }

    if (!SharedValidation.isBoolean(hidden)) {
      errors.push('Property hidden must be a boolean.');
    }

    if (!SharedValidation.isBoolean(aggregate)) {
      errors.push('Property aggregate must be a boolean.');
    }

    if (!SharedValidation.isString(instrument)) {
      errors.push('Property instrument must be a string.');
    }

    if (!openPosition.hasOwnProperty('positionTypes')) {
      errors.push('Property positionTypes is invalid.');
    }

    if (!openPosition.hasOwnProperty('direction')) {
      errors.push('Property direction is invalid.');
    }

    if (!SharedValidation.isTimeRange(modified)) {
      errors.push('Property modified must be of type TimeRange.');
    }

    if (!SharedValidation.isArrayOfStrings(deselectedColumns)) { // possible to improve this check with columnNames
      errors.push('Property deselectedColumns must be an array of strings.');
    }

    if (!PositionsValidation.isValidOpenPositionColumnSettings(columnSettings)) {
      errors.push('ColumnSettings property is invalid.');
    }

    if (!SharedValidation.isArrayOfStrings(columnOrder) || columnNames.length !== columnOrder.length) {
      errors.push(`ColumnOrder must be an array of strings with ${columnNames.length} elements.`);
    }

    return errors;
  }

  private static isValidClosedPosition(closedPosition: PositionTabSettings): string[] {
    const errors = [];
    const columnNames = Object.keys(ClosedPositionsColumnNames).map(k => ClosedPositionsColumnNames[k]);

    const { id, isOpen, tabType, hidden, name, aggregate, instrument, created, deselectedColumns, columnOrder, columnSettings } = closedPosition;

    if (!SharedValidation.isString(id)) {
      errors.push('ID must be a string.');
    }

    if (!SharedValidation.isString(name)) {
      errors.push('Name must be a string.');
    }

    if (tabType !== TabType.Position) {
      errors.push(`Invalid TabType ${tabType}, must be ${TabType.Position}.`);
    }

    if (!SharedValidation.isBoolean(isOpen) && !isOpen) { // isOpen must be true
      errors.push('Property isOpen must be a boolean with a true value.');
    }

    if (!SharedValidation.isBoolean(hidden)) {
      errors.push('Property hidden must be a boolean.');
    }

    if (!SharedValidation.isBoolean(aggregate)) {
      errors.push('Property aggregate must be a boolean.');
    }

    if (!closedPosition.hasOwnProperty('positionTypes')) {
      errors.push('Property positionTypes is invalid.');
    }

    if (!SharedValidation.isString(instrument)) {
      errors.push('Property instrument must be a string.');
    }

    if (!SharedValidation.isTimeRange(created)) {
      errors.push('Property created must be of type TimeRange.');
    }

    if (!SharedValidation.isArrayOfStrings(deselectedColumns)) { // possible to improve this check with columnNames
      errors.push('Property deselectedColumns must be an array of strings.');
    }

    if (!PositionsValidation.isValidClosedPositionColumnSettings(columnSettings)) {
      errors.push('ColumnSettings property is invalid.');
    }

    if (!SharedValidation.isArrayOfStrings(columnOrder) || columnNames.length !== columnOrder.length) {
      errors.push(`ColumnOrder must be an array of strings with ${columnNames.length} elements.`);
    }

    return errors;
  }

  private static isValidOpenPositionColumnSettings(columnSettings: object): boolean {
    const _keys = Object.keys(columnSettings);
    const columnNames = Object.keys(OpenPositionsColumnNames).map(k => OpenPositionsColumnNames[k]);
    const areEqual = _keys.length === columnNames.length && columnNames.every(x => {
      return _keys.includes(x) && SharedValidation.isNumber(columnSettings[x]);
    });

    return areEqual;
  }

  private static isValidClosedPositionColumnSettings(columnSettings: object): boolean {
    const _keys = Object.keys(columnSettings);
    const columnNames = Object.keys(ClosedPositionsColumnNames).map(k => ClosedPositionsColumnNames[k]);
    const areEqual = _keys.length === columnNames.length && columnNames.every(x => {
      return _keys.includes(x) && SharedValidation.isNumber(columnSettings[x]);
    });

    return areEqual;
  }
}
