import { PositionsValidation } from './PositionsValidation';
import { OrdersValidation } from './OrdersValidation';
import { NewsValidation } from './NewsValidation';
import { PriceAlertsValidation } from './PriceAlertsValidation';
import { ActivitiesValidation } from './ActivitiesValidation';
import { CashMovementsValidation } from './CashMovementsValidation';
import { CreditRequestsValidation } from './CreditRequestsValidation';
import { AdjustDraftsValidation } from './AdjustDraftsValidation';
import { CostsAndChargesValidation } from './CostsAndChargesValidation';

export default {
  Positions: PositionsValidation,
  Orders: OrdersValidation,
  News: NewsValidation,
  PriceAlerts: PriceAlertsValidation,
  Activities: ActivitiesValidation,
  CashMovement: CashMovementsValidation,
  CreditRequests: CreditRequestsValidation,
  AdjustDraft: AdjustDraftsValidation,
  CostsAndCharges: CostsAndChargesValidation
};