import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { ILocalizedProps } from '@elements/localization';
import * as Backoffice from '@client/backoffice/Dto';
import { LykkeGrid, LykkeGridColumn, FilterDescriptor, SortDescriptor, LykkeGridContext, LykkeGridActionsCell, VirtualField } from '@elements/lykke-html/LykkeGrid';
import { Intent, AnchorButton, Icon } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';

interface Props extends ILocalizedProps {
  data: Backoffice.RegulatoryProfile.RegulatoryProfileContract[];
  loading: boolean;
  filters: Array<FilterDescriptor<Backoffice.RegulatoryProfile.RegulatoryProfileContract>>;
  sort: Array<SortDescriptor<Backoffice.RegulatoryProfile.RegulatoryProfileContract>>;
  onStateChange?(changeContext: LykkeGridContext<Backoffice.RegulatoryProfile.RegulatoryProfileContract>): void;
  duplicateClick: (regulatoryProfileId: string) => void;
  deleteClick: (regulatoryProfileId: string) => void;
}

@inject('i18n')
export class RegulatoryProfilesTable extends Component<Props, {}> {
  private get regulatoryProfileIdColumn(): LykkeGridColumn<Backoffice.RegulatoryProfile.RegulatoryProfileContract> {
    const t = this.props.i18n.RegulationRules.RegulatoryProfiles;
    return {
      field: 'id',
      name: t.RegulatoryProfileIdColumn,
      sortable: true,
      filterable: true,
      initialWidth: 400,
    };
  }

  private get actionsColumn(): LykkeGridColumn<Backoffice.RegulatoryProfile.RegulatoryProfileContract> {
    const t = this.props.i18n.RegulationRules.RegulatoryProfiles;
    return {
      field: 'actions' as VirtualField,
      name: t.ActionsColumn,
      fixed: 'right',
      initialWidth: 100,
      getCellContent: item =>
        <LykkeGridActionsCell>
          <Tooltip2 content={t.DuplicateButtonLabel}>
            <AnchorButton intent={Intent.NONE} onClick={() => this.props.duplicateClick(item.id)} icon={<Icon icon='duplicate' title={false} />}></AnchorButton>
          </Tooltip2>

          <Tooltip2 content={t.DeleteButtonLabel}>
            <AnchorButton intent={Intent.DANGER} disabled={item.isDefault} onClick={() => this.props.deleteClick(item.id)} icon={<Icon icon='trash' title={false} />}></AnchorButton>
          </Tooltip2>
        </LykkeGridActionsCell>
    };
  }

  private get columns() {
    return [
      this.regulatoryProfileIdColumn,
      this.actionsColumn,
    ];
  }

  public render() {
    return (
      <LykkeGrid
        uniqueRowKey='id'
        columns={this.columns}
        filters={this.props.filters}
        sort={this.props.sort}
        onStateChange={this.props.onStateChange}
        loading={this.props.loading}
        rows={this.props.data}
      ></LykkeGrid>
    );
  }
}