import { LocalizedStore } from '@elements/localization';
import { action, observable, flow, computed } from 'mobx';
import * as Backoffice from '@client/backoffice/Dto';
import type { CancellablePromise } from 'mobx/lib/api/flow';
import { SortDescriptor, LykkeGridContext } from '@elements/lykke-html/LykkeGrid';
import { sortGridData, filterGridData, ExtendedFilterDescriptor } from '@elements/lykke-html/LykkeGridData';
import { instanceOfIAjaxResponse } from '@services/network/AjaxService';
import { FrontendBadRequest, isBackofficeFrontendBadRequest } from '@client/backoffice/CustomErrors';
import { ClientProfileSettingsClient } from '@client/backoffice/ClientProfileSettings';
import { RegulatorySettingsClient } from '@client/backoffice/RegulatorySettingsClient';
import { BrokerSettingsClient } from '@client/backoffice/BrokerSettingsClient';

export class ProfileSettingsStore extends LocalizedStore {
  private readonly clientProfileSettingsClient: ClientProfileSettingsClient;
  private readonly brokerSettingsClient: BrokerSettingsClient;
  private readonly regulatorySettingsClient: RegulatorySettingsClient;

  private readonly brokerId: string;

  @observable public profileSettings: Backoffice.ClientProfileSettings.ClientProfileSettingsContract[] = [];
  @observable public isLoadingFetch: boolean = false;
  @observable public errorsFetch: FrontendBadRequest;

  @observable public isLoadingUpdate: boolean = false;
  @observable public errorsUpdate: FrontendBadRequest;

  @observable public regulatorySettings: Backoffice.RegulatorySettings.RegulatorySettingsContract[] = [];
  @observable public isLoadingRegulatorySettingsFetch: boolean = false;
  @observable public errorsRegulatorySettingsFetch: FrontendBadRequest;

  @observable public filters: Array<ExtendedFilterDescriptor<Backoffice.ClientProfileSettings.ClientProfileSettingsContract>> = [
    { type: 'text', field: 'clientProfileId', operator: 'contains', value: null, ignoreCase: true },
    { type: 'text', field: 'assetTypeId', operator: 'contains', value: null, ignoreCase: true },
  ];
  @observable public sort: Array<SortDescriptor<Backoffice.ClientProfileSettings.ClientProfileSettingsContract>> = [];

  @computed
  public get filteredAndSortedProfileSettings() {
    return sortGridData(filterGridData(this.profileSettings, this.filters), this.sort);
  }

  constructor(sandbox: jc.Sandbox, brokerId: string) {
    super(sandbox);
    this.clientProfileSettingsClient = sandbox.getService('client/backoffice/client-profile-settings');
    this.brokerSettingsClient = sandbox.getService('client/backoffice/broker-settings');
    this.regulatorySettingsClient = sandbox.getService('client/backoffice/regulatory-settings');
    this.brokerId = brokerId;
  }

  public fetchProfileSettings: () => CancellablePromise<any> =
  flow(function* fetchProfileSettings(this: ProfileSettingsStore) {
    this.isLoadingFetch = true;
    try {
      const data: Backoffice.ClientProfileSettings.GetAllClientProfileSettingsResponse = yield this.clientProfileSettingsClient.getClientProfileSettingsAsync({
        brokerId: this.brokerId
      });
      this.profileSettings = data.clientProfileSettings;
      this.errorsFetch = undefined;
    } catch (error) {
      if (instanceOfIAjaxResponse(error)) {
        const errorData = JSON.parse(error.data);
        if (isBackofficeFrontendBadRequest(errorData)) {
          this.errorsFetch = errorData;
        }
      }
      throw error;
    } finally {
      this.isLoadingFetch = false;
    }
  });

  public updateProfileSetting: (profileId: string, typeId: string, request: Backoffice.ClientProfileSettings.UpdateClientProfileSettingsRequest) => CancellablePromise<any> =
  flow(function* updateProfileSetting(this: ProfileSettingsStore, profileId: string, typeId: string, request: Backoffice.ClientProfileSettings.UpdateClientProfileSettingsRequest) {
    this.isLoadingUpdate = true;
    try {
      yield this.clientProfileSettingsClient.updateClientProfileSettingsAsync(request, profileId, typeId);
      this.profileSettings = this.profileSettings
        .map(c => c.clientProfileId === profileId && c.assetTypeId === typeId
          ? {
            clientProfileId: profileId,
            assetTypeId: typeId,
            marginPercent: request.marginPercent,
            executionFeesFloor: request.executionFeesFloor,
            executionFeesCap: request.executionFeesCap,
            executionFeesRate: request.executionFeesRate,
            financingFeesRate: request.financingFeesRate,
            onBehalfFee: request.onBehalfFee,
            isAvailable: request.isAvailable
          }
          : c);
      this.errorsUpdate = undefined;
    } catch (error) {
      if (instanceOfIAjaxResponse(error)) {
        const errorData = JSON.parse(error.data);
        if (isBackofficeFrontendBadRequest(errorData)) {
          this.errorsUpdate = errorData;
        }
      }
      throw error;
    } finally {
      this.isLoadingUpdate = false;
    }
  });

  @action.bound
  public updateFilterAndSortState(changeContext: LykkeGridContext<Backoffice.ClientProfileSettings.ClientProfileSettingsContract>) {
    this.filters = changeContext.filters as any; // LykkeGridContext would proxy any extensions we pass to its filters
    this.sort = changeContext.sort;
  }

  public fetchRegulatoryProfileSettings: () => CancellablePromise<any> =
  flow(function* fetchRegulatoryProfileSettings(this: ProfileSettingsStore) {
    this.isLoadingRegulatorySettingsFetch = true;
    try {
      const brokerSettings: Backoffice.BrokerSettings.BrokerSettingsContract = yield this.brokerSettingsClient.getByIdAsync(this.brokerId);
      const regulatorySettingsResponse: Backoffice.RegulatorySettings.GetRegulatorySettingsResponse = yield this.regulatorySettingsClient
        .getRegulatorySettingsAsync(brokerSettings.regulationId);
      this.regulatorySettings = regulatorySettingsResponse.regulatorySettings;
      this.errorsRegulatorySettingsFetch = undefined;
    } catch (error) {
      if (instanceOfIAjaxResponse(error)) {
        const errorData = JSON.parse(error.data);
        if (isBackofficeFrontendBadRequest(errorData)) {
          this.errorsRegulatorySettingsFetch = errorData;
        }
      }
      throw error;
    } finally {
      this.isLoadingRegulatorySettingsFetch = false;
    }
  });
}