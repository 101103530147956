import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { ILocalizedProps } from '@elements/localization';
import * as Backoffice from '@client/backoffice/Dto';
import { LykkeGrid, LykkeGridColumn, FilterDescriptor, SortDescriptor, LykkeGridContext, LykkeGridActionsCell, VirtualField } from '@elements/lykke-html/LykkeGrid';
import { Intent, Classes, AnchorButton, Icon } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';

interface Props extends ILocalizedProps {
  data: Backoffice.ClientProfiles.ClientProfileContract[];
  loading: boolean;
  filters: Array<FilterDescriptor<Backoffice.ClientProfiles.ClientProfileContract>>;
  sort: Array<SortDescriptor<Backoffice.ClientProfiles.ClientProfileContract>>;
  onStateChange?(changeContext: LykkeGridContext<Backoffice.ClientProfiles.ClientProfileContract>): void;
  editClick: (profileId: string) => void;
  duplicateClick: (profileId: string) => void;
  deleteClick: (profileId: string) => void;
}

@inject('i18n')
export class ProfilesTable extends Component<Props, {}> {
  private get profileIdColumn(): LykkeGridColumn<Backoffice.ClientProfiles.ClientProfileContract> {
    const t = this.props.i18n.BrokerSettingsView.ProfilesParametersTab.Profiles;
    return {
      field: 'id',
      name: t.ProfileIdColumn,
      sortable: true,
      filterable: true,
      initialWidth: 250,
    };
  }

  private get regulatoryProfileIdColumn(): LykkeGridColumn<Backoffice.ClientProfiles.ClientProfileContract> {
    const t = this.props.i18n.BrokerSettingsView.ProfilesParametersTab.Profiles;
    return {
      field: 'regulatoryProfileId',
      name: t.RegulatoryProfileColumn,
      sortable: true,
      filterable: true,
      initialWidth: 250,
    };
  }

  private get isDefaultColumn(): LykkeGridColumn<Backoffice.ClientProfiles.ClientProfileContract> {
    const t = this.props.i18n.BrokerSettingsView.ProfilesParametersTab.Profiles;
    return {
      field: 'isDefault',
      name: t.DefaultColumn,
      getCellContent: item => <input type='checkbox' className={Classes.CHECKBOX} checked={item.isDefault} disabled={true} />,
      sortable: true,
      initialWidth: 120,
    };
  }

  private get actionsColumn(): LykkeGridColumn<Backoffice.ClientProfiles.ClientProfileContract> {
    const t = this.props.i18n.BrokerSettingsView.ProfilesParametersTab.Profiles;
    return {
      field: 'actions' as VirtualField,
      name: t.ActionsColumn,
      fixed: 'right',
      initialWidth: 120,
      getCellContent: item => // For some reason Tooltip flickers on this table, so we explicitly set placement='left'
        <LykkeGridActionsCell>
          <Tooltip2 content={t.EditButtonLabel} placement='left'>
            <AnchorButton intent={Intent.NONE} onClick={() => this.props.editClick(item.id)} icon={<Icon icon='edit' title={false} />}></AnchorButton>
          </Tooltip2>

          <Tooltip2 content={t.DuplicateButtonLabel} placement='left'>
            <AnchorButton intent={Intent.NONE} onClick={() => this.props.duplicateClick(item.id)} icon={<Icon icon='duplicate' title={false} />}></AnchorButton>
          </Tooltip2>

          <Tooltip2 content={t.DeleteButtonLabel} placement='left'>
            <AnchorButton intent={Intent.DANGER} disabled={item.isDefault} onClick={() => this.props.deleteClick(item.id)} icon={<Icon icon='trash' title={false} />}></AnchorButton>
          </Tooltip2>
        </LykkeGridActionsCell>
    };
  }

  private get columns() {
    return [
      this.profileIdColumn,
      this.regulatoryProfileIdColumn,
      this.isDefaultColumn,
      this.actionsColumn,
    ];
  }

  public render() {
    return (
      <LykkeGrid
        uniqueRowKey='id'
        columns={this.columns}
        filters={this.props.filters}
        sort={this.props.sort}
        onStateChange={this.props.onStateChange}
        loading={this.props.loading}
        rows={this.props.data}
      ></LykkeGrid>
    );
  }
}