import { TimeRange } from '@models/TabSettings';

export default class SharedValidation {

  public static isString(id: string) {
    return typeof id === 'string';
  }

  public static isBoolean(bool: boolean) {
    return typeof bool === 'boolean';
  }

  public static isNumber(integer: number) {
    return typeof integer === 'number';
  }

  public static isTimeRange(timeRange: TimeRange) {
    if (!timeRange || typeof timeRange !== 'object') return false;

    const hasStartProperty = timeRange.hasOwnProperty('start');
    const hasEndProperty = timeRange.hasOwnProperty('end');
    // const hasPeriodProperty = timeRange.hasOwnProperty('period');

    return hasStartProperty && hasEndProperty;
  }

  // Check if all properties of an object are booleans e.g. { "prop1": true, "prop2": false };
  public static isObjectWithOnlyBooleanProperties(obj: any) {
    const _keys = Object.keys(obj);
    let isValid = true;
    _keys.forEach(key => {
      const value = obj[key];
      if (!SharedValidation.isBoolean(value)) {
        isValid = false;
      }
    });

    return isValid;
  }

  // Check if all properties of an object are integers e.g. { "prop1": 1, "prop2": 2 };
  public static isObjectWithOnlyIntegerProperties(obj: any) {
    const _keys = Object.keys(obj);
    let isValid = true;
    _keys.forEach(key => {
      const value = obj[key];
      if (!SharedValidation.isNumber(value)) {
        isValid = false;
      }
    });

    return isValid;
  }

  public static isArrayOfStrings(arr: string[]): boolean {
    return Array.isArray(arr) && arr.every(x => SharedValidation.isString(x));
  }

}