import React, { Component } from 'react';
import { inject } from 'mobx-react';
import { ILocalizedProps } from '@elements/localization';
import * as Backoffice from '@client/backoffice/Dto';
import { LykkeGrid, LykkeGridColumn, FilterDescriptor, SortDescriptor, LykkeGridContext, LykkeGridActionsCell, VirtualField } from '@elements/lykke-html/LykkeGrid';
import { Intent, AnchorButton, Icon } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';

interface Props extends ILocalizedProps {
  data: Backoffice.RegulatoryType.RegulatoryTypeContract[];
  loading: boolean;
  filters: Array<FilterDescriptor<Backoffice.RegulatoryType.RegulatoryTypeContract>>;
  sort: Array<SortDescriptor<Backoffice.RegulatoryType.RegulatoryTypeContract>>;
  onStateChange?(changeContext: LykkeGridContext<Backoffice.RegulatoryType.RegulatoryTypeContract>): void;
  duplicateClick: (regulatoryTypeId: string) => void;
  deleteClick: (regulatoryTypeId: string) => void;
}

@inject('i18n')
export class RegulatoryTypesTable extends Component<Props, {}> {
  private get regulatoryTypeIdColumn(): LykkeGridColumn<Backoffice.RegulatoryType.RegulatoryTypeContract> {
    const t = this.props.i18n.RegulationRules.RegulatoryTypes;
    return {
      field: 'id',
      name: t.RegulatoryTypeIdColumn,
      sortable: true,
      filterable: true,
      initialWidth: 400,
    };
  }

  private get actionsColumn(): LykkeGridColumn<Backoffice.RegulatoryType.RegulatoryTypeContract> {
    const t = this.props.i18n.RegulationRules.RegulatoryTypes;
    return {
      field: 'actions' as VirtualField,
      name: t.ActionsColumn,
      fixed: 'right',
      initialWidth: 100,
      getCellContent: item =>
        <LykkeGridActionsCell>
          <Tooltip2 content={t.DuplicateButtonLabel}>
            <AnchorButton intent={Intent.NONE} onClick={() => this.props.duplicateClick(item.id)} icon={<Icon icon='duplicate' title={false} />}></AnchorButton>
          </Tooltip2>

          <Tooltip2 content={t.DeleteButtonLabel}>
            <AnchorButton intent={Intent.DANGER} onClick={() => this.props.deleteClick(item.id)} icon={<Icon icon='trash' title={false} />}></AnchorButton>
          </Tooltip2>

        </LykkeGridActionsCell>
    };
  }

  private get columns() {
    return [
      this.regulatoryTypeIdColumn,
      this.actionsColumn,
    ];
  }

  public render() {
    return (
      <LykkeGrid
        uniqueRowKey='id'
        columns={this.columns}
        filters={this.props.filters}
        sort={this.props.sort}
        onStateChange={this.props.onStateChange}
        loading={this.props.loading}
        rows={this.props.data}
      ></LykkeGrid>
    );
  }
}