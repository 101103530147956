import { CashMovementTabSettings, TabType } from '@models/TabSettings';
import TabSettingsGeneration from '@models/utils/TabSettingsGeneration';
import SharedValidation from './SharedValidation';
import { CashMovementColumnNames } from '@scripts/features/cash-movements/interfaces/CashMovementColumn';

interface CashMovementCategoryObject {
  overnightFinancing: boolean;
  unrealizedDailyPnL: boolean;
  realizedDailyPnL: boolean;
  commission: boolean;
  deposit: boolean;
  withdrawal: boolean;
  compensationPaymentForDividends: boolean;
  onBehalfFee: boolean;
  temporaryCashAdjustment: boolean;
  clientCompensation: boolean;
  totalTax: boolean;
  manual: boolean;
  reset: boolean;
}

export class CashMovementsValidation {

  public static validate(activities: CashMovementTabSettings[] = []): CashMovementTabSettings[] {

    let [
      cashMovementSettings = TabSettingsGeneration.generateCashMovementSettings(),
      ...customCashMovements
    ] = activities;

    cashMovementSettings = CashMovementsValidation.sanitizeCashMovementSettings(cashMovementSettings);
    customCashMovements = CashMovementsValidation.sanitizeCustomCashMovementSettings(customCashMovements);

    return [
      cashMovementSettings,
      ...customCashMovements
    ];
  }

  private static sanitizeCashMovementSettings(cashMovementSettings: CashMovementTabSettings): CashMovementTabSettings {
    const errors = CashMovementsValidation.isValidCashMovement(cashMovementSettings);
    return errors.length === 0 ? cashMovementSettings : TabSettingsGeneration.generateCashMovementSettings();
  }

  private static sanitizeCustomCashMovementSettings(customCashMovements: CashMovementTabSettings[] = []): CashMovementTabSettings[] {
    const validatedCustomCashMovements = customCashMovements.map((customCashMovement) => {
      const errors = CashMovementsValidation.isValidCashMovement(customCashMovement);
      return errors.length === 0 ? customCashMovement : null;
    }).filter(customCashMovement => !!customCashMovement);

    return validatedCustomCashMovements;
  }

  private static isValidCashMovement(cashMovement: CashMovementTabSettings): string[] {
    const errors = [];
    const columnNames = Object.keys(CashMovementColumnNames).map(k => CashMovementColumnNames[k]);

    const { id, tabType, hidden, name, dealingPoint, cashMovementCategory, instrument, deselectedColumns, columnOrder, columnSettings } = cashMovement;

    if (!SharedValidation.isString(id)) {
      errors.push('ID must be a string.');
    }

    if (!SharedValidation.isString(name)) {
      errors.push('Name must be a string.');
    }

    if (tabType !== TabType.Dealing) {
      errors.push(`Invalid TabType ${tabType}, must be ${TabType.Dealing}.`);
    }

    if (!SharedValidation.isBoolean(hidden)) {
      errors.push('Property hidden must be a boolean.');
    }

    if (!SharedValidation.isTimeRange(dealingPoint)) {
      errors.push('Property dealingPoint must be of type TimeRange.');
    }

    if (!CashMovementsValidation.isValidCategory(cashMovementCategory)) {
      errors.push('Property cashMovementCategory is invalid.');
    }

    if (!SharedValidation.isString(instrument)) {
      errors.push('Property instrument must be a string.');
    }

    if (!SharedValidation.isArrayOfStrings(deselectedColumns)) { // possible to improve this check with columnNames
      errors.push('DeselectedColumns must be an array of strings.');
    }

    if (!SharedValidation.isArrayOfStrings(columnOrder) || columnNames.length !== columnOrder.length) {
      errors.push(`ColumnOrder must be an array of strings with ${columnNames.length} elements.`);
    }

    if (!CashMovementsValidation.isValidColumnSettings(columnSettings)) {
      errors.push('ColumnSettings property is invalid.');
    }

    return errors;
  }

  private static isValidCategory(category: CashMovementCategoryObject): boolean {
    const CashMovementCategoryProps = ['overnightFinancing', 'unrealizedDailyPnL', 'realizedDailyPnL', 'commission',
      'deposit', 'withdrawal', 'compensationPaymentForDividends', 'onBehalfFee', 'temporaryCashAdjustment', 'clientCompensation', 'totalTax', 'manual', 'reset'];
    return CashMovementCategoryProps.every(x => SharedValidation.isBoolean(category[x]));
  }

  private static isValidColumnSettings(columnSettings): boolean {
    const _keys = Object.keys(columnSettings);
    const columnNames = Object.keys(CashMovementColumnNames).map(k => CashMovementColumnNames[k]);
    const areEqual = _keys.length === columnNames.length && columnNames.every(x => {
      return _keys.includes(x) && SharedValidation.isNumber(columnSettings[x]);
    });

    return areEqual;
  }

}
