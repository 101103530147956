import { NewsTabSettings, TabType } from '@models/TabSettings';
import TabSettingsGeneration from '@models/utils/TabSettingsGeneration';
import SharedValidation from './SharedValidation';

export class NewsValidation {

  public static validate(news: NewsTabSettings[] = []): NewsTabSettings[] {
    let [
      newsSettings = TabSettingsGeneration.generateNewsSettings(),
      ...customNews
    ] = news;

    newsSettings = NewsValidation.sanitizeNewsSettings(newsSettings);
    customNews = NewsValidation.sanitizeCustomNewsSettings(customNews);

    return [
      newsSettings,
      ...customNews
    ];
  }

  private static sanitizeNewsSettings(newsSettings: NewsTabSettings): NewsTabSettings {
    const errors = NewsValidation.isValidNews(newsSettings);
    return errors.length === 0 ? newsSettings : TabSettingsGeneration.generateNewsSettings();
  }

  private static sanitizeCustomNewsSettings(customNews: NewsTabSettings[] = []): NewsTabSettings[] {
    const validatedCustomNews = customNews.map((customNewsItem) => {
      const errors = NewsValidation.isValidNews(customNewsItem);
      return errors.length === 0 ? customNewsItem : null;
    }).filter(customNewsItem => !!customNewsItem);

    return validatedCustomNews;
  }

  private static isValidNews(news: NewsTabSettings): string[] {
    const errors: string[] = [];
    const { id, name, tabType, hidden, filterByName, filterByDate } = news;

    if (!SharedValidation.isString(id)) {
      errors.push('Invalid ID.');
    }

    if (!SharedValidation.isString(name)) {
      errors.push('Invalid Name.');
    }

    if (tabType !== TabType.News) {
      errors.push(`Invalid TabType ${tabType}, must be ${TabType.News}`);
    }

    if (!SharedValidation.isBoolean(hidden)) {
      errors.push(`hidden must be a boolean.`);
    }

    if (!SharedValidation.isString(filterByName)) {
      errors.push('filterByName must be a string.');
    }

    if (!SharedValidation.isTimeRange(filterByDate))  {
      errors.push('filterByDate must be of type TimeRange.');
    }

    return errors;
  }
}