import React from 'react';
import { inject } from 'mobx-react';
import { Button, Intent, Dialog } from '@blueprintjs/core';
import { ILocalizedProps } from '@elements/localization';
import {LykkeForm, LabelWithTextInput, LabelWithCheckbox} from '@elements/lykke-html/form/LykkeForm';
import { FrontendBadRequest } from '@client/backoffice/CustomErrors';

interface Props extends ILocalizedProps {
  open?: boolean;
  loading?: boolean;
  errors?: FrontendBadRequest;
  onClose();
  onSubmit(typeId: string, displayInUI: boolean, autoValidate: boolean);
}

interface State {
  validationError: string;
  typeId: string;
  displayInUI: boolean;
  autoValidate: boolean;
}

@inject('i18n')
export class AddCorporateActionsTypeDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      typeId: '',
      displayInUI: false,
      autoValidate: false,
      validationError: ''
    };
  }

  public componentWillReceiveProps(nextProps: Props) {
    if (this.props.open !== nextProps.open) {
      this.setState({
        typeId: '',
        displayInUI: false,
        autoValidate: false,
        validationError: '',
      });
    }
  }

  private get loc() {
    return this.props.i18n.CorporateActionTypes;
  }

  private get validationErrorsLoc() {
    return this.props.i18n.FormValidationErrors;
  }

  private handleClose = () => {
    const { onClose } = this.props;
    this.setState({
      typeId: '',
      displayInUI: false,
      autoValidate: false,
      validationError: '',
    });

    onClose();
  }

  private handleChange = (typeId: string) => {
    this.setState({ typeId, validationError: '' });
  }

  private handleDisplayInUIChange = (checked): void => {

    this.setState({ displayInUI: checked });
  }

  private handleAutoValidateChange = (checked): void => {

    this.setState({ autoValidate: checked });
  }

  private handleSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();

    if (this.state.typeId === '') {
      this.setState({ validationError: this.validationErrorsLoc.RequiredField });

      return;
    }

    const { onSubmit } = this.props;

    onSubmit(this.state.typeId, this.state.displayInUI, this.state.autoValidate);
  }

  public render() {
    const { open, loading } = this.props;
    const { typeId, displayInUI, autoValidate, validationError } = this.state;
    const textStringMaxLength = 100;

    return (
      <Dialog
        icon='add'
        isOpen={open}
        onClose={this.handleClose}
        title={this.loc.CreateTypeTitle}
        canOutsideClickClose={false}
      >
        <LykkeForm loading={loading}>
          <div className='bp3-dialog-body'>
            <LabelWithTextInput
              label={this.loc.TypeId}
              value={typeId}
              maxLength={textStringMaxLength}
              onChange={e => this.handleChange(e.target.value)}
              error={validationError}
            />
            <LabelWithCheckbox
              label={this.loc.DisplayInUI}
              value={displayInUI}
              onChange={(e) => this.handleDisplayInUIChange(e.target.checked)}
            />
            <LabelWithCheckbox
              label={this.loc.AutoValidate}
              value={autoValidate}
              onChange={(e) => this.handleAutoValidateChange(e.target.checked)}
            />
          </div>
          <div className='bp3-dialog-footer'>
            <div className='bp3-dialog-footer-actions'>
              <Button
                className='bp3-intent-none'
                disabled={loading}
                onClick={this.handleClose}
              >
                {this.loc.Cancel}
              </Button>
              <Button
                intent={Intent.PRIMARY}
                disabled={loading}
                onClick={this.handleSubmit}
                type='submit'
              >
                {this.loc.Save}
              </Button>
            </div>
          </div>
        </LykkeForm>
      </Dialog>
    );
  }
}