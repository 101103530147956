import { PriceAlertTabSettings, TabType } from '@models/TabSettings';
import TabSettingsGeneration from '@models/utils/TabSettingsGeneration';
import SharedValidation from './SharedValidation';
import { PriceAlertColumnNames } from '@scripts/features/price-alerts/interfaces/PriceAlertColumn';

export class PriceAlertsValidation {

  public static validate(priceAlerts: PriceAlertTabSettings[] = []): PriceAlertTabSettings[] {
    let [
      priceAlertSettings = TabSettingsGeneration.generatePriceAlertSettings(),
      ...customPriceAlerts
    ] = priceAlerts;

    priceAlertSettings = PriceAlertsValidation.sanitizePriceAlertsSettings(priceAlertSettings);
    customPriceAlerts = PriceAlertsValidation.sanitizeCustomPriceAlertsSettings(customPriceAlerts);

    return [
        priceAlertSettings,
      ...customPriceAlerts
    ];
  }

  private static sanitizePriceAlertsSettings(priceAlertsSettings: PriceAlertTabSettings): PriceAlertTabSettings {
    const errors = PriceAlertsValidation.isValidPriceAlert(priceAlertsSettings);
    return errors.length === 0 ? priceAlertsSettings : TabSettingsGeneration.generatePriceAlertSettings();
  }

  private static sanitizeCustomPriceAlertsSettings(customPriceAlerts: PriceAlertTabSettings[] = []): PriceAlertTabSettings[] {
    const validatedCustomPriceAlerts = customPriceAlerts.map((customPriceAlert) => {
      const errors = PriceAlertsValidation.isValidPriceAlert(customPriceAlert);
      return errors.length === 0 ? customPriceAlert : null;
    }).filter(customPriceAlert => !!customPriceAlert);

    return validatedCustomPriceAlerts;
  }

  private static isValidPriceAlert(priceAlert: PriceAlertTabSettings): string[] {
    const errors = [];
    const columnNames = Object.keys(PriceAlertColumnNames).map(k => PriceAlertColumnNames[k]);

    const { id, name, tabType, columnSettings, columnOrder, hidden, deselectedColumns, instrument, status } = priceAlert;

    if (!SharedValidation.isString(id)) {
      errors.push('ID must be a string.');
    }

    if (!SharedValidation.isString(name)) {
      errors.push('Name must be a string.');
    }

    if (tabType !== TabType.PriceAlerts) {
      errors.push(`Invalid TabType ${tabType}, must be ${TabType.PriceAlerts}.`);
    }

    if (!PriceAlertsValidation.isValidStatus(status)) {
      errors.push('Category property is invalid.');
    }

    if (!PriceAlertsValidation.isValidColumnSettings(columnSettings)) {
      errors.push('ColumnSettings property is invalid.');
    }

    if (!SharedValidation.isArrayOfStrings(columnOrder) || columnNames.length !== columnOrder.length) {
      errors.push(`ColumnOrder must be an array of strings with ${columnNames.length} elements.`);
    }

    if (!SharedValidation.isArrayOfStrings(deselectedColumns)) {
      errors.push('DeselectedColumns must be an array of strings.');
    }

    if (!SharedValidation.isString(instrument)) {
      errors.push('Property instrument must be a string.');
    }

    if (!SharedValidation.isBoolean(hidden)) {
      errors.push('Property hidden must be a boolean.');
    }

    return errors;
  }

  private static isValidStatus(status): boolean {
    if (!status || typeof status !== 'object') return false;

    const _keys = ['active', 'triggered', 'expired', 'cancelled'];
    return _keys.every(x => SharedValidation.isBoolean(status[x]));
  }

  private static isValidColumnSettings(columnSettings): boolean {
    const _keys = Object.keys(columnSettings);
    const columnNames = Object.keys(PriceAlertColumnNames).map(k => PriceAlertColumnNames[k]);
    return _keys.length === columnNames.length && columnNames.every(x =>
      _keys.includes(x) && SharedValidation.isNumber(columnSettings[x])
    );
  }

}